import React, {
  useState,
  useEffect,
  useCallback,
  startTransition,
} from "react";
import API from "../../components/API/ServiceLayer"; // Your API service layer
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Button,
  Modal,
  Table,
} from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import URL from "../../components/API/Api";
import Swal from "sweetalert2";
import { FaEdit } from "react-icons/fa";
import Select from "react-select";
import { FaEye } from "react-icons/fa";
import "./Tree.css";
import EditUpdateModal from "../Task/EditUpdateModal";

const Tree = () => {
  const [orgData, setOrgData] = useState([]);
  const [OnlyOrgData, setOnlyOrgData] = useState([]);
  const [DefaultGet, setDefaultGet] = useState();
  
  const [selectedOrg, setSelectedOrg] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  //edit process
  const [showEditModal, setShowEditModal] = useState(false);
  const [editProcessId, setEditProcessId] = useState(null);
  const [editProcessName, setEditProcessName] = useState("");
  const [editOrgId, setEditOrgId] = useState("");
  const [editFlowOrder, setEditFlowOrder] = useState("");
  const [editDept, setEditDept] = useState([]);
  const [departments, setDepartments] = useState([]); // Assumes departments are fetched
  const [organizations, setOrganizations] = useState([]);
  const [allOrgWithProcess, setAllorgwithProcess] = useState([]);
  const [mgorganization, setMGOrganization] = useState([]);
  const [depaartments, setdepaartments] = useState([]);

  const [showEditTaskModal, setShowEditTaskModal] = useState(false);

  // State variables for task details (taskEdit prefix followed by field name)
  const [taskEditOrgID, setTaskEditOrgID] = useState("");
  const [taskEditDepID, setTaskEditDepID] = useState("");
  // Loading state for API requests

  const [FilteruserDetails, setFilterUserDetails] = useState([]);

  // Dynamic state for process, department, and role options
  const [processOptions, setProcessOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null); 

  const [taskDetails, setTaskDetails] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    // Populate selectedUsers based on is_assigned (from the taskDetails)
    if (taskDetails && taskDetails.users) {
      const assignedUsers = taskDetails.users
        .filter((user) => user.is_assigned) // Filter only those users who are already assigned
        .map((user) => user.user_id); // Get the user_ids of the assigned users
      setSelectedUsers(assignedUsers);
    }
  }, [taskDetails]); // This will run every time taskDetails changes

 
  // Fetch all organization
  const fetchAllOrg =  () => {
    
      URL.get("api/org_process")
      .then((response)=>{ 
      if (response.status) {
        console.log(response,"myresponse")

        console.log(response?.data?.data?.filter((c)=>c.org_name=="MG"),"mineorg")
       const onlyRegionData=response.data?.data?.filter((c)=>c.org_name=="MG");
        setOrgData(response.data.data);
        setOnlyOrgData(onlyRegionData);
     console.log(onlyRegionData,"only")
        automaticChange(onlyRegionData)
    
      }
    })
     .catch ((error)=> {
      console.error("Error fetching organization data:", error);
    })
  }


  useEffect(() => {
    fetchAllOrg();
  }, []);

  useEffect(() => {
    if (selectedOrg !== null) {
      const selectedData = orgData.find((org) => org.org_id === selectedOrg);
      setSelectedOrgData(selectedData);
    }
  }, [orgData, selectedOrg]);

  // Fetch Departments
  const fetchDepartments = useCallback(async () => {
    try {
      const response = await URL.get(
        "https://dev.berighthere.com/user-management/api/v1/orgdept"
      );
      setDepartments(response.data.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  }, []);

  // Fetch Organizations
  const fetchOrganizations =  () => {
    
    URL.get("api/allorg")
    .then((res)=>{

    const organizationData = res.data.data;
    // console.log("organizationData", organizationData);
    setOrganizations(organizationData);
    setMGOrganization(organizationData?.filter((c)=>c.org_name=="MG"));
    fetchProcessesAndDepartments(mgorganization[0]?.id)
    // console.log(formData?.org_id,"oieeiuweghsdb")
    })
    .catch((err)=>console.log(err));
  }

  // Fetch Departments and Organizations on component mount
  useEffect(() => {
    fetchOrganizations();
    fetchDepartments();

  }, []);

  // Fetch the organization and process data
  const fetchOrganizationProcesses = useCallback(async () => {
    try {
      const response = await URL.get("/api/org_process");
      setAllorgwithProcess(response.data.data);
      console.log("response.data.data", response.data.data);
    } catch (error) {
      console.error("Error fetching organization processes:", error);
    }
  }, []);

  useEffect(() => {
    fetchOrganizationProcesses();
  }, [fetchOrganizationProcesses]);

  const gradientColors = [
    "linear-gradient(135deg, #FF9E30, #FFB74D)", // Slightly deeper orange tone
    "linear-gradient(135deg, #B0B0B0, #D1D1D1)", // Soft grey with depth
  ];

  const gradientColorsTask = ["linear-gradient(135deg, #FFB347, #FFCC33)"];

  // Handler for setting the process flow order
  const SettingProcessOrderHandler = (org_id) => {
    console.log("Selected Org ID:", org_id);

    const organization = allOrgWithProcess.find(
      (org) => org.org_id === parseInt(org_id)
    );

    if (organization) {
      console.log("Found Organization:", organization);

      if (organization.processes && organization.processes.length > 0) {
        const lastProcess =
          organization.processes[organization.processes.length - 1];
        const lastFlowOrder = lastProcess.process_flow_order;

        setEditFlowOrder(lastFlowOrder + 1);
      } else {
        setEditFlowOrder(1);
      }
    } else {
      console.log("Organization not found or empty processes.");
    }
  };

  const automaticChange=(OnlyOrgData)=>{
    console.log(OnlyOrgData,"OnlyOrgData")
    const  selectedOrgId =OnlyOrgData?.find((c)=>c?.org_name=="MG");
    console.log(selectedOrgId,"ssseee")
    // const selectedOrgId= selectedOrg;
     setSelectedOrg(Number(selectedOrgId?.org_id));
     setEditOrgId(selectedOrgId?.org_id);
     SettingProcessOrderHandler(selectedOrgId?.org_id);
  }

  // Handle Organization Change
  const handleOrgChange = (e) => {
    
      const selectedOrgId = e.target.value;
    setSelectedOrg(Number(e.target.value));
    setEditOrgId(selectedOrgId);
    SettingProcessOrderHandler(selectedOrgId);
    
  };

  // Open the modal for the selected process
  const handleProcessClick = (process) => {
    setSelectedProcess(process);
    setShowModal(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProcess(null);
  };

  //capitalizeWords
  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Handle drag end event for reordering tasks
  const onTaskDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }
    console.log(destination, source, "<><>>");
    const reorderedTasks = Array.from(selectedProcess.tasks);
    const [movedTask] = reorderedTasks.splice(source.index, 1);
    reorderedTasks.splice(destination.index, 0, movedTask);

    // Update task_flow_order after reordering tasks
    reorderedTasks.forEach((task, index) => {
      task.task_flow_order = index + 1;
    });

    // Update the local state for tasks
    setSelectedProcess({
      ...selectedProcess,
      tasks: reorderedTasks,
    });

    // Send the updated task order to the backend
    try {
      await updateTaskFlowOrder(selectedProcess.process_id, reorderedTasks);
    } catch (error) {
      console.error("Error updating task flow order:", error);
    }
  };

  // Handle drag end event for reordering processes
  const onProcessDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination || destination.index === source.index) {
      return;
    }

   
    const reorderedProcesses = Array.from(selectedOrgData.processes);

    const [movedProcess] = reorderedProcesses.splice(source.index, 1);
    reorderedProcesses.splice(destination.index, 0, movedProcess);

   
    reorderedProcesses.forEach((process, index) => {
      process.process_flow_order = index + 1;
    });

   
    setSelectedOrgData((prevData) => {
      console.log("State Update Triggered");
      return {
        ...prevData,
        processes: reorderedProcesses,
      };
    });

    // Check if the UI is reflecting the updated order
    // console.log("Selected Org Data After Update:", selectedOrgData);

    try {
      const response = await updateProcessFlowOrder(reorderedProcesses);
      // console.log("Response:", response);
    } catch (error) {
      console.error("Error updating process flow order:", error);
    }
  };

  const handleEditProcess = async (process) => {
    // Implement your edit logic here
    // console.log("Edit process:", process.process_id);
    const response = await URL.get(`/api/process/${process.process_id}`);
    const processData = response.data.data;
    setEditProcessId(process.process_id);
    setEditProcessName(processData.process_name);
    setEditOrgId(processData.org_id);
    setEditFlowOrder(processData.process_flow_order);
    setEditDept(processData.dept_id);
    setShowEditModal(true);
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedProcess = {
      org_id: editOrgId,
      process_name: editProcessName,
      process_flow_order: editFlowOrder,
      dept_id: editDept,
      status: 1,
    };

    // console.log("Updating Process ID:", editProcessId);

    try {
      const response = await URL.put(
        `api/process/${editProcessId}`,
        updatedProcess
      );
      if (response.status === 200) {
        // Successfully updated, now update the state or refetch the data
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message || "Process Data Successfully Updated",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "rgb(255, 165, 0)",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        // Optionally, refetch the process list
        fetchAllOrg(); // This function should re-fetch your list of processes

        setShowEditModal(false); // Close the modal
      }
    } catch (error) {
      // console.error("Error updating process:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error updating process data. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const updateProcessFlowOrder = async (changedProcesses) => {
    try {
      const payload = {
        org_id: selectedOrg,
        processes: changedProcesses.map((process) => ({
          process_id: process.process_id,
          process_flow_order: process.process_flow_order,
        })),
      };

      const response = await URL.post(
        "/api/update-process-flow-order",
        payload
      );

      // console.log("Response:", response.data);

      // Show success notification
      Swal.fire({
        icon: "success",
        title: "Success",
        text:
          response.data.message || "Process Flow Order Successfully Updated",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "rgb(255, 165, 0)",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error("Error updating process flow order:", error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.message ||
          "There was an error updating the process flow order. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  // Function to send updated task order to the backend and show success/error notifications
  const updateTaskFlowOrder = async (processId, reorderedTasks) => {
    try {
      const response = await URL.post(
        `/api/update-task-flow-order/${processId}`,
        {
          tasks: reorderedTasks,
        }
      );

      // Show success notification
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message || "Task Flow Order Successfully Updated",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "rgb(255, 165, 0)",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      // console.error("Error updating task flow order:", error);

      // Show error notification
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.message ||
          "There was an error updating the task flow order. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  // Function to render tasks
  const renderTasks = (tasks) => {
    const sortedTasks = [...tasks].sort(
      (a, b) => a.task_flow_order - b.task_flow_order
    );

    // If no tasks are assigned, show a message
    if (sortedTasks.length === 0) {
      return <div>No Task is Assigned</div>;
    }

    return (
      <DragDropContext onDragEnd={onTaskDragEnd}>
        <Droppable droppableId="tasks-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {sortedTasks.map((task, index) => (
                <Draggable
                  key={task.task_id}
                  draggableId={task.task_id.toString()}
                  index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="task-container"
                      style={{
                        ...provided.draggableProps.style,
                        border: "1px solid #ddd",
                        padding: "8px",
                        margin: "4px 0",
                        background:
                          gradientColorsTask[
                            Math.floor(index / 1) % gradientColorsTask.length
                          ],
                        borderRadius: "8px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      }}>
                      <div className="task-left">
                        <div className="task-name">
                          {capitalizeWords(task.task_name)}
                        </div>
                      </div>
                      <div className="task-right">
                        <div className="task-flow-order">
                          {task.task_flow_order}
                        </div>
                        {/* FaEye Icon */}
                        <FaEye
                          className="task-flow-order1"
                          onClick={() => handleEditTask(task)}
                          style={{
                            cursor: "pointer",
                            fontSize: "18px",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const renderProcesses = (processes) => {
    const process = [...processes].sort(
      (a, b) => a.process_flow_order - b.process_flow_order
    );

    if (!process || process.length === 0) {
      return (
        <div
          style={{ textAlign: "center", fontSize: "16px", color: "#B0BEC5" }}>
          No Process is assigned
        </div>
      );
    }

    return (
      <DragDropContext onDragEnd={onProcessDragEnd}>
        <Droppable droppableId="processes-list" direction="vertical">
          {(provided) => (
            <ul
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                listStyleType: "none", // Remove default bullets
                padding: 0,
                margin: 0,
              }}>
              {process.map((process, index) => (
                <Draggable
                  key={process.process_id}
                  draggableId={process.process_id.toString()}
                  index={index}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        marginBottom: "10px",
                        display: "flex", // Flexbox for centering
                        justifyContent: "center", // Center horizontally
                        alignItems: "center", // Center vertically

                        ...provided.draggableProps.style, // Apply drag styles (transform, etc.)
                      }}>
                      <Card
                        className="process-card"
                        style={{
                          background:
                            gradientColors[
                              Math.floor(index / 1) % gradientColors.length
                            ],
                          width: "33%",
                          cursor: "pointer",
                          padding: "10px",
                          borderRadius: "15px",
                          overflow: "hidden",
                          position: "relative",
                          transition: "transform 0.2s ease-in-out",
                        }}
                        onClick={() => handleProcessClick(process)}>
                        <FaEdit
                          onClick={(e) => {
                            e.stopPropagation();
                            console.log("Process onClick:", process);
                            handleEditProcess(process);
                          }}
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            fontSize: "18px",
                            color: "white",
                            cursor: "pointer",
                            zIndex: 1,
                          }}
                        />
                        <Card.Body
                          className="card-body-animated"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            position: "relative",
                          }}>
                          {/* Circle for Process Flow Order */}
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              backgroundColor: "rgba(255, 255, 255, 0.3)",
                              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                              color: "rgb(255 39 34)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}>
                            {process.process_flow_order}
                          </div>
                          {/* Glass Effect for Process Info */}
                          <div
                            style={{
                              background: "rgba(255, 255, 255, 0.3)",
                              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                              backdropFilter: "blur(10px)",
                              padding: "10px",
                              borderRadius: "8px",
                              color: "#4E5D6A",
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                            }}>
                            <h6
                              style={{ fontWeight: "bold", color: "#2D3A45" }}>
                              {process.process_name}
                            </h6>
                            <div
                              style={{
                                color: "#B0BEC5",
                                fontSize: "14px",
                                display: "flex",
                                alignItems: "center",
                              }}>
                              <strong style={{ color: "rgb(45 58 69 / 75%)" }}>
                                Tasks Assigned:
                              </strong>
                              <span
                                style={{
                                  fontWeight: "800",
                                  color: "rgb(87 106 93)",
                                  marginLeft: "5px",
                                }}>
                                {process.tasks?.length}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  // Get selected organization data
  const selectedOrgDataa = orgData?.find((org) => org.org_id === selectedOrg);


  
  //task
  // Close modal
  const handleEditCloseTask = () => setShowEditTaskModal(false);

  // Fetch task details when viewing a task (by ID)
  const handleEditTask = (task) => {
    // console.log(task, "task");
    setSelectedTask(task); 
    setDefaultGet(task.task_id);  
    setShowEditTaskModal(true);
    
  };

  console.log(FilteruserDetails,"filterusr")
  // Fetch task data from the API
  
  
  // Fetch process and department data based on selected organization and department
  useEffect(() => {
   fetchProcessesAndDepartments(mgorganization[0]?.id);
  }, [taskEditOrgID, taskEditDepID]);


  const fetchProcessesAndDepartments =  (d) => {
    
       URL.get(`api/org/${d}`)
       .then((res)=>{
      if (res.data && res.data.status) {
        const orgData = res.data.data;

        // console.log(orgData,"orgggdata")
        // Format processes for the dropdown
        const formattedProcessOptions = orgData.processes.map((process) => ({
          value: process.process_id,
          label: process.process_name || `Process ${process.process_id}`, // Use a fallback if process name is null
          key: `process-${process.process_id}`,
        }));
        setProcessOptions(formattedProcessOptions);

        // Format departments and their roles for the dropdown
        const formattedDepartments = orgData.departments.map((dept) => ({
          value: dept.department_id,
          label: dept.department_name,
          roles: dept.roles.map((role) => ({
            value: role.role_id,
            label: role.dep_role_name,
          })),
          key: dept.department_id,
        }));
        setDepartmentOptions(formattedDepartments);
        setdepaartments(orgData?.departments);
        
      }
    })
    .catch((err)=>console.log(err))
  
 
}



  return (
    <div className="tree-page-container">
      <div className="page-header mb-0">
        <nav aria-label="breadcrumb" className="mt-1 clip_brdcrumps">
          <div id="clip">
            <span
              style={{
                padding: "3px 20px",
                backgroundColor: "#FFA500",
                color: "white",
                display: "inline-block",
                clipPath:
                  "polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)",
              }}>
              <a
                href="/master/Org"
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}>
                Masters
              </a>
            </span>
            <span
              style={{
                padding: "3px 20px",
                backgroundColor: "#FFA500",
                color: "white",
                display: "inline-block",
                clipPath:
                  "polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)",
                marginRight: "5px", // Adjusted margin for smaller gap
              }}>
              <a
                href="/master/tree"
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}>
                Tree
              </a>
            </span>
          </div>
        </nav>
      </div>

      {/* Render the content of the selected organization */}
      {selectedOrgDataa && (
        <div className="org-content-container">
          {/* <h3>{selectedOrgDataa.org_name}</h3> */}
          <div className="display_space_between">
            {/* <h5 className="table_heading">Tree</h5> */}
            <Form.Group controlId="orgSelect">
              <Form.Select
                value={selectedOrg}
                onChange={handleOrgChange}
                className="custom-dropdown" // Apply the custom class here
              >
                {OnlyOrgData.map((org) => (
                  <option key={org.org_id} value={org.org_id}>
                    {org.org_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
          {renderProcesses(selectedOrgDataa.processes)}
        </div>
      )}

      {/* Modal to display process tasks */}
      {selectedProcess && (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header
            closeButton
            style={{
              borderBottom: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px 20px 10px 20px",
              transition: "transform 0.3s ease, box-shadow 0.3s ease", // Animation for transform and box-shadow
            }}
            className="modal-header-hover">
            <Modal.Title
              style={{
                textAlign: "center",
                fontWeight: "bold",
              }}>
              {capitalizeWords(selectedProcess.process_name)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              padding: "20px",
              borderRadius: "10px",
              border: "1px solid #ddd",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              transition: "transform 0.3s ease, box-shadow 0.3s ease", // Adding transition for smooth hover effect
            }}
            className="modal-body-hover">
            {renderTasks(selectedProcess.tasks)}
          </Modal.Body>
        </Modal>
      )}
      {/* Edit Process Modal */}
      {showEditModal && (
        <Modal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          centered
          size="lg">
          <Modal.Body>
            <Container className="process-form-container">
              <h2 className="text-center mb-4">Edit Process</h2>
              <Form onSubmit={handleSubmit}>
                <Row>
                  {/* Organization Dropdown */}
                  <Col md={4} style={{ padding: "10px" }}>
                    <Form.Group controlId="orgName">
                      <Form.Label>Organization</Form.Label>
                      <Form.Select
                        name="orgName"
                        value={editOrgId}
                        onChange={(e) => {
                          setEditOrgId(e.target.value);
                          const ds2 = e.target.value;
                          SettingProcessOrderHandler(ds2);
                        }}
                        required>
                        <option value="">Select Organization</option>
                        {organizations.map((org) => (
                          <option key={org.id} value={org.id}>
                            {org.org_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  {/* Process Name */}
                  <Col md={4} style={{ padding: "10px" }}>
                    <Form.Group controlId="processName">
                      <Form.Label>Process Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="processName"
                        value={editProcessName}
                        placeholder="Enter process name"
                        onChange={(e) => setEditProcessName(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>

                  {/* Process Flow Order */}
                  <Col md={4} style={{ padding: "10px" }}>
                    <Form.Group controlId="processFlowOrder">
                      <Form.Label>Process Flow Order</Form.Label>
                      <Form.Control
                        type="number"
                        name="processFlowOrder"
                        value={editFlowOrder}
                        onChange={(e) => setEditFlowOrder(e.target.value)}
                        required
                        disabled
                      />
                    </Form.Group>
                  </Col>

                  {/* Department Dropdown */}
                  <Form.Group controlId="department">
                    <Form.Label>Department</Form.Label>
                    <div className="department-select-wrapper">
                      <Select
                        isMulti
                        name="department"
                        value={departments
                          .filter((dept) => editDept.includes(dept.id))
                          .map((dept) => ({
                            label: dept.department_name,
                            value: dept.id,
                          }))}
                        options={departments.map((dept) => ({
                          label: dept.department_name,
                          value: dept.id,
                        }))}
                        onChange={(selected) =>
                          setEditDept(selected.map((item) => item.value))
                        }
                        classNamePrefix="select"
                        placeholder="Select Department"
                      />
                    </div>
                  </Form.Group>
                </Row>

                {/* Action Buttons */}
                <div className="form-actions mb-4">
                  <Button
                    variant="outline-light"
                    type="reset"
                    className="cancel-btn"
                    onClick={() => setShowEditModal(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="outline-light"
                    type="submit"
                    className="create-btn">
                    Update Process
                  </Button>
                </div>
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      )}
      {/* Edit Task Modal */}
      <Modal
        show={showEditTaskModal}
        onHide={handleEditCloseTask}
        centered
        size="lg">
        <Modal.Body>
        {showEditTaskModal &&  
            <EditUpdateModal 
            DefaultGet={DefaultGet}
            organizations={organizations}
             mgorganization={mgorganization} 
             handleEditCloseTask={handleEditCloseTask}
             /> }
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Tree;
