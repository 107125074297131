import React, { useEffect, useState } from "react";
import DeptButton from "./DeptButton";
import { FaBell } from "react-icons/fa";
import { FcBusinessman } from "react-icons/fc";
import Badge from "react-bootstrap/Badge";
import "./NavComponents.css";
import { Dropdown, Modal, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { logoutUser } from "../../redux/AuthSlice";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import API from "../API/ServiceLayer";
import URL from "../API/Api";
import { FaKey, FaSignOutAlt } from "react-icons/fa";

let user = JSON.parse(localStorage.getItem("mikro_user"));

// if (user && user.data && user.data.user_org_depts) {
//   user.data.user_org_depts.forEach((orgDept) => {
//     console.log(orgDept.organization_department.department_name, "gee");
//   });
// }
const Navbar = ({parsedNotifications,hideData}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");

  const [isMobile, setIsMobile] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0); // Track the active department
  const [hoverIndex, setHoverIndex] = useState(null);

  //password
  const [show, setShow] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [passErr1, setPassErr1] = useState("");
  const [passErr2, setPassErr2] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [passwordIsTypePassword, setPasswordIsTypePassword] = useState(true);
  const [cPasswordIsTypePassword, setCPasswordIsTypePassword] = useState(true);
  const [overallheaderCount, setoverallheaderCount] = useState([]);

  // useEffect(() => {
  //   GetOverallCounts();
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
    GetOverallCounts();
    }, 2000);

    return () => clearInterval(interval); 
  }, []);

  const getShortDepartmentName = (departmentName) => {
    if (!departmentName) return ""; // Handle undefined/null cases
  
    const words = departmentName.split(" "); // Split by space
    return words.length > 1 ? words.map(word => word[0]).join("").toUpperCase() : departmentName;
  };

  const GetOverallCounts = () => {
    const getUser = localStorage.getItem("mikro_user");

    const parsedUser = JSON.parse(getUser);
    // console.log(parsedUser, "mklop");
    const UserId = parsedUser?.data?.id;
    const myDeptiD = parsedUser?.data?.user_org_depts?.map(
      (c) => c.org_dept_id
    );
    // console.log(myDeptiD,"mkl")
    let data = {
      user_id: UserId,
      org_dept_id: myDeptiD,
    };
    // Manually format query parameters
    const params = new URLSearchParams();
    params.append("user_id", data.user_id);
    data.org_dept_id.forEach((id) => params.append("org_dept_id[]", id));

    // Send the GET request with properly formatted query parameters
    URL.get(
      `/api/v1/taskConfigDetailsResponse/getUserNotificationCount?${params.toString()}`
    )
      .then((res) => {
        setoverallheaderCount(res.data?.data);
        // console.log(res.data.data, "mjooooo");
      })
      .catch((err) => console.log(err));
  };

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setPassErr1("");
    setPassErr2("");
    setPassword1("");
    setPassword2("");
    setMatchPassword("");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const userId = localStorage.getItem("mikro_id");
    const roleId = localStorage.getItem("mikro_role_id");

    const data = {
      password: password1,
      user_id: userId,
      role_id: roleId,
    };

    try {
      const response = await API.postMethod(
        "api/password_update",
        "post",
        data
      );

      // Check if the response is OK before parsing JSON
      if (!response.ok) {
        throw new Error(
          `Server error: ${response.status} - ${response.statusText}`
        );
      }

      let res;
      try {
        res = await response.json();
      } catch (jsonError) {
        throw new Error("Failed to parse response as JSON. Please try again.");
      }

      if (res.status === "success") {
        setShow(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res?.data?.message || "Password Updated Successfully",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "rgb(255, 165, 0)",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        throw new Error(res?.message || "Error updating password.");
      }
    } catch (error) {
      // console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.message || "Error updating Password data. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  //end of password

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Retrieve user data from localStorage
    const user = JSON.parse(localStorage.getItem("mikro_user"));

    if (user && user.data && user.data.user_org_depts) {
      // Map user_org_depts to extract department names and paths
      const deptList = user.data.user_org_depts.map((orgDept, index) => ({
        dept_name: orgDept.organization_department.department_name,
        path: `/dashboard`, // Assuming path based on department ID
        org_dept_id: orgDept.org_dept_id,
        hover_text: `Go to ${orgDept.organization_department.department_name}`, // Hover text
      }));
      if(deptList) {
      localStorage.setItem(
        "mikro_departments",
        JSON.stringify(deptList[0]?.org_dept_id)
      );
      setDepartments(deptList);
    }
    }
  }, []); // Run once on mount

  // Handle click to change active department
  const handleDeptClick = (index, path, org_dept_id) => {
    setActiveIndex(index);
    // console.log("bbb");
    localStorage.setItem("mikro_departments", org_dept_id);
    navigate(path, { state: { org_dept_id } });
  };

  // Handle hover state
  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };
  //fetching the username and role
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("mikro_user"));

    if (user && user.data) {
      setUserName(user.data.name);
      const role = user.data.roles[0]?.role_name || "User";
      setUserRole(role);
    }
  }, []);

  const handleLogout = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log me out!",
    });

    if (result.isConfirmed) {
      await dispatch(logoutUser());
      Swal.fire({
        title: "Logged out!",
        text: "You have been logged out successfully.",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      navigate("/");
    }
  };

  const MyPath = "/dashboard";

  // console.log(departments, "ms1");
  // console.log(overallheaderCount, "ms12");

  return (
    <div 
      className="header_container"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
        display:hideData==true? "none":""
      }}>
      {/* -- heading section -- */}
      <div>{isMobile ? <h6>MIKRO CENTRAL</h6> : <h6>MIKRO CENTRAL</h6>}</div>
      {/* -- department section -- */}
    
    {isMobile ? <div className="mobile_header_depts">
      {overallheaderCount.map((item, index) => (
  <button
    key={item.department_name}
    className={`me-1 ${index === activeIndex ? "active-dept" : ""}`}
    onClick={(e) => {
      e.preventDefault();
      handleDeptClick(index, MyPath, item.org_dept_id);
    }}
    onMouseEnter={() => handleMouseEnter(index)}
    onMouseLeave={handleMouseLeave}
    style={{
      padding: "7px",
      fontSize: "12px",
      color: "#ffffff",
      background:
        index === activeIndex
          ? "linear-gradient(45deg,rgb(120, 116, 116),rgb(86, 85, 85))"
          : "linear-gradient(45deg, #ff7f00, #ff9e3d)",
      borderRadius: "10px",
      border: "none",
      cursor: "pointer",
      textDecoration: "none",
      display: "inline-block",
      position: "relative",
      transition: "all 0.3s ease",
      boxShadow:
        index === activeIndex || index === hoverIndex
          ? "0 4px 10px rgba(0, 0, 0, 0.1)"
          : "none",
      transform:
        index === activeIndex || index === hoverIndex
          ? "scale(1.1)"
          : "scale(1)",
    }}>
    <a
      href={MyPath}
      style={{
        textDecoration: "none",
        color: "inherit",
        fontWeight:
          index === activeIndex || index === hoverIndex
            ? "bold"
            : "normal",
        transition: "font-weight 0.3s ease",
      }}>
      {getShortDepartmentName(item?.department_name)}
    </a>

    {/* Notification Badge */}
    {item?.notification_count > 0 && (
      <Badge
        pill
        bg="danger"
        className="bell-badge position-absolute"
        style={{
          top: "-8px",
          right: "-8px",
          fontSize: "12px",
          padding: "4px 8px",
          fontWeight: "bold",
        }}>
        {item?.notification_count}
      </Badge>
    )}
  </button>
))}
      
       </div>:

<div
className={
  isMobile ? "navbar_departmentsMobile" : "navbar_departments"
}>
{overallheaderCount.map((item, index) => (
  <button
    key={item.department_name}
    className={`me-2 ${index === activeIndex ? "active-dept" : ""}`}
    onClick={(e) => {
      e.preventDefault();
      handleDeptClick(index, MyPath, item.org_dept_id);
    }}
    onMouseEnter={() => handleMouseEnter(index)}
    onMouseLeave={handleMouseLeave}
    style={{
      padding: "8px 14px",
      fontSize: "12px",
      color: "#ffffff",
      background:
        index === activeIndex
          ? "linear-gradient(45deg,rgb(120, 116, 116),rgb(86, 85, 85))"
          : "linear-gradient(45deg, #ff7f00, #ff9e3d)",
      borderRadius: "30px",
      border: "none",
      cursor: "pointer",
      textDecoration: "none",
      display: "inline-block",
      position: "relative",
      transition: "all 0.3s ease",
      boxShadow:
        index === activeIndex || index === hoverIndex
          ? "0 4px 10px rgba(0, 0, 0, 0.1)"
          : "none",
      transform:
        index === activeIndex || index === hoverIndex
          ? "scale(1.1)"
          : "scale(1)",
    }}>
    <a
      href={MyPath}
      style={{
        textDecoration: "none",
        color: "inherit",
        fontWeight:
          index === activeIndex || index === hoverIndex
            ? "bold"
            : "normal",
        transition: "font-weight 0.3s ease",
      }}>
      {item?.department_name}
    </a>

    {/* Notification Badge */}
    {item?.notification_count > 0 && (
      <Badge
        pill
        bg="danger"
        className="bell-badge position-absolute"
        style={{
          top: "-8px",
          right: "-8px",
          fontSize: "12px",
          padding: "4px 8px",
          fontWeight: "bold",
        }}>
        {item?.notification_count}
      </Badge>
    )}
  </button>
))}
</div>
    
    }  
      {/* -- user and notification section -- */}
      <div className="nav-end d-flex align-items-center justify-content-end">
         

        <Dropdown>
          <Dropdown.Toggle
            as="span"
            id="dropdown-basic"
            className="user d-flex flex-column align-items-center"
            style={{ cursor: "pointer" }}>
            <FcBusinessman className="fs-1" />
            <h6 className="name m-0 text-capitalize">{userName}</h6>
            <h5 className="role m-0 fw-bold">{userRole}</h5>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item className="dropdown-item" onClick={handleShow}>
              {" "}
              <FaKey className="dropdown-icon" /> {/* Key Icon */}
              Change Password
            </Dropdown.Item>
            <Modal
              backdrop="static"
              show={show}
              onHide={handleClose}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title">
              <Modal.Header>
                <Modal.Title id="example-custom-modal-styling-title">
                  Change Password
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <form onSubmit={handleFormSubmit}>
                  <Form.Group className="position-relative mb-4">
                    <label className="font-weight-bold text-dark">
                      New Password <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      type={passwordIsTypePassword ? "password" : "text"}
                      minLength={6}
                      maxLength={55}
                      value={password1}
                      onChange={(e) => setPassword1(e.target.value)}
                      placeholder="Enter New Password"
                      required
                      size="sm"
                      className="rounded-0"
                    />
                    <button
                      type="button"
                      className="password-toggle-btn"
                      style={{
                        position: "absolute",
                        top: "69%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        border: "none",
                        background: "transparent",
                        borderRadius: "25%",
                        padding: "6px",
                        transition: "transform 0.3s ease",
                      }}
                      onClick={() =>
                        setPasswordIsTypePassword(!passwordIsTypePassword)
                      }>
                      {passwordIsTypePassword ? (
                        <AiOutlineEyeInvisible size="1.5em" color="#607d8b" />
                      ) : (
                        <AiOutlineEye size="1.5em" color="#607d8b" />
                      )}
                    </button>
                    {passErr1 && (
                      <span className="text-danger text-small">{passErr1}</span>
                    )}
                  </Form.Group>

                  <Form.Group className="position-relative mb-4">
                    <label className="font-weight-bold text-dark">
                      Confirm New Password{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      type={cPasswordIsTypePassword ? "password" : "text"}
                      minLength={6}
                      maxLength={55}
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                      placeholder="Confirm Password"
                      required
                      size="sm"
                      className="rounded-0"
                    />
                    <button
                      type="button"
                      className="password-toggle-btn"
                      style={{
                        position: "absolute",
                        top: "69%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        border: "none",
                        background: "transparent",
                        borderRadius: "25%",
                        padding: "6px",
                        transition: "transform 0.3s ease",
                      }}
                      onClick={() =>
                        setCPasswordIsTypePassword(!cPasswordIsTypePassword)
                      }>
                      {cPasswordIsTypePassword ? (
                        <AiOutlineEyeInvisible size="1.5em" color="#607d8b" />
                      ) : (
                        <AiOutlineEye size="1.5em" color="#fff" />
                      )}
                    </button>
                    {passErr2 && (
                      <span className="text-danger text-small">{passErr2}</span>
                    )}
                    {matchPassword && (
                      <span className="text-danger text-small">
                        {matchPassword}
                      </span>
                    )}
                  </Form.Group>

                  <div className="d-flex flex-wrap justify-content-end gap-3">
                    <Button
                      onClick={handleClose}
                      className="rounded-0"
                      style={{
                        background: "linear-gradient(45deg, #ffcc00, #ff9933)",
                        border: "none",
                        outline: "none",
                        padding: "12px 35px",
                        transition:
                          "box-shadow 0.3s ease, transform 0.2s ease-in-out",
                        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                        fontSize: "1rem",
                      }}
                      onMouseOver={(e) =>
                        (e.target.style.transform = "scale(1.05)")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.transform = "scale(1)")
                      }>
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="outline-light"
                      className="rounded-0"
                      style={{
                        background: "linear-gradient(45deg, #32cd32, #228b22)",
                        border: "none",
                        outline: "none",
                        padding: "12px 35px",
                        transition:
                          "box-shadow 0.3s ease, transform 0.2s ease-in-out",
                        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                        fontSize: "1rem",
                      }}
                      onMouseOver={(e) =>
                        (e.target.style.transform = "scale(1.05)")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.transform = "scale(1)")
                      }>
                      Update
                    </Button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>

            <Dropdown.Item className="dropdown-item" onClick={handleLogout}>
              <FaSignOutAlt className="dropdown-icon" /> {/* Sign-out Icon */}
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Navbar;
