import { createBrowserRouter, Navigate } from "react-router-dom";

import Dashboard from "../../Pages/Dashboard/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Task from "../../Pages/Task/Task";
import Organization from "../../Pages/Organization/Organization";
import Process from "../../Pages/Process/Process";
import MasterOrganization from "../../Pages/MasterStaffDepartment/MasterOrganization";
import Login from "../../Pages/Login/Login";
import AddOrganizationStaff from "../../Pages/MasterStaffDepartment/AddOrganizationStaff";
import NotFound from "../../Pages/NotFound/NotFound";
import EditOrganizationStaff from "../../Pages/MasterStaffDepartment/EditOrganizationStaff";
import Tree from "../../Pages/TreeView/Tree";

const isAuthendicated = () => {
  return true;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },

  {
    path: "/",
    element: <ProtectedRoute isAuthendicated={isAuthendicated} />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/master/org",
        element: <Organization />,
      },
      {
        path: "/organization-staff-add",
        element: <AddOrganizationStaff />,
      },
      {
        path: "/organization-staff-edit/:id",
        element: <EditOrganizationStaff />,
      },
      {
        path: "/master/task",
        element: <Task />,
      },
      {
        path: "/master/organization",
        element: <MasterOrganization />,
      },
      {
        path: "/master/process",
        element: <Process />,
      },
      {
        path: "/*",
        element: <NotFound />,
      },
      {
        path: "/master/tree",
        element: <Tree />,
      },
    ],
  },
]);

export default router;
