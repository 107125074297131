import React, { useEffect, useState } from "react";
import CustomTaskForm from "./CustomTaskForm";
import URL from "../../components/API/Api";
import Swal from "sweetalert2";

const AddCustomTask = ({
  handleClose,
  FlowTask,
  isthisSubtask,
  myParentTaskId,
  selectedNotification,
}) => {
  const [formData, setFormData] = useState({
    task_flow_order: null,
    task_name: "",
    priority: "",
    task_description: "",
    dept_id: [],
    org_id: "",
    dept_role_id: [],
    departments:[],
    sla_resolution_time: "",
    is_doc_upload_required: 0,
    resolution_type: null,
    status: true,
    due_date: "",
    process_id: "",
    selectedUsers: [],
    resolution_type: "", // Resolution Type (Day or Hour)
    days: "", // Number of days (if "Day" is selected)
    hours: "", // Hours (if "Hour" is selected)
    minutes: "", // Minutes (if "Hour" is selected)
  });
  const [errors, setErrors] = useState({});
  const [defaultOrgDeptIDofHeader, setdefaultOrgDeptIDofHeader] = useState();

  // console.log(selectedNotification, "myself");

  useEffect(() => {
    const DepartmentIDS = JSON.parse(localStorage.getItem("mikro_departments"));
    setdefaultOrgDeptIDofHeader(DepartmentIDS);
  }, []);

  const handleUpdateSelectedUsers = (updatedSelectedUsers) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedUsers: updatedSelectedUsers,
    }));
  };

  let LoggedINUser = JSON.parse(localStorage.getItem("mikro_user"));
  // console.log(LoggedINUser.data.id,"LoggedINUser");
  const GetLoggedInPersonID = LoggedINUser?.data?.id;

  const formatDate = (date) => {
    const pad = (num) => (num < 10 ? "0" + num : num); // Helper function to pad single digits
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  // Get current date and time
  const currentDate = new Date();
  const formattedCurrentDate = formatDate(currentDate);

  // console.log(myParentTaskId, "myParentTaskId");

  const handleSubmit = async (e, d) => {
    let active_org_dept_id = JSON.parse(localStorage.getItem("mikro_departments"));
    // console.log(e, d, "mypayload");
    setErrors({});

    // Validation
    const newErrors = {};
    // if (!formData.org_id) newErrors.org_id = "Organization is required.";
    // if (!formData.process_id)
    //   newErrors.process_id = "Process Name is required.";
    if (!formData.priority) newErrors.priority = "Priority is required.";
    if (!formData.dept_id) newErrors.dept_id = "Department is required.";
    if (!formData.dept_role_id)
      newErrors.dept_role_id = "Department Role is required.";
    if (!formData.task_name) newErrors.task_name = "Task Name is required.";
    // if (!formData.sla_resolution_time)
    //   newErrors.sla_resolution_time = "SLA Resolution Time is required.";
    if (!formData.resolution_type)
      newErrors.resolution_type = "Resolution Type is required";
    // valid = false;

    if (formData.resolution_type === "0" && !formData.days)
      newErrors.days = "Please select number of days";
    // valid = false;

    if (
      formData.resolution_type === "1" &&
      (!formData.hours || !formData.minutes)
    )
      newErrors.hours = "Both hours and minutes are required";
    // valid = false;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    // console.log(myParentTaskId, "myParentTaskId");

    let slaResolutionTime;

    // If the resolution type is 'Day' (0)
    if (formData.resolution_type === "0") {
      formData.sla_resolution_time = formData.days; // Send number of days
    }
    // If the resolution type is 'Hour' (1)
    else if (formData.resolution_type === "1") {
      // Combine hours and minutes into a single string (e.g., "3 hours 30 minutes")
      formData.sla_resolution_time = `${formData.hours} : ${formData.minutes}`;
    }

    if (isthisSubtask === true) {
      const data = {
        //   task_flow_order: e,
        task_name: formData.task_name,
        task_description: formData.task_description,
        priority: formData.priority,
        dept_id: formData.dept_id,
        organization_dept_task_receiver: parseInt(formData.dept_id, 10),
        organization_dept_task_creator: defaultOrgDeptIDofHeader,
        org_id: formData.org_id,
        departments:formData.departments,
        dept_role_id: formData.dept_role_id,
        sla_resolution_time: formData.sla_resolution_time,
        is_resolution_hour: formData.resolution_type,
        is_doc_upload_required: formData.is_doc_upload_required,
        status: 2,
        task_id: myParentTaskId,
        sub_task_parent_id: myParentTaskId,
        is_custom_Process: selectedNotification?.is_custom_Process,
        property_id:selectedNotification?.property_details?.id,

        // task_status:2,
        // subtask_status:1,
        // due_date: dueDate,
        process_id: selectedNotification?.process?.id,
        user_ids: formData.selectedUsers,
        created_by: GetLoggedInPersonID,
        parent_task_id: myParentTaskId,
        organization_dept_task_creator: active_org_dept_id,
        is_automated: 0,
      };

      // console.log(data)
      // return 22;
      try {
        const response = await URL.post("api/subtask", data);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message || "Sub Task Data Successfully Submitted",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "rgb(255, 165, 0)",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        setFormData({
          task_flow_order: "",
          task_name: "",
          task_description: "",
          dept_id: "",
          org_id: "",
          dept_role_id: null,
          sla_resolution_time: "",
          is_doc_upload_required: 0,
          status: true,
          due_date: "",
          process_id: "",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500); 
      } catch (error) {
        // console.error("Error creating task", error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error creating the task. Please try again.",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "#dc3545",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } else {
      const data = {
        task_name: formData.task_name,
        task_description: formData.task_description,
        priority: formData.priority,
        dept_id: formData.dept_id,
        org_id: formData.org_id,
        dept_role_id: formData.dept_role_id,
        departments:formData.departments,
        organization_dept_task_receiver: parseInt(formData.dept_id, 10),
        organization_dept_task_creator: defaultOrgDeptIDofHeader,
        sla_resolution_time: formData.sla_resolution_time,
        is_resolution_hour: formData.resolution_type,
        is_doc_upload_required: formData.is_doc_upload_required,
        status: 1,
        process_id: formData.process_id,
        user_ids: formData.selectedUsers,
        created_by: GetLoggedInPersonID,
        organization_dept_task_creator: active_org_dept_id,
        is_automated: 0,
      };

      try {
        const response = await URL.post("api/task", data);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message || "Task Data Successfully Submitted",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "rgb(255, 165, 0)",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        setFormData({
          task_flow_order: "",
          task_name: "",
          task_description: "",
          dept_id: "",
          org_id: "",
          dept_role_id: null,
          sla_resolution_time: "",
          is_doc_upload_required: 0,
          status: true,
          due_date: "",
          process_id: "",
        });

        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);

        handleClose();
      } catch (error) {
        console.error("Error creating task", error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error creating the task. Please try again.",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "#dc3545",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    }
  };

  return (
    <CustomTaskForm
      isthisSubtask={isthisSubtask}
      FlowTask={FlowTask}
      formData={formData}
      setFormData={setFormData}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      errors={errors}
      buttonName={
        isthisSubtask === true ? "Create Sub Task" : "Create Custom Task"
      }
      selectedUsers={formData.selectedUsers}
      setSelectedUsers={handleUpdateSelectedUsers}
    />
  );
};

export default AddCustomTask;
