import React from "react";
import { RouterProvider } from "react-router-dom";

import router from "./components/Layout/router";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.rtl.min.css'; // Optional for RTL support

const App = () => {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
