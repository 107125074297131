import { useState } from "react";
import { Table } from "react-bootstrap";
import moment from "moment";

const TableHistory = ({ selectedNotification, SubTaskDetailHandler }) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [expandedhistory, setExpandedhistory] = useState(null);

  const toggleRow = (index, history) => {
    if (history?.task_config_detail_responses?.is_subparent === 1) {
      setExpandedRow(expandedRow === index ? null : index);
      setExpandedhistory(history);
      // console.log(expandedhistory, "hissss");
    }
  };

  // console.log(selectedNotification,"myid")

  return (
    <div>
      <Table striped bordered hover responsive size="sm">
        <thead
          style={{
            background: "#ff9800",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}>
          <tr>
            <th
              style={{
                padding: "10px 20px",
                border: "none",
                outline: "none",
                fontWeight: "900", 
                textTransform: "uppercase",
                color: "#fff",  
                background: "#ff9800",  
                borderRadius: "8px 0px 0 0",  
                textAlign: "center",
                textShadow:
                  "rgba(0, 0, 0, 0.6) 4px 4px 8px, rgba(255, 140, 0, 0.7) 2px 2px 4px",  
              }}>
              Action By
            </th>

            <th
              style={{
                padding: "10px 20px",
                border: "none",
                outline: "none",
                fontWeight: "900", 
                textTransform: "uppercase",
                color: "#fff",  
                background: "#ff9800",  
                borderRadius: "0px 0px 0 0",  
                textAlign: "center",
                textShadow:
                  "rgba(0, 0, 0, 0.6) 4px 4px 8px, rgba(255, 140, 0, 0.7) 2px 2px 4px",  
              }}>
              Action
            </th>
            {/* <th
              style={{
                padding: "10px 20px",
                border: "1px solid #ddd",
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#fff",
                background: "#ff9800",
                borderRadius: "0",
                textAlign: "center",
              }}>
              Task Name / Remarks
            </th> */}
            <th
              style={{
                padding: "10px 20px",
                border: "none",
                outline: "none",
                fontWeight: "900", 
                textTransform: "uppercase",
                color: "#fff",  
                background: "#ff9800",  
                borderRadius: "0px 0px 0 0",  
                textAlign: "center",
                textShadow:
                  "rgba(0, 0, 0, 0.6) 4px 4px 8px, rgba(255, 140, 0, 0.7) 2px 2px 4px",  
              }}>
              Created At
            </th>
            <th
              style={{
                padding: "10px 20px",
                border: "none",
                outline: "none",
                fontWeight: "900", 
                textTransform: "uppercase",
                color: "#fff",  
                background: "#ff9800",  
                borderRadius: "0px 0px 0 0",  
                textAlign: "center",
                textShadow:
                  "rgba(0, 0, 0, 0.6) 4px 4px 8px, rgba(255, 140, 0, 0.7) 2px 2px 4px",  
              }}>
              Status
            </th>
            <th
              style={{
                padding: "10px 20px",
                border: "none",
                outline: "none",
                fontWeight: "900", 
                textTransform: "uppercase",
                color: "#fff",  
                background: "#ff9800",  
                borderRadius: "0px 8px 0 0",  
                textAlign: "center",
                textShadow:
                  "rgba(0, 0, 0, 0.6) 4px 4px 8px, rgba(255, 140, 0, 0.7) 2px 2px 4px",  
              }}>
              Detail
            </th>
          </tr>
        </thead>

        <tbody>
          {selectedNotification?.history?.original?.data
            ?.sort(
              (a, b) =>
                new Date(a.task_config_detail_responses?.created_at) -
                new Date(b.task_config_detail_responses?.created_at)
            ) // Sorting in descending order (newest first)
            ?.map((history, index) => (
              <>
                <tr
                  key={index}
                  // onClick={() => toggleRow(index, history)}
                  style={{ cursor: "pointer" }}
                  className={
                    history?.task_config_detail_responses?.is_subparent === 1
                      ? "issubtaskrow"
                      : ""
                  }>
                  <td className="text-capitalize">
                    {history?.task_config_detail_responses?.is_subparent ===
                    1 ? (
                      <span
                        style={{
                          marginRight: "8px",
                          fontSize: "20px",
                        }}>
                        📚 {/* Orange Book for Task */}
                      </span>
                    ) : (
                      <>
                        {history?.task_config_detail_responses?.status === 1 ? (
                          <span
                            style={{
                              marginRight: "8px",
                              fontSize: "20px",
                            }}>
                            📙 {/* Books for Subtask */}
                          </span>
                        ) : (
                          <>
                            <span
                              style={{
                                marginRight: "8px",
                                fontSize: "20px",
                              }}>
                              📙 {/* Books for Subtask */}
                            </span>
                          </>
                        )}{" "}
                      </>
                    )}
                    {history?.user_assigned_by?.name} -{" "}
                    {history?.user_assigned_by?.department_name}
                  </td>
                  <td>
                    {history.task_config_detail_responses.status === 0 &&
                      history.task_config_detail_responses
                        .sub_task_parent_id === null && (
                        <div>
                          Task Created & Assigned to{" "}
                          {history.user_assigned_to?.name} -{" "}
                          {history.user_assigned_to?.department_name}
                        </div>
                      )}
                    {history.task_config_detail_responses.subtask_id !== null &&
                      history.task_config_detail_responses.status === 2 && (
                        <span>
                          Sub Task created to {history.user_assigned_to?.name} -{" "}
                          {history.user_assigned_to?.department_name}
                        </span>
                      )}
                    {history.task_config_detail_responses.status === 1 &&
                      history?.task_config_detail_responses?.subtask_id !==
                        null && (
                        <span>
                          Sub Task completed for{" "}
                          {history.user_assigned_to?.name} -{" "}
                          {history.user_assigned_to?.department_name}
                        </span>
                      )}
                    {history.task_config_detail_responses.status === 1 &&
                      history?.task_config_detail_responses?.subtask_id ===
                        null && (
                        <span>
                          Main Task completed for{" "}
                          {history.user_assigned_to?.name} -{" "}
                          {history.user_assigned_to?.department_name}
                        </span>
                      )}
                    {/* {history.task_config_detail_responses.status === 1 && (
                      <span>Task Completed</span>
                    )} */}
                    {history.task_config_detail_responses.status === 3 && (
                      <span>Task Rejected</span>
                    )}
                    {history.task_config_detail_responses.status === 4 && (
                      <span>
                        Re-schedule request to {history?.user_assigned_to?.name}{" "}
                        - {history.user_assigned_to?.department_name}
                      </span>
                    )}
                    {history.task_config_detail_responses.status === 5 && (
                      <span>
                        Re-schedule Accepted for{" "}
                        {history?.user_assigned_to?.name} -{" "}
                        {history.user_assigned_to?.department_name}
                      </span>
                    )}
                    {history.task_config_detail_responses.status === 6 && (
                      <span>
                        Re-schedule Cancelled for{" "}
                        {history?.user_assigned_to?.name} -{" "}
                        {history.user_assigned_to?.department_name}
                      </span>
                    )}
                  </td>
                  {/* <td>
                    
                    {history?.task_config_detail_responses.status===0 && <span> {history?.task?.task_name} </span> }
                    {history?.task_config_detail_responses.status===1 && <span>  {history?.task_config_detail_responses?.remarks}  </span> }
                    {history?.task_config_detail_responses.status===2 && <span>  {history?.subtask?.task_name} </span> }
                    {history?.task_config_detail_responses.status===3 && <span>  {history?.task_config_detail_responses?.remarks} </span> }
                    {history?.task_config_detail_responses.status===4 && <span> {history?.task_config_detail_responses?.remarks} </span> }
                    {history?.task_config_detail_responses.status===5 && <span> {history?.task_config_detail_responses?.remarks} </span> }
                    {history?.task_config_detail_responses.status===6 && <span> {history?.task_config_detail_responses?.remarks} </span> }

                  </td> */}
                  <td>
                    {moment(
                      history?.task_config_detail_responses?.created_at
                    ).format("MMM D YYYY, h:mm A")}

                    {/* On{" "}
                    {moment(history?.task_config_detail_responses?.created_at).format("MMMM Do YYYY, h:mm A")} */}
                  </td>
                  <td>
                    <span
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        display: "block",
                        textAlign: "center",
                      }}>
                      {history.task_config_detail_responses?.status === 0 &&
                        "Open"}
                      {history.task_config_detail_responses.status === 1 &&
                        history?.task_config_detail_responses?.subtask_id !==
                          null && <span>Subtask Completed</span>}
                      {history.task_config_detail_responses.status === 1 &&
                        history?.task_config_detail_responses?.subtask_id ===
                          null && (
                          <span style={{ color: "green" }}>Completed</span>
                        )}
                      {history.task_config_detail_responses?.status === 2 &&
                        "Dependency"}

                      {history.task_config_detail_responses?.status === 3 && (
                        <span style={{ color: "red" }}>Rejected</span>
                      )}

                      {history.task_config_detail_responses?.status === 4 && (
                        <span style={{ color: "red" }}>
                          Reschedule Requested{" "}
                        </span>
                      )}

                      {history.task_config_detail_responses?.status === 5 &&
                        "Reschedule Accepted"}
                      {history.task_config_detail_responses?.status === 6 &&
                        "Reschedule Cancelled"}
                    </span>
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                    }}>
                    {/* {history.status != 0 && ( */}
                    <a
                      href="#"
                      className="cursor_pointer"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row toggle
                        SubTaskDetailHandler(history);
                      }}>
                      More
                    </a>
                    {/* )} */}
                  </td>
                </tr>

                {/* Collapsible Row */}
                {expandedRow === index && (
                  <tr>
                    <td colSpan="6">
                      <div
                        style={{
                          padding: "10px",
                          backgroundColor: "#f8f9fa",
                          borderRadius: "5px",
                        }}>
                        {/* Table container */}
                        <table
                          style={{ width: "100%", borderCollapse: "collapse" }}>
                          <thead></thead>
                          <tbody>
                            <tr>
                              <td colSpan="6">
                                <div
                                  style={{
                                    padding: "10px",
                                    backgroundColor: "#f8f9fa",
                                    borderRadius: "5px",
                                  }}>
                                  {/* Table container with shadow and animation */}
                                  <table
                                    style={{
                                      width: "100%",
                                      borderCollapse: "collapse",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0px 4px 15px rgba(0, 0, 0, 0.1)",
                                      animation: "fadeIn 1s ease-in-out",
                                    }}>
                                    <thead>
                                      <tr>
                                        {/* Field Name Headers */}
                                        <th
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                            fontWeight: "bold",
                                            backgroundColor: "#FFEBCC",
                                            borderTopLeftRadius: "10px",
                                            borderTopRightRadius: "10px",
                                            textAlign: "center",
                                          }}>
                                          Process Name
                                        </th>
                                        <th
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                            fontWeight: "bold",
                                            backgroundColor: "#FFEBCC",
                                            textAlign: "center",
                                          }}>
                                          Task Name
                                        </th>
                                        <th
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                            fontWeight: "bold",
                                            backgroundColor: "#FFEBCC",
                                            textAlign: "center",
                                          }}>
                                          Department
                                        </th>
                                        <th
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                            fontWeight: "bold",
                                            backgroundColor: "#FFEBCC",
                                            textAlign: "center",
                                          }}>
                                          Assigned to
                                        </th>
                                        <th
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                            fontWeight: "bold",
                                            backgroundColor: "#FFEBCC",
                                            borderTopRightRadius: "10px",
                                            textAlign: "center",
                                          }}>
                                          Status
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        {/* Process Name Value */}
                                        <td
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                            backgroundColor: "#FFFAF0",
                                            textAlign: "center",
                                            borderBottomLeftRadius: "10px",
                                          }}>
                                          {history.process.process_name}
                                        </td>
                                        {/* Task Name Value */}
                                        <td
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                            backgroundColor: "#FFFAF0",
                                            textAlign: "center",
                                          }}>
                                          {history.subtask.task_name}
                                        </td>
                                        {/* Department Value */}
                                        <td
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                            backgroundColor: "#FFFAF0",
                                            textAlign: "center",
                                          }}>
                                          {history.department.department_name}
                                        </td>
                                        {/* Assigned to Value */}
                                        <td
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                            backgroundColor: "#FFFAF0",
                                            textAlign: "center",
                                          }}>
                                          {history.user_assigned_to?.name}
                                        </td>
                                        {/* Status Value */}
                                        <td
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                            backgroundColor: "#FFFAF0",
                                            color: "red",
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            borderBottomRightRadius: "10px",
                                          }}>
                                          {history.subtask.status === 0 &&
                                            "Open"}
                                          {history.subtask.status === 1 &&
                                            history.task.subtask_id === null &&
                                            "Completed"}
                                          {history.subtask.status === 1 &&
                                            history.task.subtask_id !== null &&
                                            "Subtask Completed"}
                                          {history.subtask.status === 2 &&
                                            "Rejected"}
                                          {history.subtask.status === 3 &&
                                            "Dependency"}
                                          {history.subtask.status === 4 &&
                                            "Reschedule Requested"}
                                          {history.subtask.status === 5 &&
                                            "Reschedule Accepted"}
                                          {history.subtask.status === 6 &&
                                            "Reschedule Rejected"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableHistory;
