import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Pagination, Form } from "react-bootstrap";
import AddOrganization from "./AddOrganization";
import EditOrganization from "./EditOrganization";
import URL from "../../components/API/Api";
import { FaPen, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import "../Stylesheet/Pages.css";
import Switch from "react-switch";


const Organization = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [allOrganizations, setAllOrganizations] = useState([]);

  // Toggle Modals
  const toggleAddModal = () => setShowAddModal((prev) => !prev);
  const toggleEditModal = () => setShowEditModal((prev) => !prev);

  // Fetch organizations from API
  const fetchOrganizations = async (page = 1) => {
    try {
      setLoading(true);
      const response = await URL.get(`api/org`, { params: { limit, page } });
      const data = response.data.data;
      setOrganizations(data.data); // Current organizations for display
      setAllOrganizations(data.data); // Step 2: Store all organizations for filtering
      setCurrentPage(data.current_page);
      setTotalPages(data.last_page);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching organizations:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganizations(currentPage);
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle Edit Button Click
  const handleEditButtonClick = (organization) => {
    setSelectedOrganization(organization);
    toggleEditModal();
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setCurrentPage(1);

    const filteredOrganizations = allOrganizations.filter((organization) =>
      organization.org_name.toLowerCase().includes(value.toLowerCase())
    );
    setOrganizations(filteredOrganizations);
  };
  // Handle Delete Button Click
  const handleDeleteButtonClick = (organization) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await URL.delete(`api/org/${organization.id}`);
          Swal.fire({
            title: "Deleted!",
            text: "Your organization has been deleted.",
            icon: "success",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
          fetchOrganizations(currentPage);
        } catch (error) {
          console.error("Error deleting organization:", error);
          Swal.fire({
            title: "Error!",
            text: "There was an error deleting the organization.",
            icon: "error",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };
  // Handle Toggle Switch Change
  const handleToggleChange = async (e, organization) => {
    console.log(e,"ss")
    try {
      let status = e;

      let data = {
        status: status,
      };

      console.log(data, "organizationhhh");
      await URL.put(`api/org/${organization.id}`, data);

      fetchOrganizations(currentPage);
    } catch (error) {
      console.error("Error updating organization status:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an error updating the organization status.",
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  // Handle form submission and close modals
  const handleAddOrganizationClose = () => {
    setShowAddModal(false);
    fetchOrganizations(currentPage);
  };

  const handleEditOrganizationClose = () => {
    setShowEditModal(false);
    setSelectedOrganization(null);
    fetchOrganizations(currentPage);
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        {/* Add Organization Modal */}
        <Modal show={showAddModal} onHide={toggleAddModal} centered size="lg">
          <Modal.Body>
            <AddOrganization handleClose={handleAddOrganizationClose} />
          </Modal.Body>
        </Modal>
      </div>

      <div>
        <div className="page-header mb-0">
        
        </div>

        <div className="display_space_between">
        <nav aria-label="breadcrumb" className="mt-1 clip_brdcrumps">
            <div id="clip">
              <span
                style={{
                  padding: "3px 20px",
                  backgroundColor: "#FFA500",
                  color: "white",
                  display: "inline-block",
                  clipPath:
                    "polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)",
                }}>
                <a
                  href="/master/Org"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                  }}>
                  Masters
                </a>
              </span>
              <span
                style={{
                  padding: "3px 20px",
                  backgroundColor: "#FFA500",
                  color: "white",
                  display: "inline-block",
                  clipPath:
                    "polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)",
                  marginRight: "5px", // Adjusted margin for smaller gap
                }}>
                <a
                  href="/master/Org"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                  }}>
                  Organizations
                </a>
              </span>
            </div>
          </nav>

          <div className="d-flex align-items-center">
            <Form className="me-3">
              <Form.Control
                type="search"
                placeholder="Search Organization"
                style={{ width: "250px" }}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </Form>

            <Button
              onClick={toggleAddModal}
              style={{
                background:
                  "linear-gradient(106.37deg, #fca61f 29.63%, #ff8c00 51.55%, #e07b39 90.85%)",
                border: "none",
              }}>
              + Organization
            </Button>
          </div>
        </div>
        <div className="table_css">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th> {/* Index Column */}
                <th>Organization Name</th>
                <th className="text-center">Status</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {organizations.length > 0 ? (
                organizations.map((organization, index) => (
                  <tr key={organization.id}>
                    <td>{(currentPage - 1) * limit + index + 1}</td>{" "}
                    {/* Display Index */}
                    <td>{organization.org_name}</td>
                    <td align="center">
                      {" "}
                     

                      <Switch
                        onChange={(checked) => handleToggleChange(checked, organization)}
                        checked={organization.status===1}
                        onColor="#0d6efd"
                        offColor="#6c757d"
                        uncheckedIcon={true}
                        checkedIcon={true}
                        height={20}
                        width={42}
                        handleDiameter={18}
                        className="custom-switch"
                      />
                    </td>
                    <td align="center">
                      <FaPen
                        style={{
                          cursor: "pointer",
                          color: "green",
                          marginRight: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleEditButtonClick(organization)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    {" "}
                    {/* Adjusted to span 3 columns */}
                    No organizations found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <div className="pagination_styles">
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Pagination>
        </div>
      </div>

      {/* Edit Organization Modal */}
      <Modal show={showEditModal} onHide={toggleEditModal} centered size="lg">
        <Modal.Body>
          {selectedOrganization && (
            <EditOrganization
              handleClose={handleEditOrganizationClose}
              organizationId={selectedOrganization.id}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Organization;
