import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Card } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/AuthSlice";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import logo from "../../Assets/Images/fullwhitelogo.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.auth);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const resultAction = await dispatch(
        loginUser({
          email: email,
          password: password,
          role: [
            "Super Admin",
            "Sub Admin",
            "Admin",
            "Staff",
            "Sales Executive",
          ],
        })
      );

      if (loginUser.fulfilled.match(resultAction)) {
        Swal.fire({
          title: "Loading...",
          text: "Please wait....",
          timer: 1000,
          showConfirmButton: false,
        }).then(() => {
          const user = localStorage.getItem("mikro_user");
          if (user) {
            const parsedUser = JSON.parse(user);
            console.log(parsedUser,"parsedUser")
            const isAuthenticated =
              parsedUser.status == true &&
              parsedUser?.data?.token &&
              parsedUser.data.token.trim() !== "" &&
              parsedUser.message &&
              parsedUser.message.trim() == "Logged IN Successfully" 
              &&
              parsedUser?.data?.user_org_depts?.length>0 
              // &&
              // parsedUser.data.user_code.trim() !== "";

            if (isAuthenticated) {

                // Navigate to the dashboard
                navigate("/dashboard");

                Swal.fire({
                  title: "Login Successful!",
                  text: "You have successfully logged in.",
                  icon: "success",
                  timer: 2000,
                  showConfirmButton: false,
                });
              }
             else if(parsedUser?.data?.user_org_depts?.length < 1) {
                Swal.fire({
                  title: "You dont have Access",
                  text: "Contact Admin to assign in a department",
                  icon: "error",
                  timer: 2000,
                  showConfirmButton: false,
                });
                localStorage.removeItem("mikro_user");
              }
              
              else {

                Swal.fire({
                  title: "Contact Admin",
                  text: "User not allowed",
                  icon: "error",
                  timer: 2000,
                  showConfirmButton: false,
                });
                localStorage.removeItem("mikro_user");
              }
            }
          })
        
      } else {
        Swal.fire({
          title: "Login Failed!",
          text: "User Not Found",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Login failed:", error);
      Swal.fire({
        title: "Error!",
        text: "Something Went Wrong",
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
  };

  return (
    <div className="login-container">
      <Container fluid className="h-100">
        <Row className="h-100">
          {/* Left Section: Image Section */}
          <Col lg={6} md={6} sm={12} className="left-section">
            <div className="image-container hide-on-mobile">
              <img
                src="https://berighthere.com/wp-content/uploads/2022/12/Who-are-we-Medium.png"
                alt="Girl sitting with laptop"
                className="login-image"
              />
            </div>
          </Col>

          {/* Right Section: Form Section */}
          <Col
            lg={6}
            md={6}
            sm={12}
            className="right-section d-flex align-items-center justify-content-center">
            <Card className="login-card">
              <div className="text-center mb-4">
                <img
                  src={logo}
                  alt="MIKRO CENTRAL Logo"
                  className="login-logo"
                />
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mt-4" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="login-input"
                  />
                </Form.Group>

                <Form.Group className="mt-4" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <div className="d-flex align-items-center position-relative">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="login-input"
                    />
                    {showPassword ? (
                      <AiFillEyeInvisible
                        size={30}
                        className="cursor-pointer login-eye-icon"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <AiFillEye
                        size={30}
                        className="cursor-pointer login-eye-icon"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                </Form.Group>

                <div className="text-center mt-4">
                  <Button
                    className="login-btn"
                    variant="outline-light"
                    type="submit"
                    size="md">
                    {status === "loading" ? "Logging in..." : "Login"}
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
