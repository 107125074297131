import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  Badge,
  Modal,
} from "react-bootstrap";
import { Ticket } from "./Ticket";
import AddCustomTask from "../Task/AddCustomTask";
import { formatDistanceToNow, parseISO } from "date-fns";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";
import "../Stylesheet/Pages.css";
import { FaCheck, FaTimes } from "react-icons/fa"; // FontAwesome Icons for tick and cross

import {
  FcAddDatabase,
  FcList,
  FcProcess,
  FcCheckmark,
  FcExpired,
} from "react-icons/fc";
import Charts from "./Data/Chart";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import URL from "../../components/API/Api";
import { SubTaskDetail } from "./SubTaskDetail";
import TableHistory from "./TableHistory";
import Navbar from "../../components/NavComponents/Navbar";

// Register necessary Chart.js components
ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const statusLabels = {
  0: "Open",
  1: "Completed",
  2: "Dependency",
  3: "Reject",
  4: "Reschedule Request",
  5: "Reschedule Accept",
  6: "Reschedule Reject",
};

const Dashboard = () => {
  const location = useLocation();
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [headerdontShow, setheaderdontShow] = useState(false);
  const [FlowTask, setFlowTask] = useState([]);
  const [parsedNotifications, setparsedNotifications] = useState([]);
  const [notificationLength, setNotificationLength] = useState();
  const [secondparsedNotifications, setSecondparsedNotifications] = useState(
    []
  );
  const [historyofNotification, sethistoryofNotification] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("");
  const [filteredNotifications, setFilteredNotifications] =
    useState(parsedNotifications);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState([]);
  // const [getingLastStatus, setgetingLastStatus] = useState({});
  const [notificationData, setNotificationData] = useState({});
  const [taskStatus, setTaskStatus] = useState(); // Store the current task status
  const [taskRemarks, setTaskRemarks] = useState(""); // Store the current task status
  // const [taskResponseMsg, setTaskResponseMsg] = useState(""); // Store the current task status
  const [lastassignedTo, setlastassignedTo] = useState(null); // Track the initial status for comparison
  const [isStatusChanged, setIsStatusChanged] = useState(false); // Track if the status has changed
  const [remarkstate, setremarkstate] = useState(false); // Track if the status has changed
  const [reassignUser, setReassignUser] = useState("");
  const [users, setUsers] = useState([]);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [taskHistory, setTaskHistory] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sendExactRead, setsendExactRead] = useState(false);
  const [editSLA, seteditSLA] = useState("");
  const [defaultOrgDeptIDofHeader, setdefaultOrgDeptIDofHeader] = useState();

  const [moreInfo, setmoreInfo] = useState({});
  const [CountInformation, setCountInformation] = useState({});

  const [isSLAResolutionHour, setisSLAResolutionHour] = useState(0);
  const [Thours, setHours] = useState(""); // To store hours if SLA is based on hours
  const [Tminutes, setMinutes] = useState(""); // To store minutes if SLA is based on hours
  const [divert, setDivert] = useState("");

  const [isMatched, setIsMatched] = useState(false);
  const [propertyDetail, setpropertyDetail] = useState(false);

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [myParentTaskId, setmyParentTaskId] = useState(null);
  const [IsParentOrSub, setIsParentOrSub] = useState(1);
  const [RequestGetter, setRequestGetter] = useState();
  const [ActualgetterData, setActualgetterData] = useState();

  const [subtaskDetailSlider, setsubtaskDetailSlider] = useState(false);
  const [RescheduleAcceptModal, setRescheduleAcceptModal] = useState(false);
  const [RescheduleRejectModal, setRescheduleRejectModal] = useState(false);
  // const storedNotification = localStorage.getItem("userNotification");
  const getUser = localStorage.getItem("mikro_user");
  const parsedUser = JSON.parse(getUser);
  const [filteringlastindex, setfilteringlastindex] = useState([]);
  const [commonerr, setcommonerr] = useState("");
  const [IdOfOpenmodal, setIdOfOpenmodal] = useState(null);
  const [mySubTaskDetail, setMySubTaskDetail] = useState();

  // organization_dept_task_receiver
  // console.log(parsedUser?.data.id,"parsedUSer")
  // const parsedNotifications = JSON.parse(storedNotification);

  console.log(filteringlastindex, "notificationLength");

  // TicketData
  const ticketData = [
    {
      name: "All Tasks",
      count: CountInformation?.AllTaskCount,
      icon: <FcAddDatabase />,
      background:
        "linear-gradient(to right, rgb(250 202 207), rgb(255 204 188 / 18%))",
      Graphbackground: ["rgb(250, 202, 207)", "rgb(250, 202, 207)"],
    },
    {
      name: "Automated Tasks",
      count: CountInformation?.AutomatedTaskCount,
      icon: <FcList />,
      background: "linear-gradient(to right, #bbdefb, #e3f2fd)",
      Graphbackground: ["rgb(187, 222, 251)", "rgb(187, 222, 251)"],
    },
    {
      name: "Custom Tasks",
      count: CountInformation?.CustomTaskCount,
      icon: <FcProcess />,
      background:
        "linear-gradient(to right, rgb(247 212 253), rgb(225 190 231 / 30%))",
      Graphbackground: ["rgb(247, 212, 253)", "rgb(247, 212, 253)"],
    },
    {
      name: "Closed Tasks",
      count: CountInformation?.AutomatedClosedTaskCount,
      icon: <FcCheckmark />,
      background:
        "linear-gradient(to right, rgb(178, 251, 194), rgb(178 251 194 / 29%))",
      Graphbackground: ["rgb(178, 251, 194)", "rgb(178, 251, 194)"],
    },

    {
      name: "Sub Tasks",
      count: CountInformation?.CustomClosedTaskCount,
      icon: <FcExpired />,
      background:
        "linear-gradient(to right, rgb(255, 227, 171), rgb(255 244 222 / 64%))",
      Graphbackground: ["rgb(255, 227, 171)", "rgb(255, 227, 171)"],
    },
  ];

  useEffect(() => {
    NotificationCall(org_dept_id);
    GetCountOfAll(org_dept_id);
  }, [modalVisible]);

  console.log(selectedNotification, "jjkkll");

  // useEffect(() => {
  //   NotificationCall();
  //   GetCountOfAll();
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      NotificationCall();
      GetCountOfAll();
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  console.log(filteringlastindex, "RequestGetter");

  const GetCountOfAll = (org_dept_id) => {
    const orgDeptId =
      org_dept_id !== undefined
        ? org_dept_id
        : JSON.parse(localStorage.getItem("mikro_departments"));

    let data = {
      user_id: parsedUser?.data.id,
      org_dept_id: orgDeptId,
    };
    const params = new URLSearchParams(data).toString();

    URL.get(
      `/api/v1/taskConfigDetailsResponse/fetchCountUnderDepartment?${params}`
    )
      .then((res) => {
        // console.log(res.data.data,"mysis")
        setCountInformation(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const HandleModalofReschedule = (data) => {
    setRescheduleAcceptModal(true);
    setRescheduleRejectModal(false);
  };

  const HandleModalofRescheduleReject = (data) => {
    setRescheduleRejectModal(true);
    setRescheduleAcceptModal(false);
  };

  // console.log(isPanelOpen,"mypanelOpwn");

  const NewRejectFunctionClose = () => {
    setRescheduleAcceptModal(false);
  };

  const NewAcceptFunctionClose = () => {
    setRescheduleAcceptModal(false);
  };

  //Notification
  const NotificationCall = (org_dept_id) => {
    const orgDeptId =
      org_dept_id !== undefined || null
        ? org_dept_id
        : JSON.parse(localStorage.getItem("mikro_departments"));
    const payload = {
      user_id: parsedUser?.data.id,
      org_dept_id: orgDeptId,
    };

    // console.log("one", payload);
    const params = new URLSearchParams(payload).toString();

    URL.get(`/api/v1/taskConfigDetailsResponse/getUserNotifications?${params}`)
      .then((res) => {
        // console.log(res.data.data, "twoViiew");
        setparsedNotifications(res.data.data);
        setFilteredNotifications(res.data.data);
        setNotificationLength(res.data.data?.length);
        setSecondparsedNotifications(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const org_dept_id = location.state?.org_dept_id;

  useEffect(() => {
    if (org_dept_id) {
      NotificationCall(org_dept_id);
      GetCountOfAll(org_dept_id);
    }
  }, [org_dept_id]);

  const handleReassignChange = (e) => {
    // console.log("Selected user ID:", e.target.value); // Debugging line
    setReassignUser(e.target.value);
    // console.log("resuser",reassignUser)
  };

  // console.log(users,"manomani")
  const fetchUsers = async (selectedNotification) => {
    try {
      //  const taskId = selectedNotification?.task?.id;
      const dept_id = selectedNotification?.department.id;
      const dept_role_id = selectedNotification?.department_role.id;
      // console.log(dept_role_id, "dept_role_id");
      const response = await URL.get("/api/task/usersData", {
        params: {
          dept_id: dept_id,
          dept_role_id: dept_role_id,
        },
      });

      if (response.data.status) {
        setUsers(response.data.data); // Set users from API response
        // console.log(users, "tkkk");
      } else {
        // console.error("Error fetching users:", response.data.message);
      }
    } catch (error) {
      console.error("API call failed:", error);
    }
  };

  const convertTimeString = (timeStr) => {
    if (!timeStr) return "0h 0m"; // Handle empty or undefined input

    // Remove spaces and split the string into hours and minutes
    const [hours, minutes] = timeStr.replace(/\s/g, "").split(":").map(Number);

    return `${hours} hour ${minutes} minutes`;
  };

  useEffect(
    (selectedNotification) => {
      // console.log("selectedNotification.status ", selectedNotification.status);
      // Ensure selectedNotification is an object and status exists
      if (selectedNotification && taskStatus === "2") {
        fetchUsers(selectedNotification); // Fetch users when Reassign is selected
      }
    },
    [taskStatus]
  );

  useEffect(() => {
    const DepartmentIDS = JSON.parse(localStorage.getItem("mikro_departments"));
    setdefaultOrgDeptIDofHeader(DepartmentIDS);
  }, [selectedNotification]);

  // task_config_detail_id
  useEffect(() => {
    if (modalVisible && selectedNotification) {
      // Filter notifications based on task_id and process_id
      const history = parsedNotifications.filter((notification) => {
        return (
          notification.task.id === selectedNotification?.task?.id &&
          notification.process_id === selectedNotification?.process_id
        );
      });

      // Filter notifications based on the selected task_config_id
      const filteredNotifications = secondparsedNotifications.filter(
        (x) =>
          x.task_config_detail?.id ===
          selectedNotification?.task_config_detail?.id
      );

      // console.log(filteredNotifications,"fltyuip")

      // Sort the filtered history by `created_at`
      const sortedHistory = filteredNotifications.sort((a, b) => {
        return moment(a.created_at).isBefore(moment(b.created_at)) ? -1 : 1;
      });

      // Map data to the desired format
      const formattedHistory = sortedHistory.map((notification) => ({
        user: notification.user_assigned_by?.name,
        usermail: notification.user_assigned_by?.email,
        assignedTo: notification.assigned_to,
        assignedToName: notification.task_assignee_name,
        remarks: notification.remarks,
        status: notification.status,
        tName: notification.task[0]?.task_name,
        createdAt: moment(notification.created_at).format(
          "MMMM Do YYYY, h:mm A"
        ), // Human-readable date format
        finalStatus: getBadgeInfo(notification.status),
      }));

      setTaskHistory(formattedHistory);
    }
  }, [
    modalVisible,
    selectedNotification,
    parsedNotifications,
    filteredNotifications,
  ]);

  // console.log(taskHistory,"myparsing");
  const getBadgeInfo = (status) => {
    switch (status) {
      case 0:
        return { text: "Open", color: "secondary" };
      case 1:
        return { text: "Completed", color: "secondary" };
      case 2:
        return { text: "Reassigned", color: "secondary" };
      case 3:
        return { text: "Rejected", color: "secondary" };
      case 4:
        return { text: "Reschedule Request", color: "secondary" };
      case 5:
        return { text: "Reschedule Accepted", color: "secondary" };
      case 6:
        return { text: "Reschedule Cancelled", color: "secondary" };
      default:
        return { text: "Unknown", color: "secondary" };
    }
  };

  //createGradient
  const createGradient = (ctx, colors) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);

    colors.forEach((color, index) => {
      const rgbaMatch = color.match(
        /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d?\.?\d*))?\)/
      );
      if (rgbaMatch) {
        const [_, r, g, b, a] = rgbaMatch;
        gradient.addColorStop(
          index / (colors.length - 1),
          `rgba(${r}, ${g}, ${b}, ${a ? a : 1})` // Default alpha to 1 if not defined
        );
      } else {
        // console.warn(`Invalid color format: ${color}`);
      }
    });

    return gradient;
  };

  // chart data
  const chartData = {
    labels: ticketData.map((ticket) => ticket.name),
    datasets: [
      {
        label: "Tasks Data",
        data: ticketData.map((ticket) => ticket.count),
        backgroundColor: ticketData.map((ticket) => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          return createGradient(ctx, ticket.Graphbackground);
        }),
      },
    ],
  };

  console.log(selectedNotification, "fsindex");

  const [myLastIndexTask, setmyLastIndexTask] = useState();
  // Function to open the modal and set the selected notification
  const openModal = (notification) => {
    console.log(notification, "notiiiiifffff");
    let newIsParentOrSub;
    if (notification?.is_parent === 0) {
      newIsParentOrSub = 0;
    }
    if (notification?.is_parent === 1) {
      newIsParentOrSub = 1;
    }
    //  setIsParentOrSub(newIsParentOrSub);
    setIdOfOpenmodal(notification.id);

    let d3b = {
      id: notification.id,
      is_parent_task: newIsParentOrSub,
      owner_id: parsedUser.data?.id,
    };
    // console.log(d3b, notification?.is_parent, "d3b");

    // URL.get(`api/taskConfigDetailsResponse/getNotificationsByParentTaskId/${notification?.parent_task_id}`)

    const queryParams = new URLSearchParams(d3b).toString();

    URL.get(
      `api/taskConfigDetailsResponse/getNotificationsByParentTaskId?${queryParams}`
    )

      .then((res) => {
        // console.log(res.data?.data, "notifview");
        // console.log(res?.data?.data?.history?.original?.data, "notifviewone");
        setSelectedNotification(res.data.data);

        // Extracts the subtask ID from the response, searches for a matching subtask in the task history, and updates the state with the task name if a match is found.
        const lastSubtaskId = res?.data?.data?.lastTaskIndex?.subtask_id;
        const matchingSubtask = res?.data?.data?.history?.original?.data?.find(
          (item) => item.subtask?.id == lastSubtaskId
        );

        console.log(matchingSubtask, "matchingSubtask");

        if (matchingSubtask) {
          const subtaskDetails = matchingSubtask;
          console.log(subtaskDetails, "mm");
          setMySubTaskDetail(subtaskDetails);
        }

        setfilteringlastindex(
          selectedNotification?.history?.original?.data?.filter(
            (item) =>
              item.task_config_detail_responses.id ==
              selectedNotification?.lastTaskIndex?.id
          )
        );

        // setgetingLastStatus(res.data.data.history?.original?.data);
        // console.log(res.data,"getlast")
        MyReadFunction(res.data.data);

        setmyParentTaskId(res.data?.data?.parent_task_id);

        // setSecondparsedNotifications(res.data.data);
      })
      .catch((err) => console.log(err));

    const LastIndexNotification = notification?.task;
    const MyLast = LastIndexNotification[LastIndexNotification.length - 1];
    // console.log(MyLast,"lastindex")
    setmyLastIndexTask(MyLast);

    setNotificationData(notification);
    setModalVisible(true);
    const org_dept_id = notification?.task?.department?.org_dept_id;

    if (org_dept_id) {
      NotificationCall(org_dept_id);
      GetCountOfAll(org_dept_id);
    }
  };

  const MyReadFunction = (dataf) => {
    const LoggenInUser = parsedUser?.data?.id;

    const TaskUpdateData = {
      user_id: LoggenInUser,
    };

    const MyNewPayload = TaskUpdateData;

    URL.put(
      `/api/taskConfigDetailsResponse/${dataf.id}/updateIsReadStatus`,
      MyNewPayload
    )
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => console.log(err));
  };

  // Function to close the modal
  const closeModal = () => {
    setModalVisible(false);
    setTaskStatus();
    setTaskRemarks();
    // setTaskResponseMsg();
    setImagePreview();
    setSelectedNotification({}); // Clear the selected notification
  };

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    // console.log(newStatus, "rt");
    // setSelectedNotification({ status: newStatus });
    // setSelectedNotification({ ...selectedNotification, status: newStatus });
    setTaskStatus(newStatus);
    setIsStatusChanged(true);

    if (newStatus == 2) {
      setIsPanelOpen(true);
      setsubtaskDetailSlider(false);
    } else {
      setIsPanelOpen(false);
    }
  };

  const SubTaskDetailHandler = (data) => {
    // console.log(data,"mstr1")
    setmoreInfo(data);
    setIsPanelOpen(true);
    setTaskStatus(0);
    setsubtaskDetailSlider(true);
  };

  const SubTaskModalCloseHandler = () => {
    setIsPanelOpen(false);
    setmoreInfo();
  };

  // console.log(users,"userFetching");
  const [imagePreview, setImagePreview] = useState("");

  const formattedDate = (dateString) => {
    if (!dateString) return ""; // Handle undefined/null values
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  const calculateEndTime = (createdAt, resolutionEndTime, timeType) => {
    if (!createdAt || resolutionEndTime == null) return ""; // Handle invalid inputs

    let date = new Date(createdAt);

    if (timeType === 1) {
      const [hours, minutes] = resolutionEndTime.split(":").map(Number);

      date.setHours(date.getHours() + hours);
      date.setMinutes(date.getMinutes() + minutes);
    } else {
      // Add days, skipping Sundays
      let daysAdded = 0;
      while (daysAdded < resolutionEndTime) {
        date.setDate(date.getDate() + 1);
        if (date.getDay() !== 0) {
          daysAdded++; // Only count non-Sunday days
        }
      }
    }

    // Format output
    return date.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  // Example usage:
  const createdAt = "2025-03-05T13:11:00Z"; // Sample created_at timestamp
  const resolutionEndTime = 5; // Add 5 hours or days
  const timeType = 2; // 1 = Hours, 2 = Days

  // console.log(calculateEndTime(createdAt, resolutionEndTime, timeType));

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setUploadedDocument(file);
        setImagePreview(reader.result); // Data URL of the image
      };

      reader.readAsDataURL(file); // Read the file as a data URL (base64 string)
    }
  };

  const SliderChange = () => {
    setpropertyDetail(!propertyDetail);
    if (isPanelOpen === true) {
      setIsPanelOpen(!isPanelOpen);
    }
  };

  // console.log(selectedNotification?.history?.original?.data[0]
  //   ?.user_assigned_to.id,"defaultOrgDeptIDofHeader" ,parsedUser?.data.id)

  const AcceptRescheduleHandler = (doneR) => {
    // console.log(selectedNotification, "ssg", doneR);

    const TaskUpdateData = {
      sub_task_parent_id:
        selectedNotification?.sub_task_parent_id == ""
          ? ""
          : selectedNotification?.sub_task_parent_id,
      subtask_id:
        selectedNotification?.subtask_id == ""
          ? ""
          : selectedNotification?.subtask_id,

      task_id: myLastIndexTask.id,
      response_id: selectedNotification?.id,
      is_accepted_by_owner: 1,
      status: 5,
      sla_resolution_request_time:
        selectedNotification?.sla_resolution_request_time,
      is_resolution_request_hour:
        selectedNotification?.is_resolution_request_hour,
      property_id: selectedNotification?.property_details?.id,
      user_id: parsedUser.data?.id,
      sub_task_parent_id: myLastIndexTask.id,
      assigned_to: RequestGetter?.task_config_detail_responses.user_id,
      organization_dept_task_creator: defaultOrgDeptIDofHeader,
      organization_dept_task_receiver:
        RequestGetter?.task_config_detail_responses
          ?.organization_dept_task_creator,
    };

    // console.log(TaskUpdateData);
    URL.put(`/api/taskConfigDetailsResponse/ownerTaskResponse`, TaskUpdateData)
      .then((res) => {
        // console.log(res);
        setRescheduleAcceptModal(false);
        Swal.fire({
          // title: "Error!",
          text: "Reschedule request has been accepted",
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setcommonerr(err.response.data.errors[0]);
      });
  };

  // console.log(remarkstate, "reststtate");

  const RejectRescheduleHandler = (doneR) => {
    // console.log(selectedNotification,"miss")
    if (taskRemarks?.length < 0) {
      setremarkstate(true);
    }
    const TaskUpdateData = {
      sub_task_parent_id:
        selectedNotification?.sub_task_parent_id == ""
          ? ""
          : selectedNotification?.sub_task_parent_id,
      subtask_id:
        selectedNotification?.subtask_id == ""
          ? ""
          : selectedNotification?.subtask_id,
      remarks: taskRemarks,
      task_id: myLastIndexTask.id,
      property_id: selectedNotification?.property_details?.id,
      response_id: selectedNotification?.id,
      is_accepted_by_owner: 0,
      sla_resolution_request_time:
        selectedNotification?.sla_resolution_request_time,
      is_resolution_request_hour:
        selectedNotification?.is_resolution_request_hour,

      status: 6,
      user_id: parsedUser.data?.id,
      assigned_to: RequestGetter?.task_config_detail_responses.user_id,
      organization_dept_task_creator: defaultOrgDeptIDofHeader,
      organization_dept_task_receiver:
        RequestGetter?.task_config_detail_responses
          ?.organization_dept_task_creator,
    };

    // console.log(TaskUpdateData, "taskdata");
    URL.put(`/api/taskConfigDetailsResponse/ownerTaskResponse`, TaskUpdateData)
      .then((res) => {
        setRescheduleRejectModal(false);
        Swal.fire({
          text: "Reschedule request has been cancelled",
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        setremarkstate(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        // setcommonerr(err.response.data.errors[0]);
      });
  };

  // console.log(selectedNotification, "spd_emergency");

  const handleResolutionChange = (e) => {
    const value = e.target.value;
    setisSLAResolutionHour(value);

    // If switching to hours, initialize Thours and Tminutes
    if (value === "1") {
      // Convert `editSLA` to hours and minutes if it exists
      const [hours, minutes] = editSLA
        .split(":")
        .map((num) => parseInt(num, 10));
      setHours(hours || 1); // Default to 1 if no valid hours found
      setMinutes(minutes || 0); // Default to 0 if no valid minutes found
    } else {
      seteditSLA(""); // Reset editSLA if not in hours mode
    }
  };

  // console.log(selectedNotification, "sghyuiashdajhsd");

  const handleStatusUpdate = async () => {
    setIsSubmitting(true);
    const taskId = selectedNotification.id;
    const formData = new FormData();
    const lastIndex = selectedNotification?.history?.original?.data?.length - 1;

    // console.log(selectedNotification, "myerthjk");

    // if(uploadedDocument==null) {
    //   console.log("donotgo");
    //   return 1;
    // }

    if (
      selectedNotification.status == 2 ||
      (selectedNotification?.subtask_id !== null &&
        selectedNotification?.sub_task_parent_id !== null)
    ) {
      console.log("imworkingfornosubtask");
      formData.append("status", taskStatus);
      formData.append("remarks", taskRemarks);
      formData.append(
        "task_config_detail_id",
        selectedNotification?.task_config_detail_id
      );
      formData.append(
        "property_id",
        selectedNotification?.property_details?.id
      );
      formData.append("process_id", selectedNotification?.process.id);
      formData.append("task_id", selectedNotification?.task_id);
      formData.append("user_id", parsedUser.data?.id);
      formData.append(
        "assigned_to",
        ActualgetterData?.task_config_detail_responses?.user_id
      );
      formData.append(
        "organization_dept_task_creator",
        defaultOrgDeptIDofHeader
      );

      if (taskStatus == 4) {
        setDivert(`${Thours}:${Tminutes}`);

        let slaResolutionTime = editSLA;

        if (
          (isSLAResolutionHour === 1 || isSLAResolutionHour === "1") &&
          Thours &&
          Tminutes
        ) {
          slaResolutionTime = `${Thours}:${Tminutes}`;
        } else if (isSLAResolutionHour === "0" || isSLAResolutionHour === 0) {
          slaResolutionTime = editSLA;
        }

        formData.append("sla_resolution_request_time", slaResolutionTime);
        formData.append("is_resolution_request_hour", isSLAResolutionHour);
      }

      formData.append(
        "organization_dept_task_receiver",
        ActualgetterData?.task_config_detail_responses
          ?.organization_dept_task_creator
      );

      if (selectedNotification?.is_custom_Process === 0) {
        formData.append("is_custom_Process", 0);
      }

      formData.append("subtask_id", selectedNotification?.subtask_id);
      if (uploadedDocument) {
        formData.append("uploaded_document", uploadedDocument);
      }

      try {
        const response = await URL.post(
          `api/taskConfigDetailsResponse/subtaskUpdate/${selectedNotification.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setTaskStatus();
        setTaskRemarks();
        setImagePreview();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Sub Task updated successfully!",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "#28a745",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        setIsSubmitting(false);

        closeModal();
      } catch (error) {
        console.error("Error uploading the data", error);
        setcommonerr(error.response.data.errors[0]);

        const errorMessage =
          error?.response?.data?.errors?.[0] ||
          error?.response?.data?.message ||
          "An unexpected error occurred. Please try again.";
        setIsSubmitting(false);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "#dc3545",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }

      console.log("itsnewsubtaskmethodofnewapi");
    } else {
      console.log("imworkingfornoreason", taskStatus);

      setDivert(`${Thours}:${Tminutes}`);

      let slaResolutionTime = editSLA;

      if (
        (isSLAResolutionHour === 1 || isSLAResolutionHour === "1") &&
        Thours &&
        Tminutes
      ) {
        slaResolutionTime = `${Thours}:${Tminutes}`;
      } else if (isSLAResolutionHour === "0" || isSLAResolutionHour === 0) {
        slaResolutionTime = editSLA;
      }

      if (selectedNotification?.sub_task_parent_id !== null) {
        formData.append("sub_task_parent_id", myLastIndexTask.id);
      } else {
        formData.append("sub_task_parent_id", "");
      }

      formData.append(
        "property_id",
        selectedNotification?.property_details?.id
      );

      formData.append("task_name", selectedNotification.task.task_name);

      formData.append(
        "slaResolution",
        selectedNotification.task.sla_resolution_time
      );
      formData.append("due_date", selectedNotification.task.due_date);
      formData.append(
        "task_description",
        selectedNotification.task.task_description
      );
      formData.append("status", taskStatus);
      formData.append("remarks", taskRemarks);
      formData.append(
        "task_config_detail_id",
        selectedNotification?.task_config_detail_id
      );
      formData.append("process_id", selectedNotification?.process.id);
      formData.append("task_id", selectedNotification?.task_id);
      formData.append("user_id", parsedUser.data?.id);
      formData.append(
        "is_custom_Process",
        selectedNotification?.is_custom_Process
      );
      formData.append("sla_resolution_request_time", slaResolutionTime);
      formData.append("is_resolution_request_hour", isSLAResolutionHour);
      formData.append("task_type", "custom");
      if (uploadedDocument) {
        formData.append("uploaded_document", uploadedDocument);
      }

      console.log("itsoldsubtaskmethodofnewapi", formData);

      const currentSubTaskId = selectedNotification.subtask_id;

      if (
        selectedNotification?.status == 5 ||
        selectedNotification?.status == 6
      ) {
        const filteredData =
          selectedNotification?.history?.original?.data?.filter(
            (c) =>
              c.task_config_detail_responses.subtask_id &&
              c.task_config_detail_responses.status === 2 &&
              c.task_config_detail_responses.subtask_id === currentSubTaskId
          );

        const filteredDataas = filteredData[0]?.user_assigned_by?.id;

        formData.append(
          "is_custom_Process",
          selectedNotification?.is_custom_Process
        );
        formData.append(
          "assigned_to",
          ActualgetterData?.task_config_detail_responses?.user_id
        );
        formData.append(
          "organization_dept_task_creator",
          defaultOrgDeptIDofHeader
        );
        formData.append(
          "organization_dept_task_receiver",
          ActualgetterData?.task_config_detail_responses
            ?.organization_dept_task_creator
        );
        formData.append(
          "property_id",
          selectedNotification?.property_details?.id
        );

        console.log("this one");
      } else if (
        selectedNotification?.subtask_id == selectedNotification?.task?.id &&
        selectedNotification.task.status == 1
      ) {
        formData.append(
          "assigned_to",
          selectedNotification?.history?.original?.data[0]
            ?.task_config_detail_responses?.user_id
        );
        formData.append(
          "is_custom_Process",
          selectedNotification?.is_custom_Process
        );

        formData.append(
          "organization_dept_task_creator",
          defaultOrgDeptIDofHeader
        );
        formData.append("is_subparent", "");
        formData.append("subtask_id", "");
        formData.append(
          "organization_dept_task_receiver",
          selectedNotification?.history?.original?.data[0]
            ?.task_config_detail_responses?.organization_dept_task_creator
        );
        console.log("im maintask");
      } else {
        formData.append(
          "assigned_to",
          ActualgetterData?.task_config_detail_responses?.user_id
        );
        formData.append(
          "is_custom_Process",
          selectedNotification?.is_custom_Process
        );

        formData.append(
          "organization_dept_task_creator",
          defaultOrgDeptIDofHeader
        );
        formData.append(
          "property_id",
          selectedNotification?.property_details?.id
        );

        formData.append(
          "organization_dept_task_receiver",
          ActualgetterData?.task_config_detail_responses
            ?.organization_dept_task_creator
        );
      }

      console.log(selectedNotification?.subtask_id, "subtasjjjjjkkk");

      try {
        const response = await URL.post(
          `api/taskConfigDetailsResponse/${taskId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setTaskStatus();
        setTaskRemarks();
        setImagePreview();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Task updated successfully!",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "#28a745",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        setIsSubmitting(false);

        closeModal();
      } catch (error) {
        console.error("Error uploading the data", error);
        setcommonerr(error.response.data.errors[0]);
        const errorMessage =
          error?.response?.data?.errors?.[0] ||
          error?.response?.data?.message ||
          "An unexpected error occurred. Please try again.";
        setIsSubmitting(false);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "#dc3545",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    }
  };

  const formatDueDate = () => {
    const { sla_resolution_time, is_resolution_hour } =
      selectedNotification.task;

    if (is_resolution_hour === 0) {
      // If is_resolution_hour is 0, it's a day
      const dayCount = sla_resolution_time;
      return `${dayCount} day${dayCount > 1 ? "s" : ""}`;
    } else if (is_resolution_hour === 1) {
      // If is_resolution_hour is 1, it's time in hours and minutes
      const timeParts = sla_resolution_time.split(":");
      const hours = timeParts[0];
      const minutes = timeParts[1] || 0;
      return `${hours} hour${hours > 1 ? "s" : ""} ${minutes} min${
        minutes > 1 ? "s" : ""
      }`;
    }
    return "";
  };

  const getNotificationMessage = (item) => {
    const {
      status,
      task_assignee_name,
      is_custom_Process,
      userAssignedByDetail,
      userAssignedToDetail,
      is_reassigned,
      search_from,
      task,
      process,
      department,
      assigned_to,
      property_details,
    } = item;

    const taskName = task[0]?.task_name;
    const processName = process?.process_name;
    const departmentName = department?.department_name;
    const AssignedOrRequestFrom = userAssignedByDetail?.name;
    const AssignedOrRequestFromID = userAssignedByDetail?.id;
    const AssignedOrRequestToID = userAssignedToDetail?.id;
    const AssignedTo = task_assignee_name;
    const YourActivityAction = search_from;
    const is_custom_task = is_custom_Process;
    const propertyDetails = property_details?.property_name;
    if (
      status === 0 &&
      YourActivityAction === "assigned_user" &&
      is_custom_task == 1
    ) {
      return (
        <>
          You received the task <b>{taskName}</b> under the
          {departmentName} for {propertyDetails} department assigned by{" "}
          {AssignedOrRequestFrom}.
        </>
      );
    }
    if (
      status === 0 &&
      YourActivityAction === "assigned_user" &&
      is_custom_task == 0
    ) {
      return (
        <>
          You received the task <b>{taskName}</b> for for {propertyDetails}{" "}
          under the
          {departmentName} department.
        </>
      );
    }
    if (status === 0 && YourActivityAction === "user") {
      return (
        <>
          You assigned the <b>{taskName}</b> task under the {departmentName}{" "}
          department to {AssignedTo}.
        </>
      );
    }

    if (status === 1 && YourActivityAction === "assigned_user") {
      return (
        <>
          The <b>{taskName}</b> task has been closed by {AssignedOrRequestFrom}.
        </>
      );
    }

    if (status === 1 && YourActivityAction === "user") {
      return (
        <>
          The <b>{taskName}</b> task has been marked as closed by you.
        </>
      );
    }

    if (status === 2 && is_reassigned === 1) {
      return (
        <>
          You reassigned the <b>{taskName}</b> task to {AssignedTo}.
        </>
      );
    }

    if (
      status === 2 &&
      (AssignedOrRequestFromID || AssignedOrRequestToID) !== parsedUser.data?.id
    ) {
      return (
        <>
          You have update for the <b>{taskName}</b> task to the {departmentName}{" "}
          department.
        </>
      );
    }

    if (
      status === 2 &&
      (AssignedOrRequestFromID || AssignedOrRequestToID) === parsedUser.data?.id
    ) {
      return (
        <>
          You reassigned the <b>{taskName}</b> task to the {departmentName}{" "}
          department.
        </>
      );
    }

    if (status === 3) {
      return (
        <>
          You rejected the <b>{taskName}</b> task.
        </>
      );
    }

    if (status === 4 && YourActivityAction === "assigned_user") {
      return (
        <div>
          You received the reschedule request of <b>{taskName}</b> from{" "}
          <b> {AssignedOrRequestFrom} </b>.
        </div>
      );
    }

    if (status === 4 && YourActivityAction === "user") {
      return (
        <div>
          You sent the reschedule request to <b> {AssignedTo} </b> for the{" "}
          <b>{taskName}</b>.
        </div>
      );
    }
    if (status === 5) {
      return (
        <>
          Reschedule has been accepted <b>{taskName}</b> task under the{" "}
          {processName} process.
        </>
      );
    }
    if (status === 6) {
      return (
        <>
          Reschedule has been cancelled <b>{taskName}</b> task.
        </>
      );
    }

    return <>{"Notification: You have updates for your task."}</>;
  };

  //   const assignedToId = selectedNotification?.history?.original?.data?.find(
  //     (item) => item?.task_config_detail_responses?.status === 4
  //   );
  // console.log(selectedNotification.status)
  //   console.log(assignedToId.task_config_detail_responses.status)
  //   console.log(assignedToId.user_assigned_to.id)
  //   console.log(parsedUser?.data.id,"2mystring")

  //   console.log(assignedToId,"mysr")

  //   console.log(assignedToId === parsedUser?.data?.id, "mystring");

  //   // console.log(selectedNotification?.history?.original?.data?.at(-1)?.user_assigned_to?.id ===parsedUser?.data.id,"mystring")
  //   console.log(selectedNotification?.history?.original?.data?.at(-1)?.user_assigned_to?.id,"1mystring")
  // console.log(parsedNotifications,"myparsing")

  const handleFilterChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedStatus(selectedValue);

    // Filter notifications based on selected status
    if (selectedValue === "") {
      setFilteredNotifications(parsedNotifications); // Show all if no status selected
    } else {
      setFilteredNotifications(
        parsedNotifications.filter(
          (rc) => rc.status.toString() === selectedValue
        )
      );
    }
  };

  useEffect(() => {
    // Debugging: Check the data structure first
    // console.log(selectedNotification?.history?.original?.data, "Data Array");
    // console.log(parsedUser?.data?.id, "Parsed User ID");

    const assignedToId = selectedNotification?.history?.original?.data?.find(
      (item) => {
        // console.log(item?.task_config_detail_responses?.status, "Status");
        // console.log(item?.user_assigned_to?.id, "User Assigned To ID");
        return (
          item?.task_config_detail_responses?.status === 4 &&
          item?.user_assigned_to?.id === parsedUser?.data?.id
        );
      }
    );

    // console.log(assignedToId, "Found Object");
    setRequestGetter(assignedToId);

    setIsMatched(
      selectedNotification?.status === 4 &&
        (selectedNotification?.lastTaskIndex?.status === 4 ||
          selectedNotification?.lastTaskIndex?.status === 4) &&
        selectedNotification?.userAssignedToDetail?.id === parsedUser?.data?.id
    );
  }, [selectedNotification, parsedUser]);

  useEffect(() => {
    // console.log(selectedNotification,"sdr_doneee")
    const matchedObject = selectedNotification?.history?.original?.data?.find(
      (item) =>
        selectedNotification?.id === item?.task_config_detail_responses?.id
    );

    setActualgetterData(matchedObject);

    console.log(matchedObject, "Found Object for ID Match");
  }, [selectedNotification]);

  // console.log(ActualgetterData,'ActualgetterData')

  //custom form
  const handleCloseTask = () => {
    setShowTaskModal(false);
  };

  const handleCloseReschedule = () => {
    setRescheduleAcceptModal(false);
  };

  const handleCloseRejectReschedule = () => {
    setRescheduleRejectModal(false);
  };
  const handleShowTask = () => setShowTaskModal(true);

  return (
    <div>
      <Navbar hideData={true} parsedNotifications={parsedNotifications} />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}>
        {" "}
        <Button
          onClick={handleShowTask}
          style={{
            background:
              "linear-gradient(106.37deg, #fca61f 29.63%, #ff8c00 51.55%, #e07b39 90.85%)",
            border: "none",
          }}>
          + Custom Task
        </Button>
      </div>

      <Row>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                All <br /> Tasks{" "}
              </>
            }
            count={CountInformation?.AllTaskCount}
            icon={<FcAddDatabase />}
            background="linear-gradient(to right, rgb(250 202 207), rgb(255 204 188 / 18%))"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Automated <br /> Tasks{" "}
              </>
            }
            count={CountInformation?.AutomatedTaskCount}
            icon={<FcList />}
            background="linear-gradient(to right, #bbdefb, #e3f2fd)"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Custom <br /> Tasks{" "}
              </>
            }
            count={CountInformation?.CustomTaskCount}
            icon={<FcProcess />}
            background="linear-gradient(to right, rgb(247 212 253), rgb(225 190 231 / 30%))"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Sub <br />
                Tasks{" "}
              </>
            }
            count={CountInformation?.AutomatedClosedTaskCount}
            icon={<FcCheckmark />}
            background="linear-gradient(to right, rgb(178, 251, 194), rgb(178 251 194 / 29%))"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Closed <br />
                Tasks{" "}
              </>
            }
            count={CountInformation?.CustomClosedTaskCount}
            icon={<FcExpired />}
            background="linear-gradient(to right, rgb(255, 227, 171), rgb(255 244 222 / 64%))"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Resolution <br /> Efficiency{" "}
              </>
            }
            count="200"
            icon={<FcProcess />}
            background="linear-gradient(to right, rgb(247 212 253), rgb(225 190 231 / 30%))"
            Graphbackground=""
          />{" "}
        </Col>
      </Row>

      <Row>
        {/* Tabular Section */}
        <Col lg={6} md={6} sm={12} className="p-0 m-0">
          {/* <div className="chart-container p-2 m-2">
            {parsedNotifications ? <> {parsedNotifications?.map((item, index) => ( 
              <div key={index} 
              onClick={() => openModal(item)}
              style={{ cursor: 'pointer' }}
              className="alert alert-info"> 
             <b> A new task Alert : </b> A task {item.task.task_name} under the process of {item.process.process_name}. </div>
            )) }</> : (
              <div>No new notifications.</div>
            )}
          </div> */}
          <div className=" m-2 p-2">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                justifyContent: "space-between",
                flexWrap: "nowrap",
              }}>
              <h6>Task Updates</h6>
              <div
                className="filter_select"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <select value={selectedStatus} onChange={handleFilterChange}>
                  {/* <option value="">Select Status</option> */}
                  <option value="">All</option>
                  {Object.entries(statusLabels).map(([key, label]) => (
                    <option key={key} value={key}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {filteredNotifications ? (
              <div style={{ height: "60vh", overflowY: "auto" }}>
                {filteredNotifications
                  ?.sort((a, b) => new Date(b.id) - new Date(a.id))
                  .map((item, index) => {
                    const { text: badgeText, color: badgeColor } = getBadgeInfo(
                      item.status
                    );

                    return (
                      <div className="notification_card" key={index}>
                        <div
                          className="card"
                          onClick={() => openModal(item)}
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                            transition: "box-shadow 0.3s ease",
                            marginBottom: "15px",
                          }}>
                          {(item.search_from === "assigned_user" &&
                            item.is_read_by_assigned_user === 0) ||
                          (item.search_from === "user" &&
                            item.is_read === 0) ? (
                            <div
                              className="card-body"
                              style={{
                                padding: "20px",
                                backgroundColor: "#ffffff",
                                borderLeft:
                                  item?.task[0]?.priority === 0
                                    ? "5px solid red"
                                    : item?.task[0]?.priority === 1
                                    ? "5px solid orange"
                                    : "5px solid green",
                              }}>
                              <table>
                                <tbody>
                                  <tr>
                                    <td style={{ width: "70%" }}>
                                      <div
                                        className="card-title"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}>
                                        <Badge
                                          bg={
                                            item.task.priority === 0
                                              ? "danger" // red
                                              : item.task.priority === 1
                                              ? "warning" // orange
                                              : "success" // green
                                          }
                                          style={{
                                            marginRight: "10px",
                                            fontSize: "12px",
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                          }}>
                                          {item.task.priority === 0 &&
                                            "Time-Sensitive"}
                                          {item.task.priority === 1 &&
                                            "Moderate"}
                                          {item.task.priority === 2 &&
                                            "Can Wait"}
                                        </Badge>
                                        {item?.task[0]?.task_type === "task" ? (
                                          <span
                                            style={{
                                              marginRight: "8px",
                                              fontSize: "20px",
                                            }}>
                                            📙 {/* Orange Book for Task */}
                                          </span>
                                        ) : (
                                          <span
                                            style={{
                                              marginRight: "8px",
                                              fontSize: "20px",
                                            }}>
                                            📚 {/* Books for Subtask */}
                                          </span>
                                        )}{" "}
                                        <span style={{ display: "inline" }}>
                                          {getNotificationMessage(item)}
                                        </span>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        width: "30%",
                                        textAlign: "right",
                                      }}>
                                      {item.is_custom_Process === 0 ? (
                                        <div
                                          title="Automated Task"
                                          style={{
                                            cursor: "pointer",
                                          }}>
                                          🦾
                                        </div>
                                      ) : (
                                        <div
                                          title="Manual Task"
                                          style={{
                                            cursor: "pointer",
                                          }}>
                                          💪
                                        </div>
                                      )}
                                      <Badge
                                        bg={badgeColor}
                                        style={{
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => openModal(item)}>
                                        {badgeText}
                                      </Badge>
                                      <div
                                        style={{
                                          textAlign: "right",
                                          fontSize: "smaller",
                                          marginTop: "5px",
                                          color: "#777",
                                        }}>
                                        <i>
                                          {item.status === 0
                                            ? `Created ${formatDistanceToNow(
                                                parseISO(item.created_at),
                                                { addSuffix: true }
                                              )}`
                                            : `Updated ${formatDistanceToNow(
                                                parseISO(item.updated_at),
                                                { addSuffix: true }
                                              )}`}
                                        </i>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div
                              className="card-body"
                              style={{
                                padding: "20px",
                                backgroundColor: "#e5eaee",
                                borderLeft:
                                  item?.task[0]?.priority === 0
                                    ? "5px solid red"
                                    : item?.task[0]?.priority === 1
                                    ? "5px solid orange"
                                    : "5px solid green",
                              }}>
                              <table>
                                <tbody>
                                  <tr>
                                    <td style={{ width: "70%" }}>
                                      <div
                                        className="card-title"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}>
                                        {/* Priority Badge */}
                                        <Badge
                                          bg={
                                            item.task.priority === 0
                                              ? "danger" // red
                                              : item.task.priority === 1
                                              ? "warning" // orange
                                              : "success" // green
                                          }
                                          style={{
                                            marginRight: "10px",
                                            fontSize: "12px",
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                          }}>
                                          {item.task.priority === 0 &&
                                            "Time-Sensitive"}
                                          {item.task.priority === 1 &&
                                            "Moderate"}
                                          {item.task.priority === 2 &&
                                            "Can Wait"}
                                        </Badge>

                                        {item?.task[0]?.task_type === "task" ? (
                                          <span
                                            style={{
                                              marginRight: "8px",
                                              fontSize: "20px",
                                            }}>
                                            📙 {/* Orange Book for Task */}
                                          </span>
                                        ) : (
                                          <span
                                            style={{
                                              marginRight: "8px",
                                              fontSize: "20px",
                                            }}>
                                            📚 {/* Books for Subtask */}
                                          </span>
                                        )}
                                        <span style={{ display: "inline" }}>
                                          {getNotificationMessage(item)}
                                        </span>
                                      </div>
                                    </td>
                                    <td style={{ width: "30%" }}>
                                      {item.is_custom_Process === 0 ? (
                                        <div
                                          title="Automated Task"
                                          style={{
                                            cursor: "pointer",
                                          }}>
                                          🦾
                                        </div>
                                      ) : (
                                        <div
                                          title="Manual Task"
                                          style={{
                                            cursor: "pointer",
                                          }}>
                                          💪
                                        </div>
                                      )}
                                      <Badge
                                        bg={badgeColor}
                                        style={{
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => openModal(item)}>
                                        {badgeText}
                                      </Badge>
                                      <div
                                        style={{
                                          textAlign: "right",
                                          fontSize: "smaller",
                                          marginTop: "5px",
                                          color: "#777",
                                        }}>
                                        <i>
                                          {item.status === 0
                                            ? `Created ${formatDistanceToNow(
                                                parseISO(item.created_at),
                                                { addSuffix: true }
                                              )}`
                                            : `Updated ${formatDistanceToNow(
                                                parseISO(item.updated_at),
                                                { addSuffix: true }
                                              )}`}
                                        </i>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div>No new notifications.</div>
            )}
          </div>
        </Col>

        {/* Charts Section */}
        <Col lg={6} md={6} sm={12} className="p-0 m-0">
          <Charts chartData={chartData} />
        </Col>
      </Row>

      {/* Modal to view the notification details */}
      {modalVisible && selectedNotification && (
        <div className="modal-overlay " style={{ background: "ffc107" }}>
          <div
            className="modal-content"
            style={{
              width: "95vw", // Increased width
              height: "90vh", // Increased height
              maxWidth: "1275px", // Optional: Limit max width
              maxHeight: "90vh", // Optional: Limit max height
              overflowY: "auto",
              padding: "0px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              height: "100vh",
              overflowY: "auto",
            }}>
            <h4
              className="text-center"
              style={{
                marginBottom: "3px",
                color: "#fff",
                background: "linear-gradient(45deg, #ff9f00, #ff5e00)",
                padding: "10px 20px",
                borderRadius: "8px 8px 0 0",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                textTransform: "uppercase",
                fontWeight: "bold",
                textShadow:
                  "rgba(0, 0, 0, 0.6) 4px 4px 8px, rgba(255, 140, 0, 0.7) 2px 2px 4px",
              }}>
              Task Detail
            </h4>
            {!propertyDetail && (
              <div>
                {selectedNotification && (
                  <div
                    style={{
                      background: "#fff",
                      padding: "20px",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div
                      style={{
                        width: isPanelOpen ? "50vw" : "90vw",
                        padding: "0 20px 0 0px",
                      }}>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Task Name</p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {" "}
                                {
                                  selectedNotification?.history?.original
                                    ?.data[0]?.task?.task_name
                                }{" "}
                                -{" "}
                                {selectedNotification?.history?.original
                                  ?.data[0]?.property_details && (
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                      color: "#e88a40",
                                    }}
                                    onClick={SliderChange}>
                                    {" "}
                                    {
                                      selectedNotification?.property_details
                                        ?.property_name
                                    }{" "}
                                  </span>
                                )}{" "}
                              </b>{" "}
                            </Col>
                          </Row>
                        </Col>

                        {/* <Col lg={6}>
                      <Row>
                        <Col lg={4} md={4} sm={4} xs={4}>
                          {" "}
                          <p> Department </p>{" "}
                        </Col>
                        <Col lg={1} md={1} sm={1} xs={1}>
                          :
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6}>
                          {" "}
                          <b className="text-capitalize" >
                            {" "}
                            {
                              selectedNotification?.department?.department_name
                            }{" "}
                          </b>{" "}
                        </Col>
                      </Row>
                    </Col> */}

                        {/* <Col lg={6}>
                      <Row>
                        <Col lg={4} md={4} sm={4} xs={4}>
                          {" "}
                          <p> Department Role </p>{" "}
                        </Col>
                        <Col lg={1} md={1} sm={1} xs={1}>
                          :
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6}>
                          {" "}
                          <b className="text-capitalize" >
                            {" "}
                            {
                              selectedNotification?.department_role
                                ?.dep_role_name
                            }{" "}
                          </b>{" "}
                        </Col>
                      </Row>
                    </Col> */}

                        {/* <Col lg={6}  md={12}>
                      <Row>
                        <Col lg={4} md={4} sm={4} xs={4}>
                          {" "}
                          <p> Resolution SLA </p>{" "}
                        </Col>
                        <Col lg={1} md={1} sm={1} xs={1}>
                          :
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6}>
                          {" "}
                          <b className="text-capitalize" >
                            {" "}
                            {selectedNotification?.task?.is_resolution_hour ===
                            1
                              ? "Hour"
                              : "Day"}{" "}
                          </b>{" "}
                        </Col>
                      </Row>
                    </Col> */}

                        <Col lg={6} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p>
                                {/* {" "}
                            {selectedNotification?.task?.is_resolution_hour ===
                            1
                              ? "No. of Hour"
                              : "No. of Day"}{" "} */}
                                SLA
                              </p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {selectedNotification?.history?.original
                                  ?.data[0]?.task?.is_resolution_hour === 1 ? (
                                  convertTimeString(
                                    selectedNotification?.history?.original
                                      ?.data[0]?.task?.sla_resolution_time
                                  )
                                ) : (
                                  <span>
                                    {" "}
                                    {
                                      selectedNotification?.history?.original
                                        ?.data[0]?.task?.sla_resolution_time
                                    }{" "}
                                    {selectedNotification?.history?.original
                                      ?.data[0]?.task?.sla_resolution_time < 2
                                      ? "Day"
                                      : "Days"}
                                  </span>
                                )}{" "}
                              </b>{" "}
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p>Task Raised By</p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {
                                  selectedNotification?.history?.original
                                    ?.data[0]?.user_assigned_by?.name
                                }{" "}
                                -{" "}
                                {
                                  selectedNotification?.history?.original
                                    ?.data[0]?.user_assigned_by?.department_name
                                }
                              </b>{" "}
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p>Task Raised To</p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {
                                  selectedNotification?.history?.original
                                    ?.data[0]?.user_assigned_to?.name
                                }{" "}
                                -{" "}
                                {
                                  selectedNotification?.history?.original
                                    ?.data[0]?.user_assigned_to?.department_name
                                }
                              </b>{" "}
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p>Task Start Date</p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {formattedDate(
                                  selectedNotification?.history?.original
                                    ?.data[0]?.task?.created_at
                                )}
                              </b>{" "}
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p>Task End Date</p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {calculateEndTime(
                                  formattedDate(
                                    selectedNotification?.history?.original
                                      ?.data[0]?.task?.created_at
                                  ),
                                  selectedNotification?.history?.original
                                    ?.data[0]?.task?.sla_resolution_time,
                                  selectedNotification?.task?.is_resolution_hour
                                )}
                              </b>{" "}
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Status </p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b
                                style={{
                                  color: "green",
                                  textTransform: "capitalize",
                                }}>
                                {selectedNotification?.lastTaskIndex?.status ===
                                  0 &&
                                  selectedNotification?.lastTaskIndex
                                    ?.subtask_id === null &&
                                  "open"}
                                {selectedNotification?.lastTaskIndex?.status ===
                                  0 &&
                                  selectedNotification?.lastTaskIndex
                                    ?.subtask_id !== null &&
                                  "Sub task open"}
                                {selectedNotification?.lastTaskIndex?.status ===
                                  1 &&
                                  selectedNotification?.lastTaskIndex
                                    ?.subtask_id === null &&
                                  "completed"}

                                {selectedNotification?.lastTaskIndex?.status ===
                                  2 && "Dependency"}
                                {selectedNotification?.lastTaskIndex?.status ===
                                  3 && <b style={{ color: "red" }}>Rejected</b>}

                                {selectedNotification?.lastTaskIndex?.status ===
                                  4 && (
                                  <b style={{ color: "red" }}>
                                    Reschedule Requested
                                  </b>
                                )}
                                {selectedNotification?.lastTaskIndex?.status ===
                                  5 && "Reschedule Accepted"}
                                {selectedNotification?.lastTaskIndex?.status ===
                                  6 && "Reschedule Cancelled"}

                                {selectedNotification?.lastTaskIndex?.status ===
                                  1 &&
                                  selectedNotification?.lastTaskIndex
                                    ?.subtask_id !== null &&
                                  "Sub task completed"}
                              </b>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Task Description </p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {
                                  selectedNotification?.history?.original
                                    ?.data[0]?.task?.task_description
                                }{" "}
                              </b>{" "}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <hr />

                      {(selectedNotification?.lastTaskIndex?.status == 4 ||
                        selectedNotification?.lastTaskIndex?.status == 5 ||
                        selectedNotification?.status == 6) && (
                          <div>
                            <h5
                        className="text-center inner_heading"
                         >
                        Reschedule Detail
                      </h5>     
                        <Row
                              style={{
                                backgroundColor: "rgb(229, 234, 238)",
    
                                borderRadius: "0 0 8px 8px ",
                                padding: "7px",
                                margin: "inherit",
                              }}
                          >
                          <Col lg={6} md={12}>
                            <Row>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                {" "}
                                <p>Reschedule Request For</p>{" "}
                              </Col>
                              <Col lg={1} md={1} sm={1} xs={1}>
                                :
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                {" "}
                                <b className="text-capitalize">
                                  {selectedNotification?.is_resolution_request_hour ===
                                  1 ? (
                                    convertTimeString(
                                      selectedNotification?.sla_resolution_request_time
                                    )
                                  ) : (
                                    <span>
                                      {" "}
                                      {
                                        selectedNotification?.sla_resolution_request_time
                                      }{" "}
                                      {selectedNotification?.sla_resolution_request_time <
                                      2
                                        ? "Day"
                                        : "Days"}
                                    </span>
                                  )}
                                </b>{" "}
                              </Col>
                            </Row>
                          </Col>

                          <Col lg={6} md={12}>
                            <Row>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                {" "}
                                <p>Reschedule Request End's</p>{" "}
                              </Col>
                              <Col lg={1} md={1} sm={1} xs={1}>
                                :
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                {" "}
                                <b className="text-capitalize">
                                  {calculateEndTime(
                                    formattedDate(
                                      selectedNotification?.task?.created_at
                                    ),
                                    selectedNotification?.sla_resolution_request_time,
                                    selectedNotification?.is_resolution_request_hour
                                  )}
                                </b>{" "}
                              </Col>
                            </Row>
                          </Col>

                          <Col lg={6} md={12}>
                            <Row>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                {" "}
                                <p>Reschedule Request By</p>{" "}
                              </Col>
                              <Col lg={1} md={1} sm={1} xs={1}>
                                :
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                {" "}
                                <b className="text-capitalize">
                                  {
                                    selectedNotification?.userAssignedByDetail
                                      ?.name
                                  }
                                  -{" "}
                                  {
                                    selectedNotification?.userAssignedByDetail
                                      ?.department_name
                                  }
                                </b>{" "}
                              </Col>
                            </Row>
                          </Col>

                          <Col lg={6} md={12}>
                            <Row>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                {" "}
                                <p>Reschedule Request To</p>{" "}
                              </Col>
                              <Col lg={1} md={1} sm={1} xs={1}>
                                :
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                {" "}
                                <b className="text-capitalize">
                                  {
                                    selectedNotification?.userAssignedToDetail
                                      ?.name
                                  }
                                  -{" "}
                                  {
                                    selectedNotification?.userAssignedToDetail
                                      ?.department_name
                                  }
                                </b>{" "}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        </div>
                      )}
                     
                     {(selectedNotification?.lastTaskIndex?.status == 2 ||
                        selectedNotification?.status == 2) && (
                      <h5
                        className="text-center inner_heading"
                         >
                        Sub Task Detail
                      </h5>  )}
                      {(selectedNotification?.lastTaskIndex?.status == 2 ||
                        selectedNotification?.status == 2) && (
                        <Row
                          style={{
                            backgroundColor: "rgb(229, 234, 238)",

                            borderRadius: "0 0 8px 8px ",
                            padding: "7px",
                            margin: "inherit",
                          }}>
                          <Col lg={6} md={12}>
                            <Row>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                {" "}
                                <p>Sub Task Name</p>{" "}
                              </Col>
                              <Col lg={1} md={1} sm={1} xs={1}>
                                :
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                {" "}
                                <b className="text-capitalize">
                                  {mySubTaskDetail?.subtask?.task_name}
                                </b>{" "}
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} md={12}>
                            <Row>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                {" "}
                                <p>Sub Task SLA</p>{" "}
                              </Col>
                              <Col lg={1} md={1} sm={1} xs={1}>
                                :
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                {" "}
                                <b className="text-capitalize">
                                  {/* {
                                    mySubTaskDetail?.subtask
                                      ?.sla_resolution_time
                                  } */}
                                  {mySubTaskDetail?.subtask
                                    .is_resolution_hour === 1 ? (
                                    convertTimeString(
                                      mySubTaskDetail?.subtask
                                        ?.sla_resolution_time
                                    )
                                  ) : (
                                    <span>
                                      {" "}
                                      {
                                        mySubTaskDetail?.subtask
                                          ?.sla_resolution_time
                                      }{" "}
                                      {mySubTaskDetail?.subtask
                                        ?.sla_resolution_time < 2
                                        ? "Day"
                                        : "Days"}
                                    </span>
                                  )}{" "}
                                </b>{" "}
                              </Col>
                            </Row>
                          </Col>

                          <Col lg={6} md={12}>
                            <Row>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                {" "}
                                <p>Sub Task Raised By</p>{" "}
                              </Col>
                              <Col lg={1} md={1} sm={1} xs={1}>
                                :
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                {" "}
                                <b className="text-capitalize">
                                  {mySubTaskDetail?.user_assigned_by?.name} -{" "}
                                  {
                                    mySubTaskDetail?.user_assigned_by
                                      ?.department_name
                                  }
                                </b>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} md={12}>
                            <Row>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                {" "}
                                <p>Sub Task Raised To</p>{" "}
                              </Col>
                              <Col lg={1} md={1} sm={1} xs={1}>
                                :
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                {" "}
                                <b className="text-capitalize">
                                  {mySubTaskDetail?.user_assigned_to?.name}-{" "}
                                  {
                                    mySubTaskDetail?.user_assigned_to
                                      ?.department_name
                                  }
                                </b>{" "}
                              </Col>
                            </Row>
                          </Col>

                          <Col lg={6} md={12}>
                            <Row>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                {" "}
                                <p>Sub Task Start Date</p>{" "}
                              </Col>
                              <Col lg={1} md={1} sm={1} xs={1}>
                                :
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                {" "}
                                <b className="text-capitalize">
                                  {formattedDate(
                                    mySubTaskDetail?.subtask?.created_at
                                  )}
                                </b>{" "}
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} md={12}>
                            <Row>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                {" "}
                                <p>Sub Task End Date</p>{" "}
                              </Col>
                              <Col lg={1} md={1} sm={1} xs={1}>
                                :
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                {" "}
                                <b className="text-capitalize">
                                  {calculateEndTime(
                                    formattedDate(
                                      mySubTaskDetail?.subtask?.created_at
                                    ),
                                    mySubTaskDetail?.subtask
                                      ?.sla_resolution_time,
                                    selectedNotification?.subtask
                                      ?.is_resolution_hour
                                  )}
                                </b>{" "}
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} md={12}>
                            {/* <Row>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                {" "}
                                <p>Status</p>{" "}
                              </Col>
                              <Col lg={1} md={1} sm={1} xs={1}>
                                :
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                {" "}
                                <b className="text-capitalize">
                                  {mySubTaskDetail}
                                </b>{" "}
                              </Col>
                            </Row> */}
                          </Col>
                          <Col lg={6} md={12}>
                            <Row>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                {" "}
                                <p>Sub Task Description</p>{" "}
                              </Col>
                              <Col lg={1} md={1} sm={1} xs={1}>
                                :
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                {" "}
                                <b className="text-capitalize">
                                  {mySubTaskDetail?.subtask?.task_description}
                                </b>{" "}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                      {!isMatched && (
                        <Row style={{ marginTop: "20px" }}>
                          {selectedNotification?.task
                            ?.is_doc_upload_required === "1" && (
                            <Col lg={4} md={4} sm={12}>
                              <Form.Group>
                                <Form.Label
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: "10px",
                                  }}>
                                  Upload Document
                                </Form.Label>
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "0.85em" }}>
                                  *
                                </span>
                                <div className="upload-doc-container">
                                  <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className="form-control"
                                  />
                                  {selectedNotification.status === 0 ? (
                                    <div style={{ marginTop: "10px" }}>
                                      <strong>Selected File:</strong>
                                      {imagePreview && (
                                        <img
                                          src={imagePreview}
                                          alt=""
                                          style={{
                                            width: "100px",
                                            height: "50px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <div style={{ marginTop: "10px" }}>
                                      {selectedNotification?.uploaded_document !==
                                        null && (
                                        <img
                                          src={
                                            process.env.REACT_APP_IMAGE_URL +
                                            selectedNotification.uploaded_document
                                          }
                                          alt=""
                                          style={{
                                            width: "100px",
                                            height: "50px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Form.Group>
                            </Col>
                          )}

                          <Col lg={4} md={4} sm={12}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontWeight: "bold",
                                  marginTop: "10px",
                                }}>
                                Task Status
                              </Form.Label>
                              <span
                                className="text-danger"
                                style={{ fontSize: "0.85em" }}>
                                *
                              </span>
                              <Form.Select
                                style={{ height: "36.5px" }}
                                value={taskStatus}
                                onChange={handleStatusChange}
                                className="form-control">
                                {selectedNotification?.status == 4 && (
                                  <option value={0} disabled>
                                    Open
                                  </option>
                                )}
                                <option>Choose Status</option>
                                <option value={1}>Completed</option>
                                <option value={2}>Dependency</option>
                                <option value={4}>Reschedule Request</option>

                                <option value={3}>Reject</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>

                          <Col md={6} sm={12}>
                            {taskStatus === "4" && (
                              <Row>
                                <Col md={6} sm={6} style={{ padding: "10px" }}>
                                  <Form.Group controlId="is_resolution_hour">
                                    <Form.Label>Resolution Type</Form.Label>
                                    <Form.Select
                                      style={{ height: "36.5px" }}
                                      value={isSLAResolutionHour}
                                      onChange={handleResolutionChange}
                                      aria-label="Select an option">
                                      <option value="">Choose...</option>
                                      <option value={0}>Day</option>
                                      <option value={1}>Hour</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>

                                {(isSLAResolutionHour === 0 ||
                                  isSLAResolutionHour === "0") && (
                                  <Col
                                    md={6}
                                    sm={6}
                                    style={{ padding: "10px" }}>
                                    <Form.Group controlId="sla_resolution_time">
                                      <Form.Label>Resolution Days</Form.Label>
                                      <Form.Control
                                        type="number"
                                        name="sla_resolution_time"
                                        value={editSLA}
                                        onChange={(e) =>
                                          seteditSLA(e.target.value)
                                        }
                                        min="1"
                                      />
                                    </Form.Group>
                                  </Col>
                                )}

                                {(isSLAResolutionHour === 1 ||
                                  isSLAResolutionHour === "1") && (
                                  <>
                                    <Col
                                      md={3}
                                      sm={6}
                                      style={{ padding: "10px" }}>
                                      <Form.Group controlId="hours">
                                        <Form.Label> Hours</Form.Label>

                                        <Form.Control
                                          name="hours"
                                          value={Thours}
                                          onChange={(e) => {
                                            setHours(e.target.value);
                                          }}
                                          min="1"
                                        />
                                      </Form.Group>
                                    </Col>

                                    <Col md={3} style={{ padding: "10px" }}>
                                      <Form.Group controlId="minutes">
                                        <Form.Label> Minutes</Form.Label>

                                        <Form.Select
                                          style={{ height: "36.5px" }}
                                          name="minutes"
                                          value={Tminutes}
                                          onChange={(e) =>
                                            setMinutes(e.target.value)
                                          }
                                          aria-label="Select an option">
                                          <option value="">Choose...</option>
                                          <option value="00">00</option>
                                          <option value="15">15</option>
                                          <option value="30">30</option>
                                          <option value="45">45</option>
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                  </>
                                )}
                              </Row>
                            )}
                          </Col>

                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            style={{ padding: "10px" }}>
                            <Form.Group>
                              <Form.Label style={{ fontWeight: "bold" }}>
                                Task Remarks
                              </Form.Label>
                              <span
                                className="text-danger"
                                style={{ fontSize: "0.85em" }}>
                                *
                              </span>
                              <Form.Control
                                as="textarea"
                                value={taskRemarks}
                                className="form-control custom-input"
                                onChange={(e) => setTaskRemarks(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      {isMatched && (
                        <div className="mysystematic_data">
                          <Row>
                            <Col lg={12}>
                              {/* Card Structure */}
                              <div className="card-container">
                                <div className="alert-container">⚠️</div>
                                <h5 style={{ textAlign: "center" }}>
                                  <b
                                    className="text-capitalize"
                                    style={{
                                      fontSize: "40px",
                                      fontWeight: "900",
                                      color: "rgb(255 203 0)",
                                      textShadow:
                                        "4px 4px 8px rgba(0, 0, 0, 0.6), " +
                                        "2px 2px 4px rgba(0, 0, 0)",
                                      padding: "20px 30px",
                                      borderRadius: "10px",
                                      transform: "skew(-10deg)",
                                      display: "inline-block",
                                      letterSpacing: "0px",
                                    }}>
                                    HEADS UP!
                                  </b>
                                  <b
                                    style={{
                                      fontSize: "22PX",
                                      fontWeight: "bold",
                                      color: "white",
                                      textShadow:
                                        "4px 4px 8px rgba(0, 0, 0, 0.6), " +
                                        "2px 2px 4px rgba(255, 140, 0, 0.7)",
                                      padding: "20px 30px",
                                      borderRadius: "10px",
                                      transform: "skew(-10deg)",
                                      display: "inline-block",
                                    }}>
                                    A reschedule request is pending.
                                  </b>
                                </h5>

                                {/* Accept and Reject Icons */}
                                <div className="action-icons">
                                  <FaCheck
                                    className="action-icon accept-icon mx-2"
                                    onClick={() =>
                                      HandleModalofReschedule(
                                        selectedNotification
                                      )
                                    }
                                  />
                                  <FaTimes
                                    className="action-icon reject-icon"
                                    onClick={() =>
                                      HandleModalofRescheduleReject(
                                        selectedNotification
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          {selectedNotification?.lastTaskIndex?.status == 4 &&
                            RescheduleRejectModal && (
                              <Row className="text-center">
                                <Col lg={12}>
                                  <div className="box">
                                    <h5>
                                      <b className="text-capitalize">
                                        Are you sure you want to reject this
                                        reschedule?
                                      </b>
                                    </h5>
                                    <Form.Control
                                      type="text"
                                      placeholder="Reason (optional)"
                                      value={taskRemarks}
                                      className="form-control custom-input mt-4"
                                      onChange={(e) =>
                                        setTaskRemarks(e.target.value)
                                      }
                                    />
                                    <br />
                                    <Button
                                      className="cancel-btn me-2 mt-3"
                                      onClick={() => RejectRescheduleHandler()}>
                                      Cancel the Request
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            )}

                          {selectedNotification?.lastTaskIndex?.status == 4 &&
                            RescheduleAcceptModal && (
                              <Row className="text-center">
                                <Col lg={12}>
                                  <div className="box">
                                    <h5>
                                      <b>
                                        Are you sure you want to reject this
                                        reschedule?
                                      </b>
                                    </h5>
                                    <Button
                                      className="cancel-btn me-2 mt-3"
                                      onClick={() =>
                                        AcceptRescheduleHandler(
                                          selectedNotification
                                        )
                                      }>
                                      Accept
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            )}
                        </div>
                      )}

                      <TableHistory
                        selectedNotification={selectedNotification}
                        SubTaskDetailHandler={SubTaskDetailHandler}
                      />

                      <Row>
                        <Col lg={3}></Col>
                        <Col lg={6}>
                          {commonerr && (
                            <span className="text-danger"> {commonerr} </span>
                          )}
                        </Col>
                        <Col lg={3}></Col>
                      </Row>
                      <Row style={{ marginTop: "20px" }}>
                        <Col lg={6} md={6} sm={12}></Col>
                        <Col
                          lg={6}
                          md={6}
                          sm={12}
                          className="d-flex justify-content-end">
                          <Button
                            className="cancel-btn me-2"
                            variant="secondary"
                            onClick={closeModal}>
                            Close
                          </Button>
                          {isStatusChanged && (
                            <Button
                              disabled={taskStatus == 2}
                              className="update-btn"
                              variant="success"
                              onClick={handleStatusUpdate}>
                              {isSubmitting ? "Updating..." : "Update"}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div
                      style={{
                        flex: isPanelOpen ? 1 : 0,
                        background: "#fff",
                        transition: "flex 0.3s ease",
                        overflow: "hidden",
                        borderLeft: isPanelOpen ? "1px solid #ccc" : "none",
                      }}>
                      {isPanelOpen && !subtaskDetailSlider && (
                        <div>
                          <AddCustomTask
                            isthisSubtask={true}
                            myParentTaskId={myParentTaskId}
                            selectedNotification={selectedNotification}
                          />
                        </div>
                      )}
                      {isPanelOpen && subtaskDetailSlider && (
                        <div style={{ padding: "20px" }}>
                          <SubTaskDetail
                            SliderChange={SliderChange}
                            SubTaskModalCloseHandler={SubTaskModalCloseHandler}
                            moreInfo={moreInfo}
                          />
                          {/* <AddCustomTask isthisSubtask={true} myParentTaskId={myParentTaskId}   /> */}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {propertyDetail && (
              <div>
                {selectedNotification && (
                  <div
                    style={{
                      background: "#fff",
                      padding: "20px",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div
                      style={{
                        width: isPanelOpen ? "50vw" : "90vw",
                        padding: "0 20px 0 0px",
                      }}>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Provider Name</p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {" "}
                                {
                                  selectedNotification?.property_details
                                    ?.providers[0]?.name
                                }{" "}
                                 
                                
                              </b>{" "}
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Property Name</p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {" "}
                                {
                                  selectedNotification?.property_details
                                    ?.property_name
                                }{" "}
                                -{" "}
                                {selectedNotification?.property_details && (
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                      color: "#e88a40",
                                    }}
                                    onClick={SliderChange}>
                                    {" "}
                                    {selectedNotification?.task?.task_name}{" "}
                                  </span>
                                )}{" "}
                              </b>{" "}
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Property Address</p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {" "}
                                {
                                  selectedNotification?.property_details
                                    ?.address1
                                }{" "}
                                ,{" "}
                                {
                                  selectedNotification?.property_details
                                    ?.address2
                                }{" "}
                                ,{" "}
                                {
                                  selectedNotification?.property_details
                                    ?.zipcode
                                }{" "}
                              </b>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Total Sq.Ft </p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {" "}
                                {
                                  selectedNotification?.property_details
                                    ?.total_sq_ft
                                }{" "} Sq.ft
                              </b>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Description </p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {" "}
                                {
                                  selectedNotification?.property_details
                                    ?.long_desc
                                }{" "}
                                <br />{" "}
                                {
                                  selectedNotification?.property_details
                                    ?.short_desc
                                }{" "}
                              </b>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Pseudo Name </p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {" "}
                                {
                                  selectedNotification?.property_details
                                    ?.pseudoname
                                }{" "}
                              </b>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Email </p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {" "}
                                {
                                  selectedNotification?.property_details?.email
                                }{" "}
                              </b>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Contact Number</p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {" "}
                                {
                                  selectedNotification?.property_details
                                    ?.mobile_no
                                }{" "}
                                ,{" "}
                                {
                                  selectedNotification?.property_details
                                    ?.landline
                                }
                              </b>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Furnished Status </p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {" "}
                                {
                                  selectedNotification?.property_details
                                    ?.furnished_status
                                }{" "}
                              </b>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Contact Person </p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                {" "}
                                {
                                  selectedNotification?.property_details
                                    ?.contact_person
                                }{" "}
                              </b>
                            </Col>
                          </Row>
                        </Col>

                      </Row>


                      <Row 
                       style={{
                        backgroundColor: "rgb(229, 234, 238)",

                        borderRadius: "0 0 8px 8px ",
                        padding: "7px",
                        margin: "inherit",
                      }}
                      >
                        <Col lg={6} md={12} sm={12} >
                      <h5 className="inner_heading text-center" >Property Timings and Amenities</h5>

                     
                      <Col lg={12} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Timings </p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                

                                  <ul  style={{ listStyle:'none',paddingLeft:0}} >
                                    {selectedNotification?.property_details?.timings.map((timing) => (
                                      <li key={timing.id}>
                                        {timing.day_id.toUpperCase()} - {timing.is_open ? "Open" : "Closed"}
                                      </li>
                                    ))}
                                  </ul>
                              </b>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                          <Row>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              {" "}
                              <p> Amenities </p>{" "}
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                              :
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                              {" "}
                              <b className="text-capitalize">
                                

                                  <ul  style={{ listStyle:'none',paddingLeft:0}} >
                                    {selectedNotification?.property_details?.amenities.map((timing) => (
                                      <li key={timing.id}>
                                        {timing.amenity_name}
                                      </li>
                                    ))}
                                  </ul>
                              </b>
                            </Col>
                          </Row>
                        </Col>

                        </Col>

                        <Col lg={6} md={12} sm={12}>
                        <h5 className="inner_heading text-center" >Property Images</h5>

<Row  style={{
  backgroundColor: "rgb(229, 234, 238)",

  borderRadius: "0 0 8px 8px ",
  padding: "7px",
  margin: "inherit",
}} >
<Col lg={12} md={12} sm={12}>
          
          <div className="text-center">
          {selectedNotification?.property_details?.images.map((img,i) => (
            <img key={i} 
            src={
              process.env.REACT_APP_IMAGE_URL +
              img.image_path
            } 
            style={{ width:'200px',heigh:'auto' }}
             alt="" />
            
              // {timing.day_id.toUpperCase()} - {timing.is_open ? "Open" : "Closed"}
          
          ))}
           </div>
        
  </Col>
</Row>
                        </Col>

                       
                      </Row>


                    

                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <Modal show={showTaskModal} onHide={handleCloseTask} centered size="lg">
        <Modal.Body>
          <AddCustomTask FlowTask={FlowTask} handleClose={handleCloseTask} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Dashboard;
