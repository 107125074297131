import React, { useState, useEffect } from "react";
import ProcessForm from "./ProcessForm";
import URL from "../../components/API/Api";
import Swal from "sweetalert2";

const EditProcess = ({ processNumberFlow, processId, handleClose }) => {
  const [formData, setFormData] = useState({
    processName: "",
    orgName: "",
    department: [],
    processFlowOrder: "",
  });

  // Fetch process data based on processId
  const fetchProcessData = async () => {
    try {
      const response = await URL.get(`/api/process/${processId}`);
      const processData = response.data.data;

      // Set the form data with the fetched process data
      setFormData({
        processName: processData.process_name,
        orgName: processData.org_id,
        department: processData.dept_id || [], // Ensure department is an array
        processFlowOrder: processData.process_flow_order,
      });
    } catch (error) {
      console.error("Error fetching process data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unable to fetch process data.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    fetchProcessData();
  }, [processId]); // Fetch data when processId changes

  // Submit updated process data
  const handleSubmit = async (e) => {
    e.preventDefault();

    const processData = {
      process_name: formData.processName,
      org_id: formData.orgName,
      process_flow_order: formData.processFlowOrder,
      status: 1, // Set status according to your requirements
      dept_id: formData.department,
    };

    try {
      const response = await URL.put(`/api/process/${processId}`, processData);
      console.log("Process Data Successfully Updated:", response.data);

      // Show success toaster
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message || "Process Data Successfully Updated",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "rgb(255, 165, 0)",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });

      // Optionally call handleClose to hide the modal after successful submission
      handleClose();
    } catch (error) {
      console.error("Error updating process data:", error);
      // Show error toaster
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error updating process data. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div>
      <ProcessForm
        processNumberFlow={processNumberFlow}
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
        buttonName="Update Process"
        handleClose={handleClose}
      />
    </div>
  );
};

export default EditProcess;
