import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Pagination,
  Table,
  Form, 
} from "react-bootstrap";
import { FaPen, FaTrash } from "react-icons/fa";
import URL from "../../components/API/Api";
import Swal from "sweetalert2";
import AddTask from "./AddTask";
import EditUpdateModal from "./EditUpdateModal";
import Switch from "react-switch";
import "./../../App.css";

const Task = () => {
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [FlowTask, setFlowTask] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [DefaultGet, setDefaultGet] = useState();
  
  const [Tasks, setTasks] = useState([]);
  const [depaartments, setdepaartments] = useState([]);
  const [organizationNames, setOrganizationNames] = useState({});
  const [departmentNames, setDepartmentNames] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);


  const [processOptions, setProcessOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [formData, setFormData] = useState({
    org_id: "",
    process_id: "",
    dept_id: "",
    dept_role_id: "",
    task_name: "",
    sla_resolution_time: "",
    task_flow_order: "",
    is_doc_upload_required: false,
    task_description: "",
  });

  const [editDepID, seteditDepID] = useState([]);
  const [editOrgID, seteditOrgID] = useState("");
  const [mgorganization, setMGOrganization] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [allTask, setAllTask] = useState([]);


  // Fetch Tasks from API
  const fetchTasks = async (page = 1) => {
    try {
      const response = await URL.get(`api/task`, {
        params: { limit: limit, page: page },
      });
      const responseData = response.data;
      setAllTask(response.data);
      // console.log(responseData.data,"jkl")
      setCurrentPage(responseData.data.current_page);
      setTotalPages(responseData.data.last_page);
      setTasks(responseData?.data?.data); // Use empty array as fallback
      // console.log(responseData.data.data)

      // Fetch organization names for all unique org_ids in the Tasks
      const orgIds = [...new Set(responseData.data.map((p) => p.org_id))];
      await fetchOrganizationNames(orgIds);

      // Fetch department names for all unique dept_ids in the Tasks
      const deptIds = [...new Set(responseData.data.flatMap((p) => p.dept_id))];
      await fetchDepartmentNames(deptIds);
    } catch (error) {
      // console.error("Error fetching Tasks:", error);
      // setTasks([]); // Set to empty array in case of error
    }
  };

  // Fetch organization names based on org_ids
  const fetchOrganizationNames = async (orgIds) => {
    const orgNameMapping = {};
    try {
      const fetchPromises = orgIds.map((id) => URL.get(`api/org/${id}`));
      const orgResponses = await Promise.all(fetchPromises);

      orgResponses.forEach((response) => {
        const orgData = response.data?.data;
        if (orgData) {
          orgNameMapping[orgData.id] = orgData.org_name;
        }
      });

      setOrganizationNames(orgNameMapping);
    } catch (error) {
      console.error("Error fetching organization names:", error);
    }
  };

  
  // Fetch department names based on dept_ids
  const fetchDepartmentNames = async (deptIds) => {
    const deptNameMapping = {};
    try {
      const fetchPromises = deptIds.map((id) =>
        URL.get(`user-management/api/v1/orgdept/${id}`)
      );
      const deptResponses = await Promise.all(fetchPromises);

      deptResponses.forEach((response) => {
        const deptData = response.data?.data;
        if (deptData) {
          deptNameMapping[deptData.id] = deptData.department_name;
        }
      });

      setDepartmentNames(deptNameMapping);
    } catch (error) {
      console.error("Error fetching department names:", error);
    }
  };

  const handleToggleChange = async (checked,tsk) => {
    console.log(checked)
    // setLoading(true);
    try {
      let status = checked ? 1 : 0;
      let data = { id: tsk.id, status };

      await URL.put(`api/updateTaskStatus`, data);
      fetchTasks(currentPage);
    } catch (error) {
      console.error("Error updating task status:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an error updating the task status.",
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } finally {
      // setLoading(false);
    }
  };

  const handleDeleteTaskClick = (task) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to recover this task!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await URL.delete(`api/task/${task.id}`); // Adjust the API endpoint as needed
          Swal.fire({
            title: "Deleted!",
            text: response.data.message, // Show the success message from the API response
            icon: "success",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
          fetchTasks(fetchTasks); // Call the function to refresh the task list
        } catch (error) {
          // console.error("Error deleting task:", error);
          Swal.fire({
            title: "Error!",
            text: "There was an error deleting the task.",
            icon: "error",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  useEffect(() => {
    fetchTasks(currentPage); // Fetch Tasks when component loads or page changes
  }, [currentPage]);

  useEffect(() => {
    fetchAll();
  }, [showTaskModal]);

  const fetchAll = async () => {
    try {
      const response = await URL.get(`api/allprocess_task`);
      const responseData = response.data;
      // console.log(responseData.data, "mytkkkkk");

      setFlowTask(responseData.data); // Use empty array as fallback
      // getLastNumberFlow(FlowTask)
    } catch (error) {
      console.error("Error fetching department names:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle Task Modal
  const handleCloseTask = () => {
    setShowTaskModal(false);
    fetchTasks(currentPage);
  };

  const handleShowTask = () => setShowTaskModal(true);

  // Handle Edit Task Modal
  const handleEditTask = (task) => {
    // console.log(task, "task");
    setSelectedTask(task); 
    setDefaultGet(task);  
    setShowEditTaskModal(true);
    
  };

  const isWholeNumber = (num) => num % 1 === 0;

// Format decimal hours into HH:mm
const formatTime = (timeString) => {
  // Split the string by ":", and trim spaces
  const [hours, minutes] = timeString.split(":").map(str => str.trim());

  // Convert hours and minutes to numbers
  const hoursNum = Number(hours);
  const minutesNum = Number(minutes);

  // Check if the conversion is successful and handle invalid input
  if (isNaN(hoursNum) || isNaN(minutesNum)) {
    return "Invalid time"; // Return a fallback message if input is invalid
  }

  // Return formatted time
  return `${hoursNum} hour and ${minutesNum} minutes`;
};



  const handleEditCloseTask = () => {
    setShowEditTaskModal(false);
    setSelectedTask(null);
  };

 
  //task
  useEffect(() => {
  //  console.log("aa2")

    fetchProcessesAndDepartments(mgorganization[0]?.id);
  }, [editOrgID, editDepID]);

 
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setCurrentPage(1);
    // console.log("allTask", allTask.data.data);
    const filteredTask = allTask.data.data.filter((task) =>
      task.task_name.toLowerCase().includes(value.toLowerCase())
    );
    setTasks(filteredTask);
  };


  const fetchOrganizations =  () => {
    
    URL.get("api/allorg")
    .then((res)=>{

    const organizationData = res.data.data;
    // console.log("organizationData", organizationData);
    setOrganizations(organizationData);
    setMGOrganization(organizationData?.filter((c)=>c.org_name=="MG"));
    fetchProcessesAndDepartments(mgorganization[0]?.id)
    })
    .catch((err)=>console.log(err));
  }

  

  const fetchProcessesAndDepartments =  (d) => {
    
       URL.get(`api/org/${d}`)
       .then((res)=>{
      if (res.data && res.data.status) {
        const orgData = res.data.data;

        // console.log(orgData,"orgggdata")
        // Format processes for the dropdown
        const formattedProcessOptions = orgData.processes.map((process) => ({
          value: process.process_id,
          label: process.process_name || `Process ${process.process_id}`, // Use a fallback if process name is null
          key: `process-${process.process_id}`,
        }));
        setProcessOptions(formattedProcessOptions);

        // Format departments and their roles for the dropdown
        const formattedDepartments = orgData.departments.map((dept) => ({
          value: dept.department_id,
          label: dept.department_name,
          roles: dept.roles.map((role) => ({
            value: role.role_id,
            label: role.dep_role_name,
          })),
          key: dept.department_id,
        }));
        setDepartmentOptions(formattedDepartments);
        setdepaartments(orgData?.departments);
        
      }
    })
    .catch((err)=>console.log(err))
  
 
}


   

  useEffect(() => {
    fetchOrganizations();
  }, []);



  return (
    <div className="task-page-container ">
      {/* Task Table */}
     
      <div>
        <div className="display_space_between">
        <div className="page-header mb-0">
        <nav aria-label="breadcrumb"  className="mt-1 clip_brdcrumps">
          <div id="clip">
            <span
              style={{
                padding: "3px 20px",
                backgroundColor: "#FFA500",
                color: "white",
                display: "inline-block",
                clipPath:
                  "polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)",
              }}>
              <a
                href="/master/task"
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}>
                Masters
              </a>
            </span>
            <span
              style={{
                padding: "3px 20px",
                backgroundColor: "#FFA500",
                color: "white",
                display: "inline-block",
                clipPath:
                  "polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)",
                marginRight: "5px", // Adjusted margin for smaller gap
              }}>
              <a
                href="/master/task"
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}>
                Tasks
              </a>
            </span>
          </div>
        </nav>
      </div>
          <div className="d-flex align-items-center">
            <Form className="me-3">
              <Form.Control
                type="search"
                placeholder="Search Task Name..."
                style={{ width: "250px" }}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </Form>

            <Button
              onClick={handleShowTask}
              style={{
                background:
                  "linear-gradient(106.37deg, #fca61f 29.63%, #ff8c00 51.55%, #e07b39 90.85%)",
                border: "none",
              }}>
              + Task
            </Button>
          </div>
        </div>
        <div className="table_css">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "center",
                  }}>
                  Organization Name
                </th>
                <th>Process Name</th>
                <th>Department</th>
                <th>Department Role</th>
                <th>Task Name</th>
                <th
                  style={{
                    textAlign: "center",
                  }}>
                  SLA
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}>
                  Task Flow Order
                </th>
                {/* <th
                  style={{
                    textAlign: "center",
                  }}>
                  Status
                </th> */}
                <th
                  style={{
                    textAlign: "center",
                  }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {Tasks && Tasks.length > 0 ? (
                Tasks.map((tsk, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        textAlign: "center",
                      }}>
                      {tsk.organization?.org_name}
                    </td>
                    <td>{tsk.process?.process_name}</td>
                    <td>
                       {tsk.departments &&
                        [...new Map(tsk.departments.map((c) => [c.dept_id, c])).values()]
                          .map((c) => c.dept_name)
                          .join(", ")}
                    </td>
                    <td>
                        {tsk.departments &&
                        [...new Map(tsk.departments.map((c) => [c.dept_role_id, c])).values()]
                          .map((c) => c.dept_role_name)
                          .join(", ")}
                    </td>
                    <td>{tsk.task_name}</td>
                    
                          <td style={{ textAlign: "center" }}>
                        {isWholeNumber(tsk.sla_resolution_time) 
                          ? `${tsk.sla_resolution_time} days`
                          : formatTime(tsk.sla_resolution_time)}
                      </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}>
                      {" "}
                      {tsk.task_flow_order}
                    </td>
                    {/* <td>

                       
                      
                      <div className="uniq_switch">
                   
   <Switch
  onChange={(checked) => handleToggleChange(checked, tsk)}
  checked={tsk.status === 1}
  onColor="#0d6efd"
  offColor="#6c757d"
  uncheckedIcon={true}
  checkedIcon={true}
  height={20}
  width={42}
  handleDiameter={18}
  className="custom-switch"
/>

                      </div>
                      
                    </td> */}

                    <td>
                      <FaPen
                        style={{
                          cursor: "pointer",
                          color: "green",
                          textAlign: "center",
                        }}
                        onClick={() => handleEditTask(tsk.id)}  
                      />
                       
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No Tasks found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <div className="pagination_styles">
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Pagination>
        </div>
      </div>

      {/* Task Modal */}
      <Modal show={showTaskModal} onHide={handleCloseTask} centered size="lg">
        <Modal.Body>
          <AddTask FlowTask={FlowTask} handleClose={handleCloseTask} />
        </Modal.Body>
      </Modal>

      {/* Edit Task Modal */}
      <Modal
        show={showEditTaskModal}
        onHide={handleEditCloseTask}
        centered
        size="lg">
        <Modal.Body>
           {showEditTaskModal &&  
            <EditUpdateModal 
            DefaultGet={DefaultGet}
            organizations={organizations}
             mgorganization={mgorganization} 
             handleEditCloseTask={handleEditCloseTask}
             /> }
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Task;
