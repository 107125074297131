import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary Chart.js components
ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const Charts = ({ chartData }) => {
  return (
    <div>
      <div
        className="chart-container p-4 m-3"
        style={{ height: "400px", background: "white", borderRadius: "10px" }}>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "top",
                labels: {
                  color: "black",
                },
              },
              tooltip: {
                callbacks: {
                  label: (tooltipItem) =>
                    `${tooltipItem.label}: ${tooltipItem.raw}`,
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  color: "black",
                },
                grid: {
                  color: "black",
                },
              },
              y: {
                ticks: {
                  color: "black",
                },
                grid: {
                  color: "black",
                },
                min: 0,   // Set minimum Y-axis value
                max: 10, // Set maximum Y-axis value
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default Charts;
