import React, { useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import "./OrganizationForm.css";

const OrganizationForm = ({
  formData,
  setFormData,
  handleSubmit,
  buttonName,
  handleClose,
}) => {
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Container className="process-form-container">
      <h2 className="text-center mb-4">Organization</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          {/* Organization Name */}
          <Col md={12} style={{ padding: "10px" }}>
            <Form.Group controlId="organizationName">
              <Form.Label>Organization Name</Form.Label>
              <Form.Control
                type="text"
                name="organizationName"
                value={formData.organizationName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Action Buttons */}
        <div className="form-actions mb-4">
          <Button
            variant="outline-light"
            type="reset"
            className="cancel-btn"
            onClick={() => {
              console.log("Cancel Organization clicked");
              handleClose();
            }}>
            Close 
          </Button>
          <Button variant="outline-light" type="submit" className="create-btn">
            {buttonName}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default OrganizationForm;
