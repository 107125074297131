import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Container, Row, Col, Table } from "react-bootstrap";
import URL from "../../components/API/Api";
import "./TaskForm.css";
import Select from "react-select";

const CustomTaskForm = ({
  isthisSubtask,
  FlowTask,
  Editform,
  editTaskFlowOrder,
  formData,
  setFormData,
  handleSubmit,
  buttonName,
  handleClose,
  errors,
  selectedUsers = [],
  setSelectedUsers,
}) => {
  const [processOptions, setProcessOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ProcessGetvalue, setProcessGetvalue] = useState();
  const [TaskNumberFlow, setTaskNumberFlow] = useState();
  const [NewDeptRole, setNewDeptRole] = useState();
  const [TasksField, setTasksField] = useState([]);
  const [mgorganization, setMGOrganization] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [FilteruserDetails, setFilterUserDetails] = useState([]);
  const [taskStatus, setTaskStatus] = useState("");
  const [users, setUsers] = useState([]);

  // console.log(isthisSubtask, "isthisSubtask");

  console.log(FilteruserDetails, "myuserlist");

  useEffect(() => {
    if (NewDeptRole) {
      setFormData((prevData) => ({
        ...prevData,
        dept_role_id: NewDeptRole, // Update formData when newDeptRole changes
      }));
    }
  }, [NewDeptRole]);

  useEffect(() => {
    if (formData.dept_role_id) {
      FilteringBydeptRoles(formData.dept_role_id);
    }
  }, [formData.dept_role_id]);

  const fetchUsers = async (deptIds) => {
    if (!deptIds || deptIds.length === 0) {
      setUserDetails([]);
      setFilterUserDetails([]);
      return;
    }

    try {
      const response = await URL.get("/api/task/usersData", {
        params: { dept_id: deptIds.join(",") }, // Convert array to CSV string
      });

      if (response.data.status) {
        const users = response.data.data;
        setUserDetails(users);
        setFilterUserDetails(users);
      } else {
        setUserDetails([]);
        setFilterUserDetails([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setUserDetails([]);
      setFilterUserDetails([]);
    }
  };

  const FilteringBydeptRoles = (roleIds) => {
    if (!roleIds.length) {
      setFilterUserDetails(userDetails);
      return;
    }

    console.log(userDetails, "hjklop", roleIds);

    const filteredUsersList = userDetails.filter((user) =>
      roleIds.includes(user.dept_role_id)
    );

    setFilterUserDetails(filteredUsersList);
  };

  // console.log(FilteruserDetails,"FilteruserDetailsFilteruserDetails")

  useEffect(() => {
    fetchUsers(formData.dept_id);
  }, [formData.dept_id, formData.dept_role_id]);

  const fetchProcessesAndDepartments = (d) => {
    // if (!formData.org_id) return; // Skip if no organization is selected
    // setLoading(true);

    URL.get(`api/org/${d}`)
      .then((res) => {
        if (res.data && res.data.status) {
          const orgData = res.data.data;

          // Format processes for the dropdown
          const formattedProcessOptions = orgData.processes.map((process) => ({
            value: process.process_id,
            label: process.process_name || `Process ${process.process_id}`, // Use a fallback if process name is null
            key: `process-${process.process_id}`,
          }));
          setProcessOptions(formattedProcessOptions);

          // Format departments and their roles for the dropdown
          const formattedDepartments = orgData.departments.map((dept) => ({
            value: dept.department_id,
            label: dept.department_name,

            roles: dept.roles.map((role) => ({
              value: role.role_id,
              label: role.dep_role_name,
            })),
            key: dept.department_id,
          }));
          setDepartmentOptions(formattedDepartments);
        }
      })
      .catch((err) => console.log(err));
  };

  const GetTaskFlowing = (d) => {
    const selectedProcess = FlowTask.find(
      (process) => process.id === parseInt(d)
    );
    if (selectedProcess) {
      setTasksField(selectedProcess.tasks);
      if (selectedProcess?.tasks?.length === 0) {
        setTaskNumberFlow(1);
      } else {
        const lastObject =
          selectedProcess?.tasks[selectedProcess?.tasks?.length - 1];
        const gettheFlowOrder = lastObject.task_flow_order;
        const AddCountFlowOrder = gettheFlowOrder + 1;

        setTaskNumberFlow(parseInt(AddCountFlowOrder));
      }
    } else {
      setTasksField([]);
      setTaskNumberFlow(1);
    }
  };
  // console.log(roleOptions, "jkll");

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target || {};

    if (name == "process_id") {
      console.log(value);
      setProcessGetvalue(value);

      setFormData((prev) => ({
        ...prev,
        [name]: name === "process_id" ? String(value) : value,
      }));

      GetTaskFlowing(parseInt(value, 10));
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "dept_id") {
      // Handle as array now
      const deptIds = e.map((opt) => opt.value);
      setFormData((prevData) => ({
        ...prevData,
        dept_id: deptIds,
      }));
      console.log(deptIds, "sds");
      // Fetch users for all selected departments
      fetchUsers(deptIds);

      // Get roles for selected departments
      const roles = departmentOptions
        .filter((dept) => deptIds.includes(dept.value))
        .flatMap((dept) => dept.roles);

      setRoleOptions(roles);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const SubTaskGrid = isthisSubtask ? 12 : 6;

  const handleSelectUser = (userId, user) => {
    setFormData((prevFormData) => {
      const updatedSelectedUsers = [...prevFormData.selectedUsers];
      const updatedDeptRoles = [...prevFormData.dept_role_id];
      let updatedDepartments = [...(prevFormData.departments || [])];

      const deptObj = {
        dept_id: user.dept_id, // Assuming user.department_id exists
        dept_role_id: user.dept_role_id,
        user_id: userId,
      };

      if (updatedSelectedUsers.includes(userId)) {
        // Remove user & related department entry
        updatedDepartments = updatedDepartments.filter(
          (item) => item.user_id !== userId
        );

        return {
          ...prevFormData,
          selectedUsers: updatedSelectedUsers.filter((id) => id !== userId),
          dept_role_id: updatedDeptRoles.filter(
            (roleId) => roleId !== user.dept_role_id
          ),
          departments: updatedDepartments,
        };
      } else {
        // Add user & related department entry
        if (!updatedDepartments.find((item) => item.user_id === userId)) {
          updatedDepartments.push(deptObj);
        }

        return {
          ...prevFormData,
          selectedUsers: [...updatedSelectedUsers, userId],
          dept_role_id: updatedDeptRoles.includes(user.dept_role_id)
            ? updatedDeptRoles
            : [...updatedDeptRoles, user.dept_role_id],
          departments: updatedDepartments,
        };
      }
    });
  };

  // console.log(formData.departments,"naanthaan")

  const handleSelectAll = () => {
    if (formData.selectedUsers.length === userDetails.length) {
      // Deselect all
      setFormData((prev) => ({
        ...prev,
        selectedUsers: [],
        dept_role_id: [],
        departments: [],
      }));
    } else {
      // Select all users & collect all unique dept_role_ids & departments
      const allUserIds = userDetails.map((user) => user.user_id);
      const allDeptRoleIds = [
        ...new Set(userDetails.map((user) => user.dept_role_id)),
      ];

      const allDepartments = userDetails.map((user) => ({
        dept_id: user.dept_id, // Assuming this exists on each user object
        dept_role_id: user.dept_role_id,
        user_id: user.user_id,
      }));

      setFormData((prev) => ({
        ...prev,
        selectedUsers: allUserIds,
        dept_role_id: allDeptRoleIds,
        departments: allDepartments,
      }));
    }
  };

  const fetchOrganizations = () => {
    URL.get("api/allorg")
      .then((res) => {
        const organizationData = res?.data?.data;
        setOrganizations(organizationData);
        const mgOrg = organizationData.filter((c) => c?.org_name === "MG");
        setMGOrganization(mgOrg);
        if (mgOrg.length > 0) {
          fetchProcessesAndDepartments(mgOrg[0]?.id);
          setFormData((prev) => ({
            ...prev,
            org_id: mgOrg[0]?.id,
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container className="task-form-container">
      <h5
        className={` ${
          isthisSubtask === true
        }?"text-center" :" text-center mb-2"`}>
        {" "}
        {isthisSubtask === true ? "Sub Task " : "Custom Task Setup"}{" "}
      </h5>
      <Form
        style={{
          height: isthisSubtask === true ? "80vh" : "",
          overflowY: isthisSubtask === true ? "auto" : "",
          padding: isthisSubtask === true ? "15px" : "",
        }}>
        <Row>
          {/* Organization Name Dropdown */}
          {/* <Col md={6} style={{ padding: "8px" }}>
            <Form.Group controlId="org_id">
              <Form.Label>
                Organization
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="org_id"
                value={formData.org_id}
                onChange={handleChange}
                className={`select-placeholder ${
                  formData.organization === "" ? "placeholder" : ""
                }`}>
                <option value="" style={{ color: "#525658" }}>
                  Select Organization
                </option>
                {organizations.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.org_name}
                  </option>
                ))}
              </Form.Control>
              {errors.org_id && (
                <small className="text-danger">{errors.org_id}</small>
              )}{" "}
             
            </Form.Group>
          </Col> */}

          {/* Process Name Dropdown */}
          {/* <Col md={6} style={{ padding: "8px" }}>
            <Form.Group controlId="process_id">
              <Form.Label>
                Process Name
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="process_id"
                value={formData.process_id}
                onChange={handleChange}
                disabled={loading}>
                <option value="">Select Process</option>
                {processOptions.map((process) => (
                  <option key={process.key} value={process.value}>
                    {process.label}
                  </option>
                ))}
              </Form.Control>
       
              {errors.process_id && (
                <small className="text-danger">{errors.process_id}</small>
              )}
            </Form.Group>
          </Col> */}

          {/* Department Dropdown */}
          <Col md={6} style={{ padding: "8px" }}>
            <Form.Group controlId="dept_id">
              <Form.Label>
                Department <span className="text-danger">*</span>
              </Form.Label>
              <Select
                isMulti
                name="dept_id"
                value={departmentOptions.filter((opt) =>
                  formData.dept_id.includes(opt.value)
                )}
                options={departmentOptions.map((dept) => ({
                  value: dept.value,
                  label: dept.label,
                }))}
                onChange={(selected) => {
                  const deptIds = selected.map((opt) => opt.value);
                  setFormData((prevData) => ({
                    ...prevData,
                    dept_id: deptIds,
                  }));
                  console.log(deptIds);

                  fetchUsers(deptIds); // ✅ Call fetchUsers with the correct array

                  // Optional: Populate roleOptions dynamically
                  const roles = departmentOptions
                    .filter((dept) => deptIds.includes(dept.value))
                    .flatMap((dept) => dept.roles);

                  setRoleOptions(roles);
                }}
                placeholder="Select Department"
              />
            </Form.Group>
          </Col>
          <Col md={8} style={{ padding: "10px" }}></Col>
          {/* Department Role Dropdown */}
          <Col md={4} style={{ padding: "8px" }}>
            <Form.Group controlId="dept_role_id">
              <Form.Label>
                {/* Department Role <span className="text-danger">*</span> */}
              </Form.Label>
              <Select
                isMulti
                name="dept_role_id"
                value={roleOptions.filter((opt) =>
                  (formData.dept_role_id || []).includes(opt.value)
                )}
                options={roleOptions.map((role) => ({
                  value: role.value,
                  label: role.label,
                }))}
                onChange={(selected) => {
                  const roleIds = selected.map((opt) => opt.value);
                  setFormData({ ...formData, dept_role_id: roleIds });
                  FilteringBydeptRoles(roleIds);
                }}
                isDisabled={roleOptions.length === 0}
                placeholder="Department Roles"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    borderBottom: "2px solid gray",
                  }),
                }}
              />
            </Form.Group>
          </Col>

          <Col md={12} style={{ padding: "8px" }}>
            {formData.dept_id ? (
              FilteruserDetails.length ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          checked={selectedUsers.length === userDetails.length} // Ensure all users are selected
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>User ID</th>
                      <th>Department</th>
                      <th>Department Role</th>
                      <th>Name</th>
                      <th>Handling States</th>
                      <th>Contact Number</th>
                      <th>Email</th>
                      <th>Assigned Tasks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {FilteruserDetails.map((user) => (
                      <tr key={user.user_id}>
                        <td>
                          <Form.Check
                            type="checkbox"
                            checked={selectedUsers.includes(user.user_id)} // Check if this user is selected
                            onChange={() =>
                              handleSelectUser(user.user_id, user)
                            } // Update on change
                          />
                        </td>
                        <td>{user.user_id}</td>
                        <td>{user.department_name}</td>
                        <td>{user.department_role_name}</td>
                        <td>{user.name}</td>
                        <td>
                          <span> {
                            user.department_name.toLowerCase() !== "mikro sales" ? "Centralized" :
                             <span>
                          {user?.state?.map((c) => c.name).join(", ") ||
                            "No State"}{" "}
                             </span> }
                            </span>
                        </td>
                        <td>{user.contact_number}</td>
                        <td>{user.email}</td>
                        <td>{user?.total_task}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th colSpan="5" style={{ textAlign: "center" }}>
                        No Users Assigned
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        colSpan="5"
                        style={{ textAlign: "center", color: "red" }}>
                        There is no user assigned to this department and its
                        role. Please assign users.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )
            ) : null}
          </Col>

          {/* Task Name */}
          <Col md={6} style={{ padding: "8px" }}>
            <Form.Group controlId="task_name">
              <Form.Label>
                {isthisSubtask === true ? "Sub Task Name" : "Task Name"}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="task_name"
                value={formData.task_name}
                placeholder="Task Name"
                onChange={handleChange}
              />
            </Form.Group>
            {errors.task_name && (
              <small className="text-danger">{errors.task_name}</small>
            )}
          </Col>

          {isthisSubtask === true ? (
            <Col md={12} style={{ padding: "8px" }}>
              <Form.Group controlId="priority">
                <Form.Label>
                  {isthisSubtask === true
                    ? "Sub Task Priority"
                    : "Task Priority"}
                  <span className="text-danger">*</span>
                </Form.Label>
                <div
                  style={{
                    display: "flex",
                    gap: "30px",
                    justifyContent: "space-around",
                  }}>
                  <Form.Check
                    type="radio"
                    id="priority-high"
                    name="priority"
                    label="High"
                    value="0"
                    checked={formData.priority === "0"}
                    onChange={handleChange}
                    style={{ color: "red" }}
                  />
                  <Form.Check
                    type="radio"
                    id="priority-medium"
                    name="priority"
                    label="Medium"
                    value="1"
                    checked={formData.priority === "1"}
                    onChange={handleChange}
                    style={{ color: "orange" }}
                  />
                  <Form.Check
                    type="radio"
                    id="priority-low"
                    name="priority"
                    label="Low"
                    value="2"
                    checked={formData.priority === "2"}
                    onChange={handleChange}
                    style={{ color: "green" }}
                  />
                </div>
                {errors.priority && (
                  <small className="text-danger">{errors.priority}</small>
                )}
              </Form.Group>
            </Col>
          ) : (
            <Col md={6} style={{ padding: "8px" }}>
              <Form.Group controlId="priority">
                <Form.Label>
                  Task Priority
                  <span className="text-danger">*</span>
                </Form.Label>
                <div
                  style={{
                    display: "flex",
                    gap: "30px",
                    justifyContent: "space-around",
                  }}>
                  <Form.Check
                    type="radio"
                    id="priority-high"
                    name="priority"
                    label="High"
                    value="0"
                    checked={formData.priority === "0"}
                    onChange={handleChange}
                    style={{ color: "red" }}
                  />
                  <Form.Check
                    type="radio"
                    id="priority-medium"
                    name="priority"
                    label="Medium"
                    value="1"
                    checked={formData.priority === "1"}
                    onChange={handleChange}
                    style={{ color: "orange" }}
                  />
                  <Form.Check
                    type="radio"
                    id="priority-low"
                    name="priority"
                    label="Low"
                    value="2"
                    checked={formData.priority === "2"}
                    onChange={handleChange}
                    style={{ color: "green" }}
                  />
                </div>
                {errors.priority && (
                  <small className="text-danger">{errors.priority}</small>
                )}
              </Form.Group>
            </Col>
          )}

          <Col md={6} style={{ padding: "8px" }}>
            {/* Resolution Type Select */}
            <Form.Group controlId="resolution_type">
              <Form.Label>
                Target Type
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                name="resolution_type"
                value={formData.resolution_type}
                onChange={handleChange}
                aria-label="Select Resolution Type">
                <option value="">Choose...</option>
                <option value="0">Day</option>
                <option value="1">Hour</option>
              </Form.Select>
              {errors.resolution_type && (
                <small className="text-danger">{errors.resolution_type}</small>
              )}
            </Form.Group>
          </Col>

          {formData.resolution_type === "0" && (
            <Col md={6} style={{ padding: "8px" }}>
              <Form.Group controlId="days">
                <Form.Label>Select No of Days</Form.Label>
                <Form.Select
                  name="days"
                  value={formData.days}
                  onChange={handleChange}>
                  <option value="">Choose...</option>
                  {Array.from({ length: 10 }, (_, i) => i + 1).map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </Form.Select>
                {errors.days && (
                  <small className="text-danger">{errors.days}</small>
                )}
              </Form.Group>
            </Col>
          )}

          {formData.resolution_type === "1" && (
            <>
              <Col md={3} style={{ padding: "10px" }}>
                <Form.Group controlId="hours">
                  <Form.Label>Select Hours</Form.Label>
                  <Form.Select
                    name="hours"
                    value={formData.hours}
                    onChange={handleChange}>
                    <option value="">Choose...</option>
                    {Array.from({ length: 8 }, (_, i) => i + 1).map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.hours && (
                    <small className="text-danger">{errors.hours}</small>
                  )}
                </Form.Group>
              </Col>

              <Col md={3} style={{ padding: "8px" }}>
                <Form.Group controlId="minutes">
                  <Form.Label>Select Minutes</Form.Label>
                  <Form.Select
                    name="minutes"
                    value={formData.minutes}
                    onChange={handleChange}>
                    <option value="">Choose...</option>
                    <option value="00">00</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="45">45</option>
                  </Form.Select>
                  {errors.minutes && (
                    <small className="text-danger">{errors.minutes}</small>
                  )}
                </Form.Group>
              </Col>
            </>
          )}

          {/* SLA Resolution Time */}
          {/* <Col md={6} style={{ padding: "8px" }}>
            <Form.Group controlId="sla_resolution_time">
              <Form.Label>
                SLA Resolution Time
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="time"
                name="sla_resolution_time"
                value={formData.sla_resolution_time}
                onChange={handleChange}
              />
              {errors.sla_resolution_time && (
                <small className="text-danger">
                  {errors.sla_resolution_time}
                </small>
              )}
            </Form.Group>
          </Col> */}

          {/* Is Document Required Checkbox */}

          {/* <Col lg={6} md={6} sm={12}>
            <Form.Group>
              <Form.Label style={{ fontWeight: "bold" }}>
                Task Status
              </Form.Label>
              <span className="text-danger" style={{ fontSize: "0.85em" }}>
                *
              </span>
              <Form.Select
                value={taskStatus}
                onChange={handleStatusChange}
                className="form-control">
                <option value={0}>Open</option>
                <option value={1}>Completed</option>
                <option value={2}>Reassign</option>
                <option value={3}>Rejected</option>
              </Form.Select>
            </Form.Group>
          </Col> */}

          {/* Task Description */}
          <Col md={12} style={{ padding: "8px" }}>
            <Form.Group controlId="task_description">
              <Form.Label>
                {isthisSubtask === true
                  ? "Sub Task Description (if any)"
                  : "Task Description (if any)"}
              </Form.Label>
              <Form.Control
                style={{ height: "70px" }}
                as="textarea"
                name="task_description"
                rows={4}
                value={formData.task_description}
                onChange={handleChange}
              />
            </Form.Group>
            {taskStatus === "2" && (
              <div>
                <label htmlFor="additionalInfo">Additional Information</label>
                <input type="text" id="additionalInfo" name="additionalInfo" />

                <Form.Group>
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Reassign To
                  </Form.Label>
                  <span className="text-danger" style={{ fontSize: "0.85em" }}>
                    *
                  </span>
                  <Form.Select
                    // value={reassignUser}
                    // onChange={handleReassignChange}
                    className="form-control">
                    <option value="">Select User</option>
                    {users.length > 0 ? (
                      users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))
                    ) : (
                      <option>No users available</option>
                    )}
                  </Form.Select>
                </Form.Group>
              </div>
            )}
          </Col>
        </Row>
        {/* document required */}
        <Col md={6}>
          {/* <Form.Group controlId="is_doc_upload_required">
            <Form.Check
              type="checkbox"
              label="Is Document Required"
              name="is_doc_upload_required"
              checked={formData.is_doc_upload_required}
              onChange={handleChange}
            />
          </Form.Group> */}
          <Form.Group
            controlId="is_doc_upload_required"
            className="d-flex "
            style={{
              alignItems: "start",
              marginLeft: "-10px",
              marginTop: "10px",
            }}>
            <Form.Check.Input
              type="checkbox"
              name="is_doc_upload_required"
              checked={formData.is_doc_upload_required}
              onChange={handleChange}
              className="me-2"
            />
            <Form.Label>Is Document Required</Form.Label>
          </Form.Group>
        </Col>

        {/* Action Buttons */}
        <div className="form-actions  mt-1">
          {isthisSubtask === true ? (
            ""
          ) : (
            <Button
              variant="outline-light"
              type="reset"
              className="cancel-btn"
              onClick={handleClose}>
              close
            </Button>
          )}
          <Button
            variant="outline-light"
            // type="submit"
            className="create-btn"
            disabled={selectedUsers.length === 0}
            onClick={() => handleSubmit(TaskNumberFlow, formData)}>
            {buttonName}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default CustomTaskForm;
