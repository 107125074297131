import React, { useEffect, useState } from "react";
import "./DashComponents.css";

export const Ticket = ({ count, ticket_name, icon, background }) => {
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div >
      {isMobile ? 
      <div
      className="ticket  "
      style={{ background ,display:'flex',flexDirection:'row',justifyContent:'space-between',paddingTop:'10px',paddingRight:'20px' }}>
      <h6 className={isMobile?"py-":" py-4"} style={{ paddingLeft:'27px' }}>{ticket_name}</h6>
      <div >
        <div className="count_disc">{count}</div> 
      </div>
    </div>
      :
    <div
      className="ticket  d-flex flex-column "
      style={{ background }}>
      <h6 className={isMobile?"py-2":" py-4"} style={{ paddingLeft:'27px' }}>{ticket_name}</h6>
      <div className="ticket_info">
        <div className="count_disc">{count}</div>
        <div className="ticket_icon">{icon}</div>
      </div>
    </div>
    }
    </div>
  );
};
