import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import URL from "../../components/API/Api";

import Select from "react-select";

const EditUpdateModal = ({
  DefaultGet,
  handleEditCloseTask,
  organizations,
  mgorganization,
}) => {
  const [depaartments, setdepaartments] = useState([]);
  const [departmentNames, setDepartmentNames] = useState({});
  const [userDetails, setUserDetails] = useState([]);

  const [processOptions, setProcessOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [isSLAResolutionHour, setisSLAResolutionHour] = useState(0);
  const [Thours, setHours] = useState(""); // To store hours if SLA is based on hours
  const [Tminutes, setMinutes] = useState(""); // To store minutes if SLA is based on hours

  const [FilteruserDetails, setFilterUserDetails] = useState([]);

  const [payloaddepartments, setpayloadDepartments] = useState([]);

  //edit form states
  const [editactprcsID, seteditactprcsID] = useState("");
  const [editactFloOrder, seteditactFloOrder] = useState("");
  const [editFloOrder, seteditFloOrder] = useState("");
  const [editTaskName, seteditTaskName] = useState("");
  const [editTaskDesc, seteditTaskDesc] = useState("");
  const [editDepID, seteditDepID] = useState([]);
  const [divert, setDivert] = useState("");
  const [selectedUserss, setselectedUserss] = useState([]);
  const [PreviousselectedUserss, setPreviousselectedUserss] = useState([]);

  const [editOrgID, seteditOrgID] = useState("");
  const [editDeptRoleID, seteditDeptRoleID] = useState([]);
  const [editSLA, seteditSLA] = useState("");
  const [allPrcswithTask, setAllPrcswithTask] = useState([]);

  const [editDocum, seteditDocum] = useState(0);
  const [editstatus, seteditstatus] = useState("");
  const [editDueDate, seteditDueDate] = useState("");
  const [editPrcsID, seteditPrcsID] = useState("");

  const [usersList, setUsersList] = useState([]); // to store users based on dept and role selection
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    console.log(DefaultGet, "DefaultGet");
    fetchProcessesAndDepartments(mgorganization[0]?.id);
    GetTaskDetail(DefaultGet);
  }, []);

  console.log(payloaddepartments, "payyylooo");

  useEffect(() => {
    const selectedDepartment = departmentOptions.find(
      (dept) => dept.value === editDepID
    );

    if (selectedDepartment) {
      // Set available role options for this department
      setRoleOptions(
        selectedDepartment.roles.map((r) => ({
          value: r.id,
          label: r.name,
        }))
      );

      // Pre-fill roles based on editDeptRoleID if they belong to this department
      const filteredRoles = (editDeptRoleID || []).filter((role) =>
        selectedDepartment.roles.some((r) => r.id === role.dept_role_id)
      );

      // If no roles are found, fallback to selecting first available role
      const defaultRole = selectedDepartment.roles[0];

      seteditDeptRoleID(
        filteredRoles.length > 0
          ? filteredRoles
          : defaultRole
          ? [{ dept_role_id: defaultRole.id }]
          : []
      );
    }
  }, [editDepID, departmentOptions, editDeptRoleID]);

  const GetTaskDetail = async (prcs_di) => {
    try {
      const response = await URL.get(`/api/task/${prcs_di}`);
      const taskDetails = response.data.data;
      console.log(taskDetails, "getttttt");
      seteditFloOrder(taskDetails.task_flow_order);
      seteditactFloOrder(taskDetails.task_flow_order);
      seteditactprcsID(taskDetails.task_flow_order);
      seteditTaskName(taskDetails.task_name);
      seteditTaskDesc(taskDetails.task_description);
      seteditDepID(taskDetails.departments);
      seteditOrgID(taskDetails.org_id);
      seteditDeptRoleID(taskDetails.departments);
      setselectedUserss(taskDetails.users);
      setPreviousselectedUserss(taskDetails.users);

      const formattedDepartmentRoles = taskDetails.departments.reduce(
        (acc, dept) => {
          if (!acc.some((item) => item.value === dept.dept_role_id)) {
            acc.push({
              value: dept.dept_role_id,
              label: dept.dept_role_name,
              key: dept.dept_role_id,
            });
          }
          return acc;
        },
        []
      );

      setRoleOptions(formattedDepartmentRoles);
      // console.log(roleOptions,"myroles")
      // seteditSLA(taskDetails.sla_resolution_time);
      const slaResolutionTime = taskDetails.sla_resolution_time;
      if (slaResolutionTime) {
        if (slaResolutionTime.includes(":")) {
          // It has hours and minutes (e.g., "1:15")
          // console.log(slaResolutionTime,"mytime")

          const [hourValue, minuteValue] = slaResolutionTime.split(":");
          setHours(hourValue);
          setMinutes(minuteValue); // Set to hours resolution
          // console.log(Thours,Tminutes,"editsl");
        } else {
          // console.log(slaResolutionTime,"mytime2")

          // It's just a number (e.g., "3" for days)
          seteditSLA(slaResolutionTime); // Set it as the number of days
        }
      }
      // console.log(editSLA,"editsla",Thours)
      setisSLAResolutionHour(taskDetails.is_resolution_hour);
      seteditDocum(parseInt(taskDetails.is_doc_upload_required, 10));
      seteditstatus(taskDetails.status);
      seteditDueDate(taskDetails.due_date);
      seteditPrcsID(taskDetails.process_id);
      // Set usersList and selectedUsers
      const usersWithAssignments = taskDetails.users || [];
      setUsersList(usersWithAssignments); // Set users list from API data
      setFilterUserDetails(usersWithAssignments); // Set users list from API data
      console.log(FilteruserDetails?.length, "sspbala");
      // Set selectedUsers based on 'is_assigned'
      const selected = taskDetails.users?.map((user) => user?.user_id);
      setSelectedUsers(selected);
    } catch (error) {
      console.error("Error fetching process data:", error);
    }
  };

  // console.log(FilteruserDetails,"FilteruserDetailsvv")

  // console.log(editDocum,"editttt")
  const fetchProcessesAndDepartments = (d) => {
    // if (!formData.org_id) return; // Skip if no organization is selected
    // setLoading(true);

    URL.get(`api/org/${d}`)
      .then((res) => {
        if (res.data && res.data.status) {
          const orgData = res.data.data;

          console.log(orgData, "orgggdata");
          // Format processes for the dropdown
          const formattedProcessOptions = orgData.processes.map((process) => ({
            value: process.process_id,
            label: process.process_name || `Process ${process.process_id}`, // Use a fallback if process name is null
            key: `process-${process.process_id}`,
          }));
          setProcessOptions(formattedProcessOptions);

          // Format departments and their roles for the dropdown
          const formattedDepartments = orgData.departments.map((dept) => ({
            value: dept.department_id,
            label: dept.department_name,
            roles: dept.roles.map((role) => ({
              value: role.role_id,
              label: role.dep_role_name,
            })),
            key: dept.department_id,
          }));
          setDepartmentOptions(formattedDepartments);
          setdepaartments(orgData?.departments);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchUsers(editDepID.map((c) => c.dept_id));
  }, [editDepID]);

  // Fetch department names based on dept_ids
  const fetchDepartmentNames = async (deptIds) => {
    const deptNameMapping = {};
    try {
      const fetchPromises = deptIds.map((id) =>
        URL.get(`user-management/api/v1/orgdept/${id}`)
      );
      const deptResponses = await Promise.all(fetchPromises);

      deptResponses.forEach((response) => {
        const deptData = response.data?.data;
        if (deptData) {
          deptNameMapping[deptData.id] = deptData.department_name;
        }
      });

      setDepartmentNames(deptNameMapping);
    } catch (error) {
      console.error("Error fetching department names:", error);
    }
  };

  const SettingTaskOrderHandler = (prcs__id) => {
    // console.log(prcs__id, "prcs__id");
    // console.log(editactId, "prcs__id2");
    URL.get("/api/allprocess_task")
      .then((res) => {
        console.log(res.data.data, "ddddkkklll");
        setAllPrcswithTask(res.data.data);

        // Find the organization directly from the response data
        const TaskernAtion = res.data.data.find((prs) => prs.id == prcs__id);
        console.log(TaskernAtion, "getobj");
        if (TaskernAtion && TaskernAtion.id === editactprcsID) {
          // Example action
          // setSomeState(TaskernAtion); // Uncomment if you want to set a state with the TaskernAtion
          console.log("TaskernAtion matches selected ID:", TaskernAtion);

          seteditFloOrder(parseInt(editactFloOrder));
        } else {
          console.log("No match found or TaskernAtion is undefined.");
          if (TaskernAtion?.tasks?.length === 0) {
            seteditFloOrder(1); // Return 1 if the array is empty
          } else {
            const lastObject =
              TaskernAtion?.tasks[TaskernAtion?.tasks?.length - 1];
            const gettheFlowOrder = lastObject.task_flow_order; // Return the value of number_flow
            const AddCountFlowOrder = gettheFlowOrder + 1;

            // console.log(AddCountFlowOrder,"getorder");
            seteditFloOrder(parseInt(AddCountFlowOrder));
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchUsers = async (deptId) => {
    if (!deptId) return;

    console.log(deptId, "Fetching users for department");

    try {
      const response = await URL.get("/api/task/usersData", {
        params: { dept_id: deptId.join(",") },
      });

      if (response.data.status) {
        let users = response.data.data;
        console.log(users, "Fetched users");

        setUserDetails(users); // Store all users initially
        setFilterUserDetails(users); // Store all users initially
      } else {
        setUserDetails([]);
        setFilterUserDetails([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setUserDetails([]);
      setFilterUserDetails([]);
    }
  };

  const FilteringBydeptRoles = (roleObjects, selectedDeptIds) => {
    if (
      (!roleObjects || roleObjects.length === 0) &&
      (!selectedDeptIds || selectedDeptIds.length === 0)
    ) {
      setFilterUserDetails(userDetails);
      return;
    }

    const roleIds = roleObjects.map((r) => r.dept_role_id);

    const filteredUsersList = userDetails.filter((user) => {
      const matchDept =
        selectedDeptIds.length > 0
          ? selectedDeptIds.includes(user.dept_id)
          : true;
      const matchRole =
        roleIds.length > 0 ? roleIds.includes(user.dept_role_id) : true;
      return matchDept && matchRole;
    });

    setFilterUserDetails(filteredUsersList);
  };

  const handleResolutionChange = (e) => {
    const value = e.target.value;
    setisSLAResolutionHour(value);

    // If switching to hours, initialize Thours and Tminutes
    if (value === "1") {
      // Convert `editSLA` to hours and minutes if it exists
      const [hours, minutes] = editSLA
        .split(":")
        .map((num) => parseInt(num, 10));
      setHours(hours || 1); // Default to 1 if no valid hours found
      setMinutes(minutes || 0); // Default to 0 if no valid minutes found
    } else {
      seteditSLA(""); // Reset editSLA if not in hours mode
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    // console.log(Thours, Tminutes, "cccm");
    setDivert(`${Thours}:${Tminutes}`);
    // if ((isSLAResolutionHour === 1 || isSLAResolutionHour === "1") && Thours && Tminutes) {
    //   const mydate = `${Thours}:${Tminutes}`;
    //   seteditSLA(divert);
    // } else {
    //   seteditSLA(editSLA);  // Keep the existing value if in days mode
    // }

    let slaResolutionTime = editSLA; // Default to current value of editSLA

    // If hours and minutes mode is selected, construct the SLA time
    if (
      (isSLAResolutionHour === 1 || isSLAResolutionHour === "1") &&
      Thours &&
      Tminutes
    ) {
      slaResolutionTime = `${Thours}:${Tminutes}`; // Combine hours and minutes
    } else if (isSLAResolutionHour === "0" || isSLAResolutionHour === 0) {
      // If in days mode, keep the existing editSLA value
      slaResolutionTime = editSLA;
    }

    console.log(editSLA, "forest", divert);
    const payload = {
      task_flow_order: editFloOrder,
      task_name: editTaskName,
      task_description: editTaskDesc,
      org_id: editOrgID,
      // dept_id:  [...new Set(editDepID.map((c) => c.dept_id))].join(","),
      // departments: depaartments,
      // dept_role_id:  [...new Set(editDeptRoleID.map((c) => c.dept_role_id))].join(","),
      dept_id: [...new Set(editDepID.map((c) => c.dept_id))],
      dept_role_id: [...new Set(editDeptRoleID.map((c) => c.dept_role_id))],
      is_resolution_hour: isSLAResolutionHour,
      departments: PreviousselectedUserss,
      sla_resolution_time: slaResolutionTime,
      is_doc_upload_required: editDocum,
      status: true,
      process_id: editPrcsID,
      user_ids: selectedUsers,
      is_automated: 1,
    };

    console.log(payload, "payloads");

    try {
      const response = await URL.put(`/api/task/${DefaultGet}`, payload, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Task Updated",
          text: response.data.message || "Task updated successfully.",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "rgb(255, 165, 0)",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        seteditSLA();
        setisSLAResolutionHour();
        handleEditCloseTask();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating task:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unable to update task.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const handleUserSelection = (user, isChecked) => {
    if (isChecked) {
      // Add only if not already present
      setPreviousselectedUserss((prev) => {
        const exists = prev.some((dep) => dep.user_id === user.user_id);
        if (!exists) {
          return [
            ...prev,
            {
              user_id: user.user_id,
              dept_id: user.dept_id,
              dept_role_id: user.dept_role_id,
            },
          ];
        }
        return prev;
      });
    } else {
      // Remove user from payloadDepartments
      setPreviousselectedUserss((prev) =>
        prev.filter((dep) => dep.user_id !== user.user_id)
      );
    }
  };

  console.log(PreviousselectedUserss, "prsselected");

  return (
    <div>
      <Container className="task-form-container">
        <h2 className="text-center mb-4">Task</h2>
        <Form>
          <Row>
            {/* Process Name Dropdown */}
            <Col md={6} style={{ padding: "10px" }}>
              <Form.Group controlId="process_id">
                <Form.Label>Process Name</Form.Label>
                <Form.Select
                  name="process_id"
                  value={editPrcsID}
                  onChange={(e) => {
                    const ds2 = e.target.value;
                    SettingTaskOrderHandler(ds2);
                    seteditPrcsID(ds2);
                  }}
                  required
                  // disabled={loading}
                >
                  <option value="">Select Process</option>
                  {processOptions.map((process) => (
                    <option key={process.key} value={process.value}>
                      {process.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            {/* // Department Multi-select */}
            <Col md={6} style={{ padding: "10px" }}>
              <Form.Group controlId="dept_id">
                <Form.Label>Department</Form.Label>
                <Select
                  isMulti
                  name="dept_id"
                  value={departmentOptions.filter((dept) =>
                    editDepID.some((item) => item.dept_id === dept.value)
                  )}
                  options={departmentOptions.map((dept) => ({
                    value: dept.value,
                    label: dept.label,
                  }))}
                  onChange={(selected) => {
                    // If nothing is selected, handle empty state
                    if (!selected || selected.length === 0) {
                      seteditDepID([]);
                      setRoleOptions([]);
                      fetchUsers([]); // Send empty array if no dept selected
                      return;
                    }

                    // Build your dept object list
                    const newEditDeptID = selected.map((opt) => {
                      const deptObj = departmentOptions.find(
                        (d) => d.value === opt.value
                      );
                      return {
                        dept_id: deptObj.value,
                        dept_name: deptObj.label,
                      };
                    });

                    // Update department state
                    seteditDepID(newEditDeptID);

                    // Extract dept IDs only for API call
                    const deptIds = newEditDeptID.map((d) => d.dept_id);
                    fetchUsers(deptIds); // ✅ sends array of IDs like [9, 10]

                    // Populate role options from selected departments
                    const roles = departmentOptions
                      .filter((dept) => deptIds.includes(dept.value))
                      .flatMap((dept) => dept.roles);

                    setRoleOptions(roles || []);
                  }}
                  placeholder="Select Department"
                />
              </Form.Group>
            </Col>
            <Col md={8} style={{ padding: "10px" }}></Col>
            {/* Department Role Multi-select */}
            <Col md={4} style={{ padding: "10px" }}>
              <Form.Group controlId="dept_role_id">
                {/* <Form.Label>Department Role</Form.Label> */}
                <Select
                  isMulti
                  name="dept_role_id"
                  value={roleOptions.filter((role) =>
                    editDeptRoleID.some(
                      (item) => item.dept_role_id == role.value
                    )
                  )}
                  options={roleOptions}
                  onChange={(selected) => {
                    const selectedObjects = selected.map((sel) => ({
                      dept_role_id: sel.value,
                    }));

                    seteditDeptRoleID(selectedObjects);

                    // Extract dept IDs from your state (editDepID)
                    const deptIds = editDepID.map((d) => d.dept_id);

                    // ✅ Pass both role objects & department ids
                    FilteringBydeptRoles(selectedObjects, deptIds);
                  }}
                  placeholder=" Department Role"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: "none",
                      borderBottom: "2px solid gray",
                    }),
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={12} style={{ padding: "8px" }}>
              {FilteruserDetails.length ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>
                        {/* <Form.Check
                          type="checkbox"
                          checked={selectedUsers.length === userDetails.length} // Ensure all users are selected
                          onChange={handleSelectAll}
                        /> */}
                      </th>
                      <th>User ID</th>
                      <th>Name</th>
                      <th>Handling States</th>
                      <th>Department</th>
                      <th>Department Role</th>
                      <th>Contact Number</th>
                      <th>Email</th>
                      <th>Assigned Tasks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {FilteruserDetails.map((user) => (
                      <tr key={user.user_id}>
                        <td>
                          <Form.Check
                            type="checkbox"
                            checked={PreviousselectedUserss.some(
                              (dep) => dep.user_id === user.user_id
                            )} // Check if this user is selected
                            onChange={(e) =>
                              handleUserSelection(user, e.target.checked)
                            }
                          />
                        </td>
                        <td>{user.user_id} </td>
                        <td>{user.name} </td>
                        <td>
                          <span> {
                            user.department_name.toLowerCase() !== "mikro sales" ? "Centralized" :
                             <span>
                          {user?.state?.map((c) => c.name).join(", ") ||
                            "No State"}{" "}
                             </span> }
                            </span>
                        </td>
                        <td>{user.department_name}</td>
                        <td>{user.department_role_name}</td>
                        <td>{user.contact_number}</td>
                        <td>{user.email}</td>
                        <td>{user?.total_task}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th colSpan="5" style={{ textAlign: "center" }}>
                        No Users Assigned
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        colSpan="5"
                        style={{ textAlign: "center", color: "red" }}>
                        There is no user assigned to this department and its
                        role. Please assign users.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </Col>
            {/* Task Name */}
            <Col md={6} style={{ padding: "10px" }}>
              <Form.Group controlId="task_name">
                <Form.Label>Task Name</Form.Label>
                <Form.Control
                  type="text"
                  name="task_name"
                  value={editTaskName}
                  placeholder="Task Name"
                  onChange={(e) => seteditTaskName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            {/* SLA Resolution Time */}
            <Col md={6} style={{ padding: "10px" }}>
              <Form.Group controlId="is_resolution_hour">
                <Form.Label>Resolution Type</Form.Label>
                <Form.Select
                  value={isSLAResolutionHour}
                  // onChange={(e) => {
                  //   console.log("iS hour:", e.target.value); // Check the value being set
                  //   setisSLAResolutionHour(e.target.value);
                  // }}
                  onChange={handleResolutionChange}
                  aria-label="Select an option">
                  <option value="">Choose...</option>
                  <option value={0}>Day</option>
                  <option value={1}>Hour</option>
                </Form.Select>
              </Form.Group>
            </Col>
            {/* If Day is selected */}
            {(isSLAResolutionHour === 0 || isSLAResolutionHour === "0") && (
              <Col md={6} style={{ padding: "10px" }}>
                <Form.Group controlId="sla_resolution_time">
                  <Form.Label>SLA Resolution Time (Days)</Form.Label>
                  <Form.Control
                    type="number"
                    name="sla_resolution_time"
                    value={editSLA}
                    onChange={(e) => seteditSLA(e.target.value)}
                    min="1"
                  />
                </Form.Group>
              </Col>
            )}
            {/* If Hour is selected */}
            {(isSLAResolutionHour === 1 || isSLAResolutionHour === "1") && (
              <>
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="hours">
                    <Form.Label>SLA Resolution Time (Hours)</Form.Label>

                    <Form.Control
                      name="hours"
                      value={Thours}
                      onChange={(e) => {
                        setHours(e.target.value);
                      }}
                      min="1"
                    />
                  </Form.Group>
                </Col>

                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="minutes">
                    <Form.Label>SLA Resolution Time (Minutes)</Form.Label>

                    <Form.Select
                      style={{ height: "36.5px" }}
                      name="minutes"
                      value={Tminutes}
                      onChange={(e) => setMinutes(e.target.value)}
                      aria-label="Select an option">
                      <option value="">Choose...</option>
                      <option value="00">00</option>
                      <option value="15">15</option>
                      <option value="30">30</option>
                      <option value="45">45</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </>
            )}
            {/* Task Flow Order */}
            <Col md={6} style={{ padding: "10px" }}>
              <Form.Group controlId="task_flow_order">
                <Form.Label>Task Flow Order</Form.Label>
                <Form.Control
                  disabled
                  type="number"
                  name="task_flow_order"
                  value={editFloOrder}
                  onChange={(e) => seteditFloOrder(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            {/* Is Document Required Checkbox */}
            <Col md={6} style={{ padding: "50px 10px" }}>
              <Form.Group controlId="is_doc_upload_required">
                <Form.Check
                  type="checkbox"
                  label="Is Document Required"
                  name="is_doc_upload_required"
                  checked={editDocum}
                  onChange={(e) => seteditDocum(e.target.checked)}
                />
              </Form.Group>{" "}
            </Col>{" "}
            {/* Task Description */}{" "}
            <Col md={12} style={{ padding: "10px" }}>
              {" "}
              <div className="def_textarea">
                <Form.Group controlId="task_description">
                  {" "}
                  <Form.Label>Task Description</Form.Label>{" "}
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="task_description"
                    value={editTaskDesc}
                    onChange={(e) => seteditTaskDesc(e.target.value)}
                    required
                  />{" "}
                </Form.Group>{" "}
              </div>
            </Col>{" "}
            <div className="form-actions mb-4">
              <Button
                variant="outline-light"
                type="reset"
                className="cancel-btn"
                onClick={handleEditCloseTask}>
                Close
              </Button>
              <Button
                variant="outline-light"
                // type="submit"
                className="create-btn"
                onClick={(e) => handleSubmit(e)}>
                Update Task
              </Button>
            </div>
          </Row>{" "}
        </Form>
      </Container>
    </div>
  );
};

export default EditUpdateModal;
