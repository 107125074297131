import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FcLeft } from "react-icons/fc";

export const SubTaskDetail = ({
  SubTaskModalCloseHandler,
  selectedNotification,
  moreInfo,SliderChange
}) => {

  console.log(moreInfo,"master")
  // console.log(moreInfo,"moreInfomoreInfo")
  return (
    <div
      style={{
        paddingLeft: "5px",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}>
      <h6
        style={{
          textAlign: "center",
          marginTop: "20px",
          fontWeight: "bolder",
          color: "rgb(255, 152, 0)",
        }}>
        Detail
      </h6>
      <Row style={{ marginTop: "20px" }}>
        {/* Process Name */}
        <Col lg={12} style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "flex-start",
              flexWrap:'wrap',
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "8px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
              width: "100%",
              marginBottom: "5px",
            }}>
            <span
              style={{
                fontWeight: "bold",
                color: "rgb(255, 152, 0)",
                fontSize: "1rem",
                marginRight: "10px",
              }}>
              <b>Process Name:</b>
            </span>
            <span
              style={{ fontSize: "1rem", color: "#333", fontWeight: "normal" }}>
              {moreInfo?.process?.process_name}
            </span>{" "}
            {/* Replace with dynamic value */}
          </div>
        </Col>

        {/* Task Name */}
        <Col lg={12} style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "flex-start",
              flexWrap:'wrap',
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "8px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
              width: "100%",
              marginBottom: "5px",
            }}>
            <span
              style={{
                fontWeight: "bold",
                color: "rgb(255, 152, 0)",
                fontSize: "1rem",
                marginRight: "10px",
              }}>
              <b>Task:</b>
            </span>
            <span
              style={{ fontSize: "1rem", color: "#333", fontWeight: "normal" }}>
               {moreInfo?.task ?moreInfo?.task?.task_name : moreInfo?.subtask?.task_name}

            </span>{" "}
            {/* Replace with dynamic value */}
          </div>
        </Col>

        <Col lg={12} style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "flex-start",
              flexWrap:'wrap',
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "8px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
              width: "100%",
              marginBottom: "5px",
            }}>
            <span
              style={{
                fontWeight: "bold",
                color: "rgb(255, 152, 0)",
                fontSize: "1rem",
                marginRight: "10px",
              }}>
              <b>Property :</b>
            </span>
            <span
             style={{ textDecoration:'underline', cursor:'pointer', color:'#e88a40' ,textTransform:'capitalize' }} onClick={SliderChange} >
              {moreInfo?.property_details?.property_name}
               
            </span>{" "}
            
          </div>
        </Col>

        {/* Department */}
        <Col lg={12} style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "flex-start",
              flexWrap:'wrap',
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "8px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
              width: "100%",
              marginBottom: "5px",
            }}>
            <span
              style={{
                fontWeight: "bold",
                color: "rgb(255, 152, 0)",
                fontSize: "1rem",
                marginRight: "10px",
              }}>
              <b>Department:</b>
            </span>
            <span
              style={{ fontSize: "1rem", color: "#333", fontWeight: "normal" }}>
              {moreInfo?.department?.department_name}
               
            </span>{" "}
            
          </div>
        </Col>
 

        {/* SLA Resolution Time */}
        <Col lg={12} style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "flex-start",
              flexWrap:'wrap',
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "8px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
              width: "100%",
              marginBottom: "5px",
            }}>
            <span
              style={{
                fontWeight: "bold",
                color: "rgb(255, 152, 0)",
                fontSize: "1rem",
                marginRight: "10px",
              }}>
              <b>SLA Resolution Time:</b>
            </span>
            <span
              style={{ fontSize: "1rem", color: "#333", fontWeight: "normal" }}>
                {(moreInfo?.task_config_detail_responses?.status!==4 && moreInfo?.task_config_detail_responses?.status!==5 && moreInfo?.task_config_detail_responses?.status!==6 )&& moreInfo?.task  &&   moreInfo?.task?.is_resolution_hour==1 &&   <>{moreInfo.task?.sla_resolution_time} hours </>   }
                {(moreInfo?.task_config_detail_responses?.status!==4 && moreInfo?.task_config_detail_responses?.status!==5 && moreInfo?.task_config_detail_responses?.status!==6 ) && moreInfo?.task  &&   moreInfo?.task?.is_resolution_hour==0 &&   <>{moreInfo.task?.sla_resolution_time} {moreInfo?.task?.sla_resolution_time<2 
                           ? "Day" : "Days"}   </>   }
                { (moreInfo?.task_config_detail_responses?.status!==4 && moreInfo?.task_config_detail_responses?.status!==5 && moreInfo?.task_config_detail_responses?.status!==6 ) && moreInfo?.subtask  &&   moreInfo?.subtask?.is_resolution_hour==1 &&   <>{moreInfo.subtask?.sla_resolution_time} hours </>   }
                { (moreInfo?.task_config_detail_responses?.status!==4 && moreInfo?.task_config_detail_responses?.status!==5 && moreInfo?.task_config_detail_responses?.status!==6 ) && moreInfo?.subtask  &&   moreInfo?.subtask?.is_resolution_hour==0 &&   <>{moreInfo.subtask?.sla_resolution_time}  {moreInfo?.subtask?.sla_resolution_time<2 
                           ? "Day" : "Days"}   </>   } 
                {(moreInfo?.task_config_detail_responses?.status==4 || moreInfo?.task_config_detail_responses?.status==5 || moreInfo?.task_config_detail_responses?.status==6  )
                        && moreInfo  &&  
                         moreInfo?.task_config_detail_responses?.is_resolution_request_hour==0
                          &&  
                           <>{moreInfo?.task_config_detail_responses?.sla_resolution_request_time} 
                           {moreInfo?.task_config_detail_responses?.sla_resolution_request_time<2 
                           ? "Day" : "Days"}  
                           </> 
                             }


            </span>{" "} 
          </div>
        </Col>

        {/* Task Description */}
        <Col lg={12} style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "flex-start",
              flexWrap:'wrap',
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "8px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
              width: "100%",
              marginBottom: "5px",
            }}>
            <span
              style={{
                fontWeight: "bold",
                color: "rgb(255, 152, 0)",
                fontSize: "1rem",
                marginRight: "10px",
              }}>
            {moreInfo?.task_config_detail_responses.status===4 &&<b> Task Remarks:</b> }  
            {moreInfo?.task_config_detail_responses.status===5 && <b>Task Remarks:</b> }  

            {moreInfo?.task_config_detail_responses.status===6  && <b>Task Remarks:</b> }  
            {moreInfo?.task_config_detail_responses.status===2  && <b>Task Description:</b> }  
            {moreInfo?.task_config_detail_responses.status===1  && <b>Task Remarks:</b> }  
            {moreInfo?.task_config_detail_responses.status===0  && <b>Task Description:</b> }  
            {moreInfo?.task_config_detail_responses.status===3  && <b>Task Remarks::</b> }  
         
             
            </span>
            <span
              style={{ fontSize: "1rem", color: "#333", fontWeight: "normal" }}>
                           
            {moreInfo?.task_config_detail_responses.status===4 &&<span> {moreInfo?.task_config_detail_responses?.remarks}</span> }  
            {moreInfo?.task_config_detail_responses.status===5 && <span>{moreInfo?.task_config_detail_responses?.remarks}</span> }  

            {moreInfo?.task_config_detail_responses.status===6  && <span>{moreInfo?.task_config_detail_responses?.remarks}</span> }  
            {moreInfo?.task_config_detail_responses.status===2  && <span> {moreInfo?.subtask?.task_description} </span> }  
            {moreInfo?.task_config_detail_responses.status===1  && <span>{moreInfo?.task_config_detail_responses?.remarks}</span> }  
            {moreInfo?.task_config_detail_responses.status===0  && <span> {moreInfo?.task?.task_description} </span> }  
            {moreInfo?.task_config_detail_responses.status===3  && <span>{moreInfo?.task_config_detail_responses?.remarks}:</span> }  
         
            </span>{" "}
            {/* Replace with dynamic value */}
          </div>
        </Col>
        {/* uploaded_document */}

        {moreInfo?.task_config_detail_responses?.uploaded_document !==
                                  null && (
        <Col lg={12} style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "flex-start",
              flexWrap:'wrap',
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "8px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
              width: "100%",
              marginBottom: "5px",
            }}>
            <span
              style={{
                fontWeight: "bold",
                color: "rgb(255, 152, 0)",
                fontSize: "1rem",
                marginRight: "10px",
              }}>
              <b>Document:</b>
            </span>
            <span
              style={{ fontSize: "1rem", color: "#333", fontWeight: "normal" }}>
             <img
                                    src={
                                      process.env.REACT_APP_IMAGE_URL +
                                      moreInfo?.task_config_detail_responses?.uploaded_document
                                    }
                                    alt=""
                                    style={{
                                      width: "100px",
                                      height: "50px",
                                      objectFit: "cover",
                                    }}
                                  />
               
            </span>{" "}
            {/* Replace with dynamic value */}
          </div>
        </Col> )}

        <Col lg={12} style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "flex-start",
              flexWrap:'wrap',
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "8px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
              width: "100%",
              marginBottom: "5px",
            }}>
            <span
              style={{
                fontWeight: "bold",
                color: "rgb(255, 152, 0)",
                fontSize: "1rem",
                marginRight: "10px",
              }}>
              

            {moreInfo?.task_config_detail_responses.status===4 &&<b>Reschedule Request From:</b> }  
            {moreInfo?.task_config_detail_responses.status===5 && <b>Reschedule Accept From:</b> }  
            {moreInfo?.task_config_detail_responses.status===6 && <b>Reschedule Cancelled From:</b> } 
            {moreInfo?.task_config_detail_responses.status===2  && <b>Assigned From:</b> }  
            {moreInfo?.task_config_detail_responses.status===1  && <b>Completed From:</b> }  
            {moreInfo?.task_config_detail_responses.status===0  && <b>Assigned From:</b> }  
            {moreInfo?.task_config_detail_responses.status===3  && <b>Reject From:</b> }  


            </span>
            <span
              style={{ fontSize: "1rem", color: "#333", fontWeight: "normal" }}>
                            {moreInfo?.user_assigned_by ?moreInfo?.user_assigned_by?.name : "-"}

            </span>{" "} 
          </div>
        </Col>

        <Col lg={12} style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "flex-start",
              flexWrap:'wrap',
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "8px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
              width: "100%",
              marginBottom: "5px",
            }}>
            <span
              style={{
                fontWeight: "bold",
                color: "rgb(255, 152, 0)",
                fontSize: "1rem",
                marginRight: "10px",
              }}>
            {moreInfo?.task_config_detail_responses.status===4 &&<b> Reschedule Request To:</b> }  
            {moreInfo?.task_config_detail_responses.status===5 && <b>Reschedule Accept For:</b> }  

            {moreInfo?.task_config_detail_responses.status===6  && <b>Reschedule Reject For:</b> }  
            {moreInfo?.task_config_detail_responses.status===2  && <b>Dependency For:</b> }  
            {moreInfo?.task_config_detail_responses.status===1  && <b>Completed For:</b> }  
            {moreInfo?.task_config_detail_responses.status===0  && <b>Assigned For:</b> }  
            {moreInfo?.task_config_detail_responses.status===3  && <b>Reject For:</b> }  
            </span>
            <span
              style={{ fontSize: "1rem", color: "#333", fontWeight: "normal" }}>
                            {moreInfo?.user_assigned_to ?moreInfo?.user_assigned_to?.name : "-"}

            </span>{" "}
            {/* Replace with dynamic value */}
          </div>
        </Col>
      </Row>

      {/* Close Button */}
      <Row style={{ textAlign: "center", marginTop: "20px" }}>
        <Col>
          <Button
            style={{
              padding: "10px 30px",
              marginBottom:"20px",
              fontSize: "1rem",
              borderRadius: "5px",
              outline: "none",
              border: "none",
              cursor: "pointer",
              color: "white",
              background: "linear-gradient(45deg, #ffcc00, #ff9933)",
              boxShadow:
                "inset 0 4px 6px rgba(255, 224, 47, 0.5), 0 6px 12px rgba(0, 0, 0, 0.2)",
              transition:
                "background-color 0.3s ease, box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out",
            }}
            onClick={SubTaskModalCloseHandler}>
              <FcLeft style={{ color:'#fff' }} size={25} />
          </Button>
        </Col>
      </Row>
    </div>
  );
};
