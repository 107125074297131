import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  Table,
  Pagination,
} from "react-bootstrap";
import API from "../../components/API/ServiceLayer";
import { useLocation, useParams } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";

import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";

import { FaTrash } from "react-icons/fa";
import Select from "react-select";

const EditOrganizationStaff = (props) => {
  const { id } = useParams();
  const [date, setDate] = useState();
  const [cityValue, setCityvalue] = useState([]);
  const [stateValue, setStatevalue] = useState([]);
  const [stateId, setStateId] = useState();
  const [cityId, setCityId] = useState();
  const [isPropertySelected, setIsPropertySelected] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [NEwProperties, setNEwProperties] = useState([]);
  const [propertiesList, setPropertiesList] = useState([]);
  const [PropStateName, setPropStateName] = useState([]);
  const [PropCityName, setPropCityName] = useState([]);
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [foundObjects, setFoundObjects] = useState([]);
  const [editStaffData, setEditStaffData] = useState({
    canUpdate: false,
    isPasswordSame: true,
    loader: false,
    isPasswordValid: true,
    propties: [],
    propertiesList,

    mobileNo: "",
    postal_code: "",
    org_dept_data: "",
  });
  const [show, setShow] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState(null);
  const [propertyId, setPropertyId] = useState();

  const [selectedStatesProp, setSelectedStatesProp] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [propertyValue, setPropertyValue] = useState([]);
  const [testState, setTestState] = useState([]);
  const [testPropcity, setTestPropcity] = useState([]);
  const [combinedStates, setCombinedStates] = useState([]);
  const [Mystatecity, setMystatecity] = useState([]);
  const [passwordIsTypePassword, setPasswordIsTypePassword] = useState(true);
  const [cPasswordIsTypePassword, setcPasswordIsTypePassword] = useState(true);
  const [toastObject, setToastObject] = useState({
    message: "",
    duration: 3000,
    variant: "",
  });
  const [formData, setFormData] = useState({
    country_id: 101,
    state_id: "",
    city_id: "",
  });

  const [isPropertyListed, setIsPropertyListed] = useState(false);

  const [snackOpen, setSnackOpen] = useState(false);
  const [listServiceDeps, setListServiceDeps] = useState([]);
  const location = useLocation();

  const [errors, setErrors] = useState({});
  const Departmentref = useRef();
  const DepartmentroleRef = useRef();
  const NameRef = useRef();
  const contactNumberRef = useRef();
  const emailRef = useRef();
  const AddressoneRef = useRef();
  const countryRef = useRef();
  const stateRef = useRef();
  const cityRef = useRef();
  const postalcodeRef = useRef();
  const propcityRef = useRef();
  const propstateRef = useRef();

  //OrganizationDepartments array for form input
  const serviceDep = listServiceDeps.map((i) => {
    return {
      id: i.id,
      name: i.department_name,
      code: i.department_code,
      dep_roles: i.organization_role_department,
    };
  });
  //console.log(editStaffData?.org_dept_data?.length, "length");
  //OrganizationDepartmentRoles array for form input
  const depRoles =
    editStaffData.depName &&
    serviceDep.find((i) => i.id === Number(editStaffData.depName));
  //console.log(editStaffData, "edit");

  //call api when choose state
  useEffect(() => {
    if (formData.country_id) {
      API.getData("user-management/api/v1/state/" + formData.country_id)
        .then((res) => res.json())
        .then((result) => {
          // console.log("result?.data", result?.data);
          setStateList(result?.data);

          const validKeys = ["value", "label"];

          let statesData = [];
          const states = result?.data?.forEach((ele) => {
            ele.label = ele.name;
            ele.value = ele.id;

            const {
              country_code,
              country_id,
              created_at,
              deleted_at,
              fips_code,
              flag,
              id,
              iso2,
              latitude,
              longitude,
              name,
              status,
              type,
              updated_at,
              wikiDataId,
              ...eleRest
            } = ele;

            statesData.push(eleRest);
          });

          setStateOptions(statesData);
        });
    }
  }, [formData.country_id]);

  useEffect(() => {
    const combined = [...selectedStatesProp, ...testState];
    setCombinedStates(combined);
  }, [selectedStatesProp, testState]);

  // setMystatecity(citiesData);

  //initial setup
  useEffect(() => {
    // console.log(props, "props");
    // let id = props.staffId;

    let month = new Date().getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let dd = new Date().getDate();
    dd = dd < 10 ? "0" + dd : dd;
    let date = new Date().getFullYear() - 18 + "-" + month + "-" + dd;
    setDate(date);
    API.getData("user-management/api/v1/user/" + id)
      .then((res) => res.json())
      .then((json) => {
        console.log("API-SHOW_METHOD", json);
        // this.setState({ memberData: json.data });
        let dob = json.data.dob ? json.data.dob.slice(0, 10) : "";
        // step-2->get countries
        API.getData("api/country?limit=300")
          .then((countryRes) => countryRes.json())
          // this.setState({ countries: countryResult?.data?.data });
          .then((countryResult) => {
            // this.setState({ countries: countryResult?.data?.data });
            console.log("API-COUNTRY_LIST", countryResult);
            //step-3->get states list against user country
            let data = {
              country_id: json.data.country_id,
            };

            API.getData(`user-management/api/v1/state/${data?.country_id}`)
              // console
              //   .log(data?.country_id, "data?.country_id")
              .then((stateRes) => {
                console.log(stateRes, "lgp");
                return stateRes.json();
              })
              .then((stateResult) => {
                if (stateResult?.status === true) {
                  let stateData = {
                    state_id: json.data.state_id,
                  };
                  console.log("API-STATE_LIST", stateData.state_id);

                  API.getData(
                    `user-management/api/v1/city/${stateData?.state_id}`
                  )
                    .then((cityRes) => {
                      return cityRes.json();
                    })
                    .then((cityResult) => {
                      if (cityResult?.status === true) {
                        // this.setState({ citiesData: cityResult?.data?.data });
                        console.log("API-CITY_LIST");
                        const org_dep = json.data.user_org_depts.find(
                          (i) => i.is_default === 1
                        );
                        console.log("appdatas", json.data);
                        setEditStaffData({
                          ...editStaffData,
                          name: json.data.name,
                          mobileNo: json.data.mobile_no,
                          dob: dob,
                          email: json.data.email,
                          address1: json.data.address1,
                          address2: json.data.address2,
                          isCatHead: json.data.cat_head,

                          propties: json.data.property_details,

                          org_dept_data: json.data.user_org_depts?.map((i) => {
                            return {
                              id: i.id,
                              depName:
                                i.organization_department?.department_name,
                              dep_id: i.org_dept_id,
                              depRoleName:
                                i.organization_department_role?.dep_role_name,
                              dep_role_id: i.org_dept_role_id,
                              is_default: i.is_default,
                            };
                          }),

                          isCatLead: json.data.cat_lead,
                          postal_code: json.data.postal_code,
                          onBoardingMode: json.data.on_boarding_mode,
                          contactNumber: json.data.mobile_no,
                          contactPerson: json.data.name,
                          status: json.data.status,
                          isVerified: 1,
                          isDocumentVerified: 1,
                          password: "", //json.data.password
                          password_confirmation: "", // json.data.password_confirmation
                          userCode: json.data.user_code,
                          roleId: json.data.role_id,
                          latitude: json.data.latitude,
                          longitude: json.data.longitude,
                          country_id: json.data.country_id,
                          state_id: json.data.state_id,
                          city_id: json.data.city_id,
                          company_name: json.data.company_name,

                          // property_details:json?.data.property_details,
                          propertiesList: json?.data.property_details,

                          // cityValue:json?.data?.property_details?.map((citiee)=>citiee.city_id),
                          // stateValue:json?.data?.property_details?.map((staate)=>staate.state_id),
                          //countylist
                          countries: countryResult?.data?.data,
                          statesData: stateResult?.data,
                          citiesData: cityResult?.data,
                          depName: org_dep?.org_dept_id,
                          depRole: org_dep?.org_dept_role_id,
                          loader: true,
                        });
                        setPropStateName(
                          json?.data.property_details.map(
                            (prps) => prps.state_name
                          )
                        );
                        setPropCityName(
                          json?.data.property_details.map(
                            (prps) => prps.city_name
                          )
                        );
                        // getAllPropFunction();

                        //  console.log(PropStateName, PropCityName, "my_cccb");
                        // console.log(
                        //   editStaffData.propertiesList,
                        //   "propertiesList"
                        // );

                        // console.log(
                        //   json?.data.property_details.map(
                        //     (prts) => prts.state_id
                        //   )
                        // );
                        // console.log(
                        //   json?.data.property_details.map(
                        //     (prts) => prts.city_id
                        //   )
                        // );
                        // handleStateChange(mynewPropertieState)
                        // const updatedPropertiesList = json?.data.property_details?.map((lists) => lists.propert_name);
                        // console.log('Updated Properties List:', updatedPropertiesList);
                        // console.log(mynewPropertieState,"selectedStatesFromOptions")

                        // console.log(
                        //   json?.data?.property_details.map((rs) => rs),
                        //   "ffrrrr"
                        // );

                        // const myProperty=json?.data?.property_details.map((rs)=>rs.property_name,rs.id);
                        const myProperty = json?.data?.property_details.map(
                          (rs) => rs.property_name
                        );
                        const myPropertyid = json?.data?.property_details.map(
                          (rs) => rs.id
                        );

                        let propertiesDatafromAPI = [];
                        let data = json?.data?.property_details.forEach(
                          (ele) => {
                            ele.label = ele.property_name;
                            ele.value = ele.id;
                            const {
                              country_code,
                              country_id,
                              created_at,
                              deleted_at,
                              address1,
                              address2,
                              id,
                              iso2,
                              latitude,
                              longitude,
                              name,
                              status,
                              type,
                              updated_at,
                              wikiDataId,
                              cctv_link,
                              cgst,
                              city_id,
                              contact_alternate_email,
                              contact_email,
                              contact_no,
                              contact_person,

                              email,
                              end_at,
                              floor_file,
                              floor_no,
                              furnished_status,
                              gst_registration_num,
                              invoice_template_id,
                              is_alternative_primary,
                              is_cancel,
                              is_cancellation_policy,
                              is_draft,
                              is_primary,
                              is_qr_code,
                              is_verified,
                              landline,
                              landmark,
                              locality,
                              long_desc,
                              mobile_no,
                              property_code,
                              property_tabs,
                              property_type_id,
                              provider_id,
                              pseudoname,
                              qr_code_path,
                              service_location_id,
                              sgst,
                              short_desc,
                              show_actual_name,
                              skip_plans,
                              sort_id,
                              start_at,
                              state_id,
                              total_sq_ft,
                              website,
                              zipcode,
                              property_name,

                              ...eleRest
                            } = ele;
                            propertiesDatafromAPI.push(eleRest);
                            console.log("result_pros", eleRest);
                          }
                        );

                        // setNEwProperties(myPropertyid);
                        setNEwProperties(propertiesDatafromAPI);

                        // handlePropertyNames(mynewProperties)
                        // prope

                        // console.log(NEwProperties, "NEwProperties");

                        // console.log(cityResult?.data, "sdaasdasd");

                        // console.log("laksh", "states", stateResult?.data);
                        // console.log("laksh", "cities", cityResult?.data);
                      }
                    })
                    .catch((cityErr) => {
                      //city listing error
                      // console.log("API-CITY_LIST", cityErr);
                      setEditStaffData({ ...editStaffData, loader: true });
                    });
                }
              })
              .catch((stateErr) => {
                //state listing error
                // console.log("API-STATE_LIST", stateErr);
                setEditStaffData({ ...editStaffData, loader: true });
              });
          })
          .catch((countryErr) => {
            //country listing error
            //  console.log("API-COUNTRY_LIST", countryErr);
            setEditStaffData({ ...editStaffData, loader: true });
          });
      })
      .catch((showErr) => {
        // console.log("API-SHOW_METHOD", showErr);
        setEditStaffData({ ...editStaffData, loader: true });
      });

    API.getData(`user-management/api/v1/orgdept`)
      .then((res) => res.json())
      .then((result) => {
        //  console.log("resswsw", result);
        if (result.status) {
          setListServiceDeps(
            result.data.filter((stat) => {
              if (stat.status !== 2) {
                return stat;
              }
            })
          );
        }
      });
  }, []);

  // useEffect (()=>{
  //   const mynewPropertieState=editStaffData.propertiesList.map((prts)=>prts.state_id);
  //   const selectedStatesFromOptions = mynewPropertieState.map((state) => stateOptions.filter(s => s.value === state));

  //   setTestState(...selectedStatesFromOptions)
  //   console.log(selectedStatesFromOptions,"RRRR")

  //   getAllPropFunction();
  // },[ stateOptions,editStaffData])

  useEffect(() => {
    const mynewPropertieState = editStaffData.propertiesList.map(
      (prts) => prts.state_id
    );
    const mynewPropertieCity = editStaffData.propertiesList.map(
      (prts) => prts.city_id
    );
    // console.log(editStaffData, "editStaffData");

    // console.log(mynewPropertieCity, "mynewPropertieCity");
    const cityyFdata = mynewPropertieCity.map((d) => d);
    //  console.log(cityyFdata, "cityFdaya");

    setCityvalue(mynewPropertieCity);
    setCityId(mynewPropertieCity);
    // console.log(mynewPropertieState, "mynewPropertieState");
    API.getData("user-management/api/v1/city/" + mynewPropertieState)
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setMystatecity(result?.data);
        //  console.log(result?.data, "mycitis");
      });

    const selectedStatesFromOptions = mynewPropertieState.map((state) =>
      stateOptions.find((s) => s.value === state)
    );
    // const selectedCitiesFromOptions = mynewPropertieCity.map((city) => Mystatecity.find(s => s.value === city));

    const selectedCitiesFromOptions = cityyFdata.map((cityId) =>
      Mystatecity.find((city) => city.id === cityId)
    );

    // const foundLocations = cityyFdata.map(id => Mystatecity.find(location => location.id === id));

    const foundLocations = cityyFdata.map((id) =>
      Mystatecity.find((location) => location.id === id)
    );

    const validLocations = foundLocations.filter(
      (location) => location !== undefined
    );

    if (validLocations.length === 0) {
      // console.log("No matching locations found.");
    } else {
      //  console.log(validLocations, "validLocations");
    }

    // console.log(selectedCitiesFromOptions, "yyycity");
    //  console.log(citiesOptions, filteredCityOptions);

    // Now `selectedStatesFromOptions` is an array of objects with the label and value properties.
    setTestState(selectedStatesFromOptions);

    setPropCityName(selectedCitiesFromOptions);

    // console.log(selectedStatesFromOptions, "selectedStatesFromOptions");
    // setTestPropcity(selectedCitiesFromOptions);

    // Ensure getAllPropFunction() should be called after setting the state.
    getAllPropFunction();
  }, [stateOptions, editStaffData]);

  // useEffect(()=>{
  //   const mynewPropertieState = editStaffData.propertiesList.map((prts) => prts.state_id);
  //   const selectedStatesFromOptions = mynewPropertieState.map((state) => stateOptions.find(s => s.value === state));

  // },[stateOptions,editStaffData])

  //   console.log(cityValue, "mycityvalue");
  //   console.log(Mystatecity, "Mystatecity");

  useEffect(() => {
    const checkObjects = () => {
      const foundObjs = cityValue
        .map((id) => Mystatecity.find((item) => item.id === id))
        .filter(Boolean);
      const transformedObjects = foundObjs.map((obj) => ({
        value: obj.id,
        label: obj.name,
      }));

      setFoundObjects(transformedObjects);
    };
    checkObjects();
  }, [cityValue, Mystatecity]);

  const validateForm = () => {
    const newErrors = {};

    // Example validation rules
    if (!editStaffData.name) {
      newErrors.name = "Username is required";
    }
    if (!editStaffData.email) {
      newErrors.email = "Email is required";
    }
    if (!editStaffData.mobileNo) {
      newErrors.mobile_number = "Enter Mobile Number";
    }

    if (
      editStaffData.mobileNo.length > 0 &&
      editStaffData.mobileNo.length !== 10
    ) {
      newErrors.mobile_number1 = "Enter 10 digits";
    }
    if (!editStaffData.address1) {
      newErrors.address1 = "Enter Addresss";
    }
    if (!editStaffData.country_id) {
      newErrors.country_id = "Choose country";
    }
    if (!editStaffData.postal_code) {
      newErrors.postal_code = "Enter Postal code";
    }

    if (
      editStaffData.postal_code.length > 0 &&
      editStaffData.postal_code.length !== 6
    ) {
      newErrors.postal_code1 = "Enter 6 digits";
    }

    if (editStaffData.org_dept_data.length < 1) {
      newErrors.org_dept_data = "Choose Atleast One Department";
    }
    // if (!formData.dep_role_id) {
    //   newErrors.dep_role_id = 'Choose Department Role';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // console.log(foundObjects, "foundObjects_tt");

  const getAllPropFunction = () => {
    // console.log(editStaffData.propties, "myffff");

    const MyPropertyStates =
      editStaffData.propties &&
      editStaffData.propties.map((prp) => prp.state_id);
    const selectedStatesFromOptions = stateOptions.filter((state) =>
      MyPropertyStates.includes(state.value)
    );
    // console.log(selectedStatesFromOptions, "selectedStatesFromOptions");
    // const Mynewstate = selectedStatesFromOptions.map((yes)=>yes.label);
    if (selectedStatesFromOptions.length > 0) {
      setStatevalue((prev) => [...prev, selectedStatesFromOptions]);
    }
    // console.log(selectedStatesFromOptions, "Mynewstate");
    // setSelectedStates(selectedStatesFromOptions);

    const MyPropertyCities =
      editStaffData.propties &&
      editStaffData.propties.map((prp) => prp.city_id);
    // console.log(MyPropertyCities, "citiesOptions");

    // setCitiesOptions
    // console.log(citiesOptions,"myadss")
    // const selectedCityFromOptions = citiesOptions.filter((cty) =>
    // MyPropertyCities.includes(cty));
    //     console.log(selectedCityFromOptions,"selectedCityFromOptions");

    const MyPropertyNames =
      editStaffData.propties &&
      editStaffData.propties.map((prp) => prp.property_name);
    // console.log(MyPropertyStates , "MyPropertyStates");
    // console.log(MyPropertyCities , "MyPropertyCities");
    // console.log(MyPropertyNames, "MyPropertyNames");
  };

  // console.log("edit", editStaffData, listServiceDeps);
  //creating array for DepTable based on dep_id and dep_role_id
  const AddDepartments = () => {
    const depExists = editStaffData.org_dept_data.some(
      (dep) =>
        dep.dep_id === editStaffData.depName &&
        dep.dep_role_id === editStaffData.depRole
    );

    if (depExists) {
      // Show error message
      setSnackOpen(true);
      setToastObject({
        message: "Department with this department role already exists",
        duration: 3000,
        variant: "warning",
      });
      return; // Stop further execution
    }
    const depName = serviceDep?.find(
      (i) => i.id === Number(editStaffData.depName)
    ).name;
    // console.log(depName, serviceDep, "name");
    const depRoleName = depRoles?.dep_roles?.find(
      (i) => i.id === Number(editStaffData.depRole)
    )?.dep_role_name;
    const depObj = {
      id: editStaffData.org_dept_data?.length + 1,
      depName: depName,
      dep_id: editStaffData.depName,
      depRoleName: depRoleName,
      dep_role_id: editStaffData.depRole,
      is_default: editStaffData.org_dept_data.length === 0 ? 1 : 0,
    };

    setEditStaffData((prev) => ({
      ...prev,
      org_dept_data: [...prev.org_dept_data, depObj],
    }));
    setEditStaffData((prev) => ({
      ...prev,
      depName: "",
      depRole: "",
    }));
  };
  // console.log(editStaffData, "depLisrt");

  //handle default status of departments and deproles
  const handleDefaultDeproleStatus = (rowData) => {
    setEditStaffData((prev) => ({
      ...prev,
      org_dept_data: [
        ...editStaffData.org_dept_data.map((i) => {
          if (i.id === rowData.id) {
            return {
              ...i,
              is_default: 1,
            };
          } else {
            return { ...i, is_default: 0 };
          }
        }),
      ],
    }));
  };

  //   console.log(testState, "my testings");
  //   console.log(stateOptions, "my stateoptiontestings");

  const handleLocation = () => {
    if (cityId.length > 0) {
      setIsPropertyListed(true);
      API.postMethod("user-management/api/v1/city-property", "post", {
        city_id: cityId,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.status) {
            setConfirmToggle((prevState) => true);
            let propertiesData = [];
            let data = result?.data?.forEach((ele) => {
              ele.label = ele.property_name;
              ele.value = ele.id;
              const {
                country_code,
                country_id,
                created_at,
                deleted_at,
                address1,
                address2,
                id,
                iso2,
                latitude,
                longitude,
                name,
                status,
                type,
                updated_at,
                wikiDataId,
                cctv_link,
                cgst,
                city_id,
                contact_alternate_email,
                contact_email,
                contact_no,
                contact_person,

                email,
                end_at,
                floor_file,
                floor_no,
                furnished_status,
                gst_registration_num,
                invoice_template_id,
                is_alternative_primary,
                is_cancel,
                is_cancellation_policy,
                is_draft,
                is_primary,
                is_qr_code,
                is_verified,
                landline,
                landmark,
                locality,
                long_desc,
                mobile_no,
                property_code,
                property_tabs,
                property_type_id,
                provider_id,
                pseudoname,
                qr_code_path,
                service_location_id,
                sgst,
                short_desc,
                show_actual_name,
                skip_plans,
                sort_id,
                start_at,
                state_id,
                total_sq_ft,
                website,
                zipcode,
                property_name,

                ...eleRest
              } = ele;
              propertiesData.push(eleRest);
              console.log("result_pros", eleRest);
            });

            setPropertiesList(propertiesData);
            console.log(propertiesData.length, " Properties count");
            setIsPropertyListed(false);
          } else {
            setIsPropertyListed(false);
            Swal.fire({
              title: <strong>Error</strong>,
              html: <i>{result.message}</i>,
              icon: "warning",
              timer: 1000,
              showConfirmButton: true,
            }).then(function () {
              Swal.close();
            });
            // console.log("laksh", "properties not found");
          }
        });
    } else {
      // console.log("laksh", "please select atleast one city");
    }

    return null;
  };

  const uniqueTestState = [...new Set(testState.map((d) => d.value))].map(
    (id) => testState.find((d) => d.value === id)
  );
  const uniqueTestcity = [...new Set(foundObjects.map((d) => d.value))].map(
    (id) => foundObjects.find((d) => d.value === id)
  );

  const check_propertyChoosed = () => {
    if (propertyValue.length > 0) {
      setIsPropertySelected(false);
      return true;
    } else {
      setIsPropertySelected(true);
      return false;
    }
  };

  const handlePropertyNames = (selectedProperties) => {
    // console.log(selectedProperties);
    //console.log(
    //   "selectedProperties",
    //   selectedProperties.map((rr) => rr.label)
    // );
    const propertyChoose = selectedProperties?.map(
      (property) => property.value
    );
    const selectedPropertyId = selectedProperties
      ?.map((property) => property.value)
      .pop();
    // console.log("propertyChoose", propertyChoose);
    //console.log("selectedPropertyId", selectedPropertyId);
    setPropertyValue(propertyChoose);
    setNEwProperties(selectedProperties);
    setPropertyId(selectedPropertyId);
    // console.log(propertyChoose, "property choose");
    // console.log(propertyChoose, "selected ID");
    // console.log()
  };

  // const handleStatesName = (selectedStates) => {
  //   // setSelectedStatesProp(selectedStates);
  //   console.log("selectedStates", selectedStates);
  //   const stateChoose = selectedStates?.map((state) => state.value);
  //   const selectedStateId = selectedStates?.map((state) => state.value).pop();
  //   console.log("stateChoose", stateChoose);
  //   console.log("stateId", selectedStateId);
  //   console.log("selectedStates", selectedStates);

  //   setTestState(selectedStates);
  //   setStateId(stateChoose);

  //   const promises = stateChoose.map((id) => {
  //     API.getData("user-management/api/v1/city/" + id)
  //       .then((res) => res.json())
  //       .then((result) => {
  //         let citiOptions = [];
  //         const citiesData = result?.data;
  //         if (citiesData?.length > 0) {
  //           console.log("dsssdsd", citiesData);
  //           citiesData.forEach((ele) => {
  //             ele.label = ele.name;
  //             ele.value = ele.id;
  //             ele.stateId = ele.state_id;
  //             const {
  //               country_code,
  //               country_id,
  //               state_id,
  //               state_code,
  //               created_at,
  //               deleted_at,
  //               fips_code,
  //               flag,
  //               id,
  //               iso2,
  //               latitude,
  //               longitude,
  //               name,
  //               status,
  //               type,
  //               updated_at,
  //               wikiDataId,
  //               ...eleRest
  //             } = ele;
  //             citiOptions.push(eleRest);
  //           });
  //           // setPropCityName(citiOptions);
  //           // setCitiesOptions(citiOptions);
  //         }
  //         setCitiesOptions([...citiesOptions, ...citiOptions]);
  //         console.log(promises, "prmisessss");
  //       });
  //   });
  // };

  const handleStatesName = (selectedStates) => {
    if (!selectedStates || !Array.isArray(selectedStates)) return;

    console.log("selectedStates", selectedStates);

    const stateChoose = selectedStates.map((state) => state.value);
    const selectedStateId = selectedStates.map((state) => state.value).pop();

    setTestState(selectedStates); // Store selected states as array
    setStateId(stateChoose);

    const promises = stateChoose.map((id) =>
      API.getData("user-management/api/v1/city/" + id)
        .then((res) => res.json())
        .then((result) => {
          const citiOptions = [];
          const citiesData = result?.data || [];
          if (citiesData.length > 0) {
            citiesData.forEach((ele) => {
              const {
                id: value,
                name: label,
                state_id: stateId,
                ...eleRest
              } = ele;
              citiOptions.push({ label, value, stateId, ...eleRest });
            });
          }
          setCitiesOptions((prevOptions) => [...prevOptions, ...citiOptions]);
        })
    );

    // Execute all promises to fetch cities data
    Promise.all(promises);
  };

  // const handleStatesName = (selectedStates) => {
  //   console.log(selectedStates, "selectedStates");

  //   // setSelectedStatesProp(selectedStates);
  //   //  console.log("selectedStates", selectedStates);
  //   const stateChoose = selectedStates?.map((state) => state?.value);
  //   const selectedStateId = selectedStates?.map((state) => state?.value).pop();
  //   // console.log("stateChoose", stateChoose);
  //   // console.log("stateId", selectedStateId);
  //   // console.log("selectedStates", selectedStates);

  //   setTestState(selectedStates);
  //   setStateId(stateChoose);

  //   const promises = stateChoose.map((id) => {
  //     API.getData("user-management/api/v1/city/" + id)
  //       .then((res) => res.json())
  //       .then((result) => {
  //         let citiOptions = [];
  //         const citiesData = result?.data;
  //         if (citiesData?.length > 0) {
  //           //  console.log("dsssdsd", citiesData);
  //           citiesData.forEach((ele) => {
  //             ele.label = ele.name;
  //             ele.value = ele.id;
  //             ele.stateId = ele.state_id;
  //             const {
  //               country_code,
  //               country_id,
  //               state_id,
  //               state_code,
  //               created_at,
  //               deleted_at,
  //               fips_code,
  //               flag,
  //               id,
  //               iso2,
  //               latitude,
  //               longitude,
  //               name,
  //               status,
  //               type,
  //               updated_at,
  //               wikiDataId,
  //               ...eleRest
  //             } = ele;
  //             citiOptions.push(eleRest);
  //           });
  //           // setPropCityName(citiOptions);
  //           // setCitiesOptions(citiOptions);
  //         }
  //         setCitiesOptions([...citiesOptions, ...citiOptions]);
  //         // console.log(promises, "prmisessss");
  //       });
  //   });
  // };

  const handleCityNames = (selectedCities) => {
    // console.log("selectedCities", selectedCities);
    const cityChoose = selectedCities?.map((city) => city.value);
    const cityChooselabel = selectedCities?.map((city) => city.label);
    const selectedCityId = selectedCities?.map((city) => city.value);
    // console.log("cityChoose", cityChoose);
    // console.log("selectedCityId", selectedCityId);
    // setCityvalue(cityChoose);
    setFoundObjects(selectedCities);
    setCityId(selectedCityId);
    handleLocation();
  };

  //handle department and departmentrole delete from is default table
  const handleDeleteDep = async (rowData) => {
    const message =
      rowData.is_default === 1
        ? "Are you sure you want to delete default data?"
        : "Are you sure you want to delete this data?";

    const result = await Swal.fire({
      title: "Confirm to delete",
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      backdrop: true,
      allowOutsideClick: false,
    });

    if (result.isConfirmed) {
      setEditStaffData((prev) => ({
        ...prev,
        org_dept_data: [
          ...editStaffData.org_dept_data.filter((i) => i.id !== rowData.id),
        ],
      }));
      Swal.fire("Deleted!", "Your data has been deleted.", "success");
    }
  };
  //columns for Org-dep table

  const columns = useMemo(
    () => [
      {
        title: "Department",
        field: "depName",
      },
      {
        title: "Department Role",
        field: "depRoleName",
      },

      {
        title: "Default",
        field: "edit",
        render: (rowData) => (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Mark as default</Tooltip>}>
            <Form.Check
              type="radio"
              value="large"
              // label="Large"
              id={`default-radio-${rowData.id}`} // Ensure unique ID if using in a loop
              checked={rowData.is_default === 1}
              onChange={() => handleDefaultDeproleStatus(rowData)}
              style={{ color: "orange" }}
            />
          </OverlayTrigger>
        ),
      },
      {
        title: "Action",
        field: "delete",

        // render: (rowData) => (
        //   <Tooltip title="Delete">
        //     <IconButton onClick={() => handleDeleteDep(rowData)}>
        //       <DeleteRoundedIcon />
        //     </IconButton>
        //   </Tooltip>
        // ),
        render: (rowData) => (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-top`}>Delete</Tooltip>}>
            <Button variant="danger" onClick={() => handleDeleteDep(rowData)}>
              <FaTrash />
            </Button>
          </OverlayTrigger>
        ),
      },
    ],
    [editStaffData.org_dept_data]
  );
  //handle when country id change
  const handleCountryChange = (e) => {
    let value = e.target.value;
    // console.log("laksh", e.target.value);
    API.getData(`user-management/api/v1/state/${e.target.value}`)
      .then((stateRes) => stateRes.json())
      .then((stateResult) => {
        // console.log("laksh", stateResult.data);
        // console.log("API-STATE_LIST");
        return setEditStaffData({
          ...editStaffData,
          country_id: value,
          statesData: stateResult?.data,
        });
      })
      .catch((stateErr) => console.log("API-STATE_LIST", stateErr));
  };
  //handle when state id change
  const handleStateChange = (e) => {
    let value = e.target.value;
    API.getData(`user-management/api/v1/city/${e.target.value}`)
      .then((cityRes) => cityRes.json())
      .then((cityResult) => {
        // console.log("API-STATE_LIST");
        setEditStaffData({
          ...editStaffData,
          state_id: value,
          citiesData: cityResult?.data,
        });
      })
      .catch((cityErr) => console.log("API-CITY_LIST", cityErr));
  };
  //handle when toggle enable and disenable button
  const onToggleButton = (e) => {
    let name = e.target.name;
    if (name == "status" && e.target.checked == true) {
      setEditStaffData({ ...editStaffData, status: 1 });
    } else if ((name = "status" && e.target.checked == false)) {
      setEditStaffData({ ...editStaffData, status: 0 });
    }
  };
  //check wheteher password and confirm passowrd is same
  const check_password = () => {
    if (editStaffData.password != editStaffData.password_confirmation) {
      setEditStaffData({ ...editStaffData, isPasswordSame: false });
      return false;
    } else {
      setEditStaffData({ ...editStaffData, isPasswordSame: true });
      return true;
    }
  };
  //check password length
  const checkPasswordLength = () => {
    if (editStaffData.password.length > 7) {
      setEditStaffData({ ...editStaffData, isPasswordValid: true });
      return true;
    } else {
      setEditStaffData({ ...editStaffData, isPasswordValid: false });
      return false;
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("gee1");
    const isValid = validateForm();
    if (isValid) {
      console.log("true");
      let isSame = editStaffData.canUpdate == true ? check_password() : true;

      //get local data
      let user = localStorage.getItem("mikro_user");
      user = JSON.parse(user);

      // to get onboard person role name
      let objectsWithStatusOne = user?.data?.roles.filter(
        (obj) => obj.status === 1
      );

      let UseronboardString = objectsWithStatusOne?.map((obj) => obj.role_name);
      const OnboardPerson =
        UseronboardString.length > 0 ? UseronboardString[0] : null;
      //  console.log(OnboardPerson, " <<<<<<<>>>>>>>>>>");

      let tempValidPassword =
        editStaffData.canUpdate == true ? checkPasswordLength() : true;
      let data = {
        name: editStaffData.name,
        mobile_no: editStaffData.mobileNo,
        cat_head: editStaffData.isCatHead,
        cat_lead: editStaffData.isCatLead,
        email: editStaffData.email,
        address1: editStaffData.address1,
        address2: editStaffData.address2,
        city_id: Number(editStaffData.city_id),
        state_id: Number(editStaffData.state_id),
        country_id: Number(editStaffData.country_id),
        postal_code: Number(editStaffData.postal_code),
        dob: editStaffData.dob,
        role: "Staff",
        register_from: "Web",
        on_boarding_mode: OnboardPerson,
        company_name: editStaffData.company_name,
        status: Number(editStaffData.status),
        property_id:
          propertyValue.length === 0
            ? NEwProperties.map((d) => d.value)
            : propertyValue,
        // state_id:stateValue
        contact_number: editStaffData.mobileNo,
        org_dept_data: editStaffData.org_dept_data.map((i) => {
          return {
            org_dept_id: Number(i.dep_id),
            org_dept_role_id: Number(i.dep_role_id),
            is_default: i.is_default,
          };
        }),
      };
      data =
        editStaffData.canUpdate === true
          ? {
              ...data,
              password: editStaffData.password,
              password_confirmation: editStaffData.password_confirmation,
            }
          : { ...data };
      console.log("props.match.params.id", id);
      // let id = props.match.params.id;

      // if (isSame === true && tempValidPassword === true) {
      //   API.postMethod("user-management/api/v1/user/" + id, "put", data)
      //     .then((res) => res.json())
      //     .then((result) => {
      //       console.log("API-USER_UPDATE", result);
      //       if (result.status == true) {
      //         Swal.fire({
      //           title: <strong>success</strong>,
      //           html: <i>{result.message}</i>,
      //           icon: "success",
      //           timer: 2000,
      //           showConfirmButton: true,
      //         }).then(function () {
      //           Swal.close();
      //           window.location.href = "/user-management/organization-staff";
      //         });
      //       } else {
      //         Swal.fire({
      //           title: <strong>Error</strong>,
      //           html: <i>{result.message}</i>,
      //           icon: "error",
      //           timer: 2000,
      //           showConfirmButton: true,
      //         }).then(function () {
      //           Swal.close();
      //         });
      //       }
      //     })
      //     .catch((e) => {
      //       console.log("error", e);
      //     });
      // }
      if (isSame === true && tempValidPassword === true) {
        API.postMethod("user-management/api/v1/user/" + id, "put", data)
          .then((res) => res.json())
          .then((result) => {
            console.log("result", result);
            console.log("API-USER_UPDATE", result);
            if (result.status === true) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: result.message || "User updated successfully.",
                position: "top-right",
                toast: true,
                timer: 3000,
                background: "rgb(255, 165, 0)",
                color: "#fff",
                timerProgressBar: true,
                showConfirmButton: false,
              }).then(() => {
                window.location.href = "/master/organization";
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: result.message || "Failed to update Staff.",
                position: "top-right",
                toast: true,
                timer: 3000,
                background: "#dc3545",
                color: "#fff",
                timerProgressBar: true,
                showConfirmButton: false,
              });
            }
          })
          .catch((e) => {
            console.error("error", e);

            Swal.fire({
              icon: "error",
              title: "Error",
              text: "An error occurred. Please try again.",
              position: "top-right",
              toast: true,
              timer: 3000,
              background: "#dc3545",
              color: "#fff",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          });
      }
    } else {
      if (errors.name) {
        NameRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.email) {
        emailRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.mobile_number) {
        contactNumberRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.mobile_number1) {
        contactNumberRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.org_dept_data) {
        Departmentref.current.scrollIntoView({ behavior: "smooth" });
      }
      // else if (errors.dep_role_id) {
      //   DepartmentroleRef.current.scrollIntoView({ behavior: 'smooth' });
      // }
      else if (errors.address1) {
        AddressoneRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.country_id) {
        countryRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.postal_code) {
        postalcodeRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.postal_code1) {
        postalcodeRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const filteredCityOptions = Array.from(
    new Set(
      citiesOptions
        ?.filter(({ stateId }) => stateValue?.includes(stateId))
        ?.map(({ value }) => value)
    )
  )?.map((val) => citiesOptions?.find(({ value }) => value === val));

  const handleRadioChange = (event) => {
    const value = event.target.value;
    if (value === "cathead") {
      setEditStaffData({
        ...editStaffData,
        isCatHead: 1,
        isCatLead: 0,
      });
    } else if (value === "catlead") {
      setEditStaffData({
        ...editStaffData,
        isCatHead: 0,
        isCatLead: 1,
      });
    }
    console.log(value);
  };
  return (
    <>
      {editStaffData.loader === true ? (
        <>
          <div className="card editStaff">
            {/* page title */}
            {/* <div className="col-sm-12 text-left">
              <h6 className="breadcrumps_bg">
                <a className="text-primary" href="/master/organization">
                  Organization staff
                </a>{" "}
                <span className="text-muted"> / Edit Organization Staff </span>
              </h6>
            </div> */}
            <div className="page-header mb-0">
              <nav aria-label="breadcrumb" className="mt-5">
                <div id="clip">
                  <span
                    style={{
                      padding: "3px 20px",
                      backgroundColor: "#FFA500",
                      color: "white",
                      display: "inline-block",
                      clipPath:
                        "polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)",
                    }}>
                    <a
                      href="/master/organization"
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                      }}>
                      Master
                    </a>
                  </span>
                  <span
                    style={{
                      padding: "3px 20px",
                      backgroundColor: "#FFA500",
                      color: "white",
                      display: "inline-block",
                      clipPath:
                        "polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)",
                    }}>
                    <a
                      href="/master/organization"
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                      }}>
                      Organization staff
                    </a>
                  </span>
                  <span
                    style={{
                      padding: "3px 20px",
                      backgroundColor: "#FFA500",
                      color: "white",
                      display: "inline-block",
                      clipPath:
                        "polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)",
                      marginRight: "5px", // Adjusted margin for smaller gap
                    }}>
                    <a
                      href="/org"
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                      }}>
                      Edit Organization Staff
                    </a>
                  </span>
                </div>
              </nav>
            </div>
            <div className="row">
              <div
                className="col-md-12 grid-margin stretch-card"
                style={{
                  background: "white",
                  borderRadius: "5px",
                  padding: "22px",
                  height: "80vh",
                  overflow: "auto",
                  border: "none",
                  outline: "none",
                }}>
                <div className="card">
                  <div className="card-body">
                    <Form className="forms-sample" onSubmit={handleFormSubmit}>
                      <div className="row">
                        {/* User Name  */}
                        <div
                          className="col-md-6 col-sm-12 mt-3 mb-3"
                          ref={NameRef}>
                          <Form.Group>
                            <label htmlFor="exampleInputUsername1">
                              Staff User Name{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <Form.Control
                              autoFocus
                              name="name"
                              pattern="^[A-Za-z][A-Za-z0-9_\s ]*$"
                              onKeyPress={(event) => {
                                if (!/^[A-Za-z0-9_\s ]*$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Enter only Alphanumeric and Should not allow special characters other than space or underscore"
                              type="text"
                              onChange={(e) => {
                                setEditStaffData({
                                  ...editStaffData,
                                  name: e.target.value,
                                });
                              }}
                              value={editStaffData.name}
                              id="exampleInputUsername1"
                              placeholder="Enter Sales User Name"
                              maxLength={35}
                              required
                            />
                            {errors.name && (
                              <small className="text-danger">
                                {errors.name}
                              </small>
                            )}

                            <Form.Control.Feedback type="invalid">
                              Please Enter Name.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        {/* Date of Birth */}
                        <div className="col-md-6 col-sm-12 mt-3 mb-3">
                          <Form.Group>
                            <label htmlFor="date">
                              Date of Birth{" "}
                              {/* <span className="text-danger">*</span>  */}
                            </label>
                            <Form.Control
                              type="date"
                              onChange={(e) => {
                                setEditStaffData({
                                  ...editStaffData,
                                  dob: e.target.value,
                                });
                              }}
                              value={editStaffData.dob}
                              name="dob"
                              id="date"
                              max={date}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Age should be minimum 18 years"
                              placeholder="Select Date"
                              // required
                            />
                            {/* <Form.Control.Feedback type="invalid">
                              Please Select DOB.
                            </Form.Control.Feedback> */}
                          </Form.Group>
                        </div>
                      </div>

                      <div className="row">
                        {/*  Contact Number */}
                        <div
                          className="col-md-6 col-sm-12 mt-3 mb-3"
                          ref={contactNumberRef}>
                          <Form.Group>
                            <label htmlFor="enterContactnumber">
                              Contact Number{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setEditStaffData({
                                  ...editStaffData,
                                  mobileNo: e.target.value,
                                });
                              }}
                              value={editStaffData.mobileNo}
                              maxLength="10"
                              minLength="10"
                              type="tel"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="Enter Contact Number"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Value should not exceed or less than 10"
                              name="mobileNo"
                              required
                            />
                            {errors.mobile_number && (
                              <small className="text-danger">
                                {errors.mobile_number}
                              </small>
                            )}
                            {errors.mobile_number1 && (
                              <small className="text-danger">
                                {errors.mobile_number1}
                              </small>
                            )}

                            <Form.Control.Feedback type="invalid">
                              Please Enter Contact Number.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        {/* Email ID */}
                        <div
                          className="col-md-6 col-sm-12 mt-3 mb-3"
                          ref={emailRef}>
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">
                              Email ID <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setEditStaffData({
                                  ...editStaffData,
                                  email: e.target.value,
                                });
                              }}
                              value={editStaffData.email}
                              type="email"
                              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                              name="email"
                              disabled
                              placeholder="Enter Email ID"
                              required
                            />
                            {errors.email && (
                              <small className="text-danger">
                                {errors.email}
                              </small>
                            )}

                            <Form.Control.Feedback type="invalid">
                              Please Enter Email Id.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="row">
                        {/* Address Line#1 */}
                        <div
                          className="col-md-6 col-sm-12 mt-3 mb-3"
                          ref={AddressoneRef}>
                          <Form.Group>
                            <label htmlFor="enterAddressLineOne">
                              Address Line#1{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setEditStaffData({
                                  ...editStaffData,
                                  address1: e.target.value,
                                });
                              }}
                              value={editStaffData.address1}
                              as="textarea"
                              rows={3}
                              maxLength={150}
                              name="address1"
                              id="enterAddressLineOne"
                              placeholder="Enter Address Line #1"
                              required
                            />
                            {errors.address1 && (
                              <small className="text-danger">
                                {errors.address1}
                              </small>
                            )}

                            <Form.Control.Feedback type="invalid">
                              Please Enter Address 1.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        {/* Address Line#2 */}
                        <div className="col-md-6 col-sm-12 mt-3 mb-3">
                          <Form.Group>
                            <label htmlFor="enterAddressLineTwo">
                              Address Line#2
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setEditStaffData({
                                  ...editStaffData,
                                  address2: e.target.value,
                                });
                              }}
                              value={editStaffData.address2}
                              as="textarea"
                              rows={3}
                              maxLength={150}
                              name="address2"
                              id="enterAddressLineTwo"
                              placeholder="Enter Address Line #2"
                            />
                          </Form.Group>
                        </div>
                        {/* Country */}
                        <div
                          className="col-md-6 col-sm-12 mt-3 mb-3"
                          ref={countryRef}>
                          <Form.Group>
                            <label htmlFor="country">
                              Country <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="country_id"
                              onChange={(e) => {
                                // setEditStaffData({
                                //   ...editStaffData,
                                //   country_id: e.target.value,
                                // });
                                handleCountryChange(e);
                              }}
                              value={editStaffData.country_id}
                              required>
                              <option value="">Select Country</option>
                              {editStaffData.countries &&
                                editStaffData.countries?.map((value, key) => {
                                  return (
                                    <option key={key} value={value.id}>
                                      {value.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.country_id && (
                              <small className="text-danger">
                                {errors.country_id}
                              </small>
                            )}

                            <Form.Control.Feedback type="invalid">
                              Please Select Country.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        {/* State */}
                        <div
                          className="col-md-6 col-sm-12 mt-3 mb-3"
                          ref={stateRef}>
                          <Form.Group>
                            <label htmlFor="state">
                              State <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="state_id"
                              onChange={(e) => {
                                // setEditStaffData({
                                //   ...editStaffData,
                                //   state_id: e.target.value,
                                // });
                                handleStateChange(e);
                              }}
                              value={editStaffData.state_id}
                              required>
                              <option value="">Select State</option>
                              {editStaffData.statesData?.map((data, index) => {
                                return (
                                  <option value={data.id} key={index}>
                                    {data.name}
                                  </option>
                                );
                              })}
                            </select>
                            <Form.Control.Feedback type="invalid">
                              Please Select State.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        {/* City */}
                        <div
                          className="col-md-6 col-sm-12 mt-3 mb-3"
                          ref={cityRef}>
                          <Form.Group>
                            <label htmlFor="City">
                              City <span className="text-danger">*</span>
                            </label>
                            <select
                              onChange={(e) =>
                                setEditStaffData({
                                  ...editStaffData,
                                  city_id: e.target.value,
                                })
                              }
                              value={editStaffData.city_id}
                              className="form-control"
                              name="city_id"
                              required>
                              <option value="">Select City</option>
                              {editStaffData.citiesData?.map((data, index) => {
                                return (
                                  <option value={data.id} key={index}>
                                    {data.name}
                                  </option>
                                );
                              })}
                            </select>
                            <Form.Control.Feedback type="invalid">
                              Please Select City.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        {/* Postal Code */}
                        <div
                          className="col-md-6 col-sm-12 mt-3 mb-3"
                          ref={postalcodeRef}>
                          <Form.Group>
                            <label htmlFor="enterPostalCode">
                              Postal Code <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setEditStaffData({
                                  ...editStaffData,
                                  postal_code: e.target.value,
                                });
                              }}
                              value={editStaffData.postal_code}
                              type="phone"
                              maxLength={6}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              name="postal_code"
                              id="enterPostalCode"
                              placeholder="Enter Postal Code"
                              required
                            />
                            {errors.postal_code1 && (
                              <small className="text-danger">
                                {errors.postal_code1}
                              </small>
                            )}

                            {errors.postal_code && (
                              <small className="text-danger">
                                {errors.postal_code}
                              </small>
                            )}

                            <Form.Control.Feedback type="invalid">
                              Please Enter Postal Code.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        {/* Company Name */}
                        {/* <div className="col-md-6 col-sm-12 mt-3 mb-3">
                          <Form.Group>
                            <label htmlFor="company">Company Name</label>
                            <Form.Control
                              type="text"
                              name="company_name"
                              onChange={(e) => {
                                setEditStaffData({
                                  ...editStaffData,
                                  company_name: e.target.value,
                                });
                              }}
                              value={editStaffData.company_name}
                              id="company"
                              placeholder="Enter Company Name"
                            />
                          </Form.Group>
                        </div> */}
                        <div
                          className="col-md-6 col-sm-12 mt-3 mb-3 d-flex"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}>
                          <span
                            style={{
                              fontSize: "18px",
                              color: "#495057",
                              marginRight: "20px",
                            }}>
                            Do u want to Update Password ?{" "}
                          </span>
                          <div>
                            <button
                              type="button"
                              className={
                                editStaffData.canUpdate === true
                                  ? "update_password_basic_button is_update"
                                  : "update_password_basic_button"
                              }
                              style={{
                                background:
                                  "linear-gradient(45deg, #4caf50, #81c784)",
                                color: "#ffffff",
                                fontSize: "15px",
                                padding: "10px 20px",
                                borderRadius: "8px",
                                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
                                transition: "all 0.3s ease",
                                border: "none",
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              onClick={() => {
                                setEditStaffData({
                                  ...editStaffData,
                                  canUpdate: true,
                                });
                                console.log("laksh", "yes");
                              }}>
                              Yes
                            </button>
                            <button
                              type="button"
                              className={
                                editStaffData.canUpdate === true
                                  ? "update_password_basic_button "
                                  : "update_password_basic_button is_update"
                              }
                              style={{
                                background:
                                  "linear-gradient(45deg, rgb(255, 152, 0), #FFC107)",
                                color: "#ffffff",
                                fontSize: "15px",
                                padding: "10px 20px",
                                borderRadius: "8px",
                                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
                                transition: "all 0.3s ease",
                                border: "none",
                                textTransform: "none",
                              }}
                              onClick={() => {
                                setEditStaffData({
                                  ...editStaffData,
                                  canUpdate: false,
                                });
                                console.log("laksh", "no");
                              }}>
                              No
                            </button>
                          </div>
                        </div>{" "}
                        <div className="col-md-6"></div>
                        <div
                          className="col-md-6 col-sm-12 mt-3 mb-3 mt-3"
                          style={{
                            display:
                              editStaffData.canUpdate === true
                                ? "block"
                                : "none",
                          }}>
                          <Form.Group>
                            <label htmlFor="company">
                              Password <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              type={
                                passwordIsTypePassword == true
                                  ? "password"
                                  : "text"
                              }
                              name="password"
                              onChange={(e) => {
                                setEditStaffData({
                                  ...editStaffData,
                                  password: e.target.value,
                                });
                              }}
                              value={editStaffData.password}
                              id="password"
                              placeholder="Enter password"
                              //   style={{
                              //     position: "relative",
                              //     border:
                              //       editStaffData.password.length < 8 &&
                              //       editStaffData.password !== "" &&
                              //       "1px solid red",
                              //   }}
                              style={{
                                position: "relative",
                                border:
                                  (editStaffData.password || "")?.length < 8 &&
                                  editStaffData.password !== "" &&
                                  "1px solid red",
                              }}
                              required={editStaffData.canUpdate}
                            />
                            {passwordIsTypePassword === true ? (
                              <>
                                <button
                                  type="button"
                                  style={{
                                    position: "absolute",
                                    top: "33px",
                                    right: "20px",
                                    border: "none",
                                    background: "none",
                                    borderLeft: "1px solid #ccc",
                                  }}
                                  onClick={(e) => {
                                    setPasswordIsTypePassword(false);
                                  }}>
                                  <AiOutlineEyeInvisible
                                    size="1.5em"
                                    color="#111"
                                  />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  style={{
                                    position: "absolute",
                                    top: "33px",
                                    right: "20px",
                                    border: "none",
                                    background: "none",
                                    borderLeft: "1px solid #ccc",
                                  }}
                                  onClick={(e) => {
                                    setPasswordIsTypePassword(true);
                                  }}>
                                  <AiOutlineEye size="1.5em" color="#111" />
                                </button>
                              </>
                            )}{" "}
                            {editStaffData?.password?.length < 8 &&
                              editStaffData?.password !== "" && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "10px",
                                    letterSpacing: 1,
                                    fontWeight: 500,
                                  }}>
                                  Min of 8 digits required
                                </p>
                              )}
                          </Form.Group>
                          <p
                            style={{
                              display:
                                editStaffData?.isPasswordValid == true
                                  ? "none"
                                  : "block",
                              color: "red",
                            }}>
                            Password Should be above 7 characters
                          </p>
                        </div>
                        <div
                          className="col-md-6 col-sm-12 mt-3 mb-3 mt-3"
                          style={{
                            display:
                              editStaffData.canUpdate === true
                                ? "block"
                                : "none",
                          }}>
                          <Form.Group>
                            <label htmlFor="company">
                              Confirm Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              type={
                                cPasswordIsTypePassword === true
                                  ? "password"
                                  : "text"
                              }
                              name="password_confirmation"
                              onChange={(e) => {
                                setEditStaffData({
                                  ...editStaffData,
                                  password_confirmation: e.target.value,
                                });
                              }}
                              value={editStaffData.password_confirmation}
                              id="confirm_password"
                              placeholder="Enter Confirm password"
                              style={{
                                position: "relative",
                                border:
                                  (editStaffData.password_confirmation?.length <
                                    8 &&
                                    editStaffData.password_confirmation !==
                                      "") ||
                                  (editStaffData.password_confirmation &&
                                    editStaffData.password_confirmation !==
                                      editStaffData.password)
                                    ? "1px solid red"
                                    : null,
                              }}
                              required={editStaffData.canUpdate}
                            />
                            {cPasswordIsTypePassword === true ? (
                              <>
                                <button
                                  type="button"
                                  style={{
                                    position: "absolute",
                                    top: "33px",
                                    right: "20px",
                                    border: "none",
                                    background: "none",
                                    borderLeft: "1px solid #ccc",
                                  }}
                                  onClick={(e) => {
                                    setcPasswordIsTypePassword(false);
                                  }}>
                                  <AiOutlineEyeInvisible
                                    size="1.5em"
                                    color="#111"
                                  />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  style={{
                                    position: "absolute",
                                    top: "33px",
                                    right: "20px",
                                    border: "none",
                                    background: "none",
                                    borderLeft: "1px solid #ccc",
                                  }}
                                  onClick={(e) => {
                                    setcPasswordIsTypePassword(true);
                                  }}>
                                  <AiOutlineEye size="1.5em" color="#111" />
                                </button>
                              </>
                            )}
                            {editStaffData.password_confirmation?.length < 8 &&
                              editStaffData.password_confirmation !== "" && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "10px",
                                    letterSpacing: 1,
                                    fontWeight: 500,
                                  }}>
                                  Min of 8 digits required
                                </p>
                              )}
                            {editStaffData.password_confirmation &&
                              editStaffData.password_confirmation?.length >=
                                8 &&
                              editStaffData.password_confirmation !==
                                editStaffData.password &&
                              editStaffData.password && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "10px",
                                    letterSpacing: 1,
                                    fontWeight: 500,
                                  }}>
                                  Password mismatch
                                </p>
                              )}
                          </Form.Group>

                          <p
                            style={{
                              display:
                                editStaffData.isPasswordSame == true
                                  ? "none"
                                  : "block",
                              color: "red",
                            }}>
                            Password and Confirm Password does not match
                          </p>
                        </div>{" "}
                        {/* Department */}
                        <div className="col-md-6 col-sm-12 mt-3 mb-3 mt-3">
                          <Form.Group>
                            <label htmlFor="dep_id">
                              Department <span className="text-danger">*</span>
                            </label>

                            <select
                              onChange={(e) =>
                                setEditStaffData({
                                  ...editStaffData,
                                  depName: Number(e.target.value),
                                  depRole: "",
                                })
                              }
                              className="form-control"
                              name="dep_id"
                              id="dep_id"
                              value={editStaffData.depName}
                              // required
                            >
                              <option value="">
                                Select Organization Department
                              </option>
                              {serviceDep?.map((data, index) => {
                                return (
                                  <option value={Number(data.id)} key={index}>
                                    {data.name}
                                  </option>
                                );
                              })}
                            </select>
                          </Form.Group>
                        </div>
                        {/* Department Roles*/}
                        {editStaffData?.depName ||
                        editStaffData?.org_dept_data?.length > 0 ? (
                          <div
                            className="col-md-6 col-sm-12 mt-3 mb-3 mt-3"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}>
                            <div className="col-md-9 col-sm-12 p-0">
                              <Form.Group>
                                <label htmlFor="dep_role_id">
                                  Department Role{" "}
                                  <span className="text-danger">*</span>
                                </label>

                                <select
                                  onChange={(e) =>
                                    setEditStaffData({
                                      ...editStaffData,
                                      depRole: e.target.value,
                                    })
                                  }
                                  value={editStaffData?.depRole}
                                  className="form-control"
                                  name="dep_role_id"
                                  id="dep_role_id"
                                  // required
                                >
                                  <option value="">
                                    Select Organization Department Role
                                  </option>

                                  {depRoles?.dep_roles?.map((data, index) => {
                                    return (
                                      <option
                                        value={Number(data.id)}
                                        key={index}>
                                        {data.dep_role_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </Form.Group>
                            </div>

                            <div
                              className="col-md-2 col-sm-12   p-0"
                              style={{
                                alignSelf: "end",
                                // marginBottom: "5px",
                                // marginRight: "10px",
                                // marginLeft: "0px",
                                display:
                                  !editStaffData.depName ||
                                  !editStaffData.depRole
                                    ? "none"
                                    : "block",
                              }}
                              onClick={AddDepartments}>
                              <Button
                                style={{
                                  background:
                                    "linear-gradient(45deg, #4caf50, #81c784)",
                                  color: "#ffffff",
                                  fontSize: "15px",
                                  padding: "10px 20px",
                                  borderRadius: "8px",
                                  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
                                  transition: "all 0.3s ease",
                                  border: "none",
                                  textTransform: "none",
                                }}>
                                Add
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-5 col-sm-12"></div>
                        )}
                        {/* show table for dep_roles and dep*/}
                        <div
                          className="col-md-12  col-sm-12  p-0"
                          style={{
                            display:
                              editStaffData?.org_dept_data?.length > 0
                                ? "block"
                                : "none",
                          }}>
                          {/* <MaterialTable
                            key={editStaffData.org_dept_data}
                            title="Department"
                            icons={tableIcons}
                            columns={columns}
                            data={editStaffData.org_dept_data}
                            options={{
                              pageSize:
                                editStaffData.org_dept_data.length < 5
                                  ? editStaffData.org_dept_data.length
                                  : 5,
                              padding: "default",
                              searchFieldAlignment: "left",
                              searchFieldStyle: {
                                width: "300px",
                                height: "40px",
                                borderRadius: "10px",
                              },
                              searchFieldVariant: "outlined",
                              showTitle: false,
                            }}
                            components={{
                              Toolbar: (props) => {
                                return null;
                              },
                            }}
                          /> */}
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                {columns.map((column, index) => (
                                  <th key={index}>{column.title}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {editStaffData?.org_dept_data?.map(
                                (rowData, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {columns.map((column, colIndex) => (
                                      <td key={colIndex}>
                                        {column.render
                                          ? column.render(rowData)
                                          : rowData[column.field]}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </div>
                        <div className="col-md-4 col-sm-12 d-flex mt-5">
                          {/* <div className="me-2">
                            <input
                              type="radio"
                              id="indivitualUser"
                              name="inviteGuestFor"
                              value="cathead"
                              onChange={handleRadioChange}
                              checked={editStaffData.isCatHead}
                            />
                            <label
                              htmlFor="indivitualUser"
                              className="visitors_modal_lable_radio">
                              CAT Head
                            </label>
                          </div> */}
                          {/* <div className="">
                            <input
                              type="radio"
                              id="corporateUser"
                              name="inviteGuestFor"
                              value="catlead"
                              onChange={handleRadioChange}
                              checked={editStaffData.isCatLead}
                            />
                            <label
                              htmlFor="corporateUser"
                              className="visitors_modal_lable_radio">
                              CAT Lead
                            </label>
                          </div> */}
                        </div>
                      </div>

                      {errors.org_dept_data && (
                        <small className="text-danger">
                          {errors.org_dept_data}
                        </small>
                      )}

                      <div className="row">
                        <div
                          className="col-md-12 col-sm-12 my-4"
                          ref={propstateRef}>
                          <b>Property Access based on location</b>
                        </div>
                        {/* State */}
                        <div className="col-md-6 col-sm-12 mt-3 mb-3">
                          <Form.Group>
                            <label>
                              State <span className="text-danger">*</span>
                            </label>

                            {/* <Select
                              multiple
                              className="form-control"
                              id="country-select-demo"
                              options={
                                !stateOptions.length
                                  ? [{ label: "Loading...", value: 0 }] // Use 'value' here instead of 'id'
                                  : stateOptions
                              }
                              onChange={(value) => {
                                console.log([value]);
                                handleStatesName([value]);
                              }}
                              value={uniqueTestState} // Make sure uniqueTestState is in the correct format
                              renderInput={(params) => (
                                <Form.Control
                                  {...params}
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    outline: "none",
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autoComplete
                                  }}
                                />
                              )}
                            /> */}

                            <Select
                              isMulti
                              options={
                                !stateOptions?.length
                                  ? [{ label: "Loading...", value: 0 }]
                                  : stateOptions
                              }
                              onChange={(selectedOptions) =>
                                handleStatesName(selectedOptions)
                              }
                              value={uniqueTestState} // Use an array to support multiple selections
                              renderInput={(params) => (
                                <Form.Control
                                  {...params}
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    outline: "none",
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                  }}
                                />
                              )}
                            />

                            <p
                              style={{
                                display: isPropertySelected ? "block" : "none",
                                color: "red",
                                marginTop: "0.5rem",
                              }}>
                              Please select at least one property.
                            </p>
                          </Form.Group>
                        </div>
                        {/* Cities */}
                        <div className="col-md-6 col-sm-12 mt-3 mb-3">
                          <Form.Group>
                            <label>Cities</label>
                            {/*
                          <Select
                            onChange={handleCityNames}
                            options={citiesOptions}
                            isMulti
                          /> */}

                            <Select
                              isMulti
                              // options={States}
                              options={
                                !filteredCityOptions
                                  ? [{ label: "Loading...", id: 0 }]
                                  : citiesOptions
                              }
                              style={{
                                background: "transparent",
                                border: "none",
                                outline: "none",
                              }}
                              onChange={(value) => handleCityNames(value)}
                              value={uniqueTestcity?.map((d) => d)}
                              // value={uniqueTestcity}
                              // value={citiesOptions}

                              autoHighlight
                              renderInput={(params) => (
                                <Form.Control
                                  {...params}
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    outline: "none",
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    Select: "new-password", // disable Select and autofill
                                  }}
                                />
                              )}
                            />

                            {/* <p
                      role="button"
                      className="text-right text-primary"
                      onClick={handleLocation}
                      style={{ display: cityId ? "block" : "none" }}
                    >
                      <b> Search Properties</b>
                    </p> */}
                          </Form.Group>
                        </div>{" "}
                        <div className="col-md-12">
                          <button
                            className="user_management_styled_button"
                            onClick={handleLocation}
                            style={{
                              display: cityId ? "block" : "none",
                              borderRadius: "8px",
                              marginRight: "5px",
                              padding: "10px 30px",
                              transition:
                                "background-color 0.3s ease, box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out",
                              color: "var(--white)",
                              background:
                                "linear-gradient(45deg, #ffcc00, #ff9933)",
                              boxShadow:
                                "inset 0 4px 6px rgba(255, 224, 47, 0.5), 0 6px 12px rgba(0, 0, 0, 0.2)",
                              outline: "none",
                              border: "none",
                            }}
                            // style={{ display: cityId ? "block" : "none" }}
                            type="button">
                            {isPropertyListed
                              ? "Loading ..."
                              : "Search Properties"}
                          </button>
                        </div>
                        {/* {confirmToggle && ( */}
                        <div className="col-md-12 col-sm-12 mt-2">
                          {/* <label>Properties</label> */}

                          <Select
                            isMulti
                            id="country-select-demo"
                            // options={States}
                            // options={!propertiesList ? [{ label: "Loading...", id: 0 }] : propertiesList.map((prop)=>prop.label)}
                            options={
                              !propertiesList
                                ? [{ label: "Loading...", id: 0 }]
                                : propertiesList
                            }
                            onChange={(event, label) =>
                              handlePropertyNames(label)
                            }
                            // onChange={handleStateChange}
                            // value={selectedStatesProp}
                            value={NEwProperties?.map((d) => d)}
                            autoHighlight
                            renderInput={(params) => (
                              <Form.Control
                                {...params}
                                // label="Permit State"
                                inputProps={{
                                  ...params.inputProps,
                                  Select: "new-password", // disable Select and autofill
                                }}
                              />
                            )}
                          />

                          {/* { NEwProperties.length<=0 && <small style={{ color:"red" }} >*  Please select Property</small>  } */}
                        </div>
                        {/* )} */}
                      </div>

                      {/* Enable/Disable */}
                      <div
                        className="col-md-4 col-sm-12 mt-5"
                        style={{ zIndex: 0 }}>
                        <label>
                          Enable/Disable <span className="text-danger">*</span>
                        </label>
                        <Form.Check
                          className="mt-2"
                          name="status"
                          onClick={(e) => {
                            onToggleButton(e);
                          }}
                          type="switch"
                          defaultChecked={editStaffData.status}
                          id={"status"}
                          label=""
                        />
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="d-flex flex-wrap justify-content-end mr-2">
                          <div className="mt-5 d-flex align-items-center">
                            <Button
                              type="button"
                              style={{
                                display: cityId ? "block" : "none",
                                borderRadius: "8px",
                                marginRight: "5px",
                                padding: "10px 30px",
                                transition:
                                  "background-color 0.3s ease, box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out",
                                color: "var(--white)",
                                background:
                                  "linear-gradient(45deg, #ffcc00, #ff9933)",
                                boxShadow:
                                  "inset 0 4px 6px rgba(255, 224, 47, 0.5), 0 6px 12px rgba(0, 0, 0, 0.2)",
                                outline: "none",
                                border: "none",
                              }}
                              className="btn btn-light rounded-0">
                              Clear
                            </Button>

                            <Button
                              type="submit"
                              className="btn btn-primary ml-2 rounded-0"
                              style={{
                                background:
                                  "linear-gradient(45deg, #4caf50, #81c784)",
                                color: "#ffffff",
                                fontSize: "15px",
                                padding: "10px 20px",
                                borderRadius: "8px",
                                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
                                transition: "all 0.3s ease",
                                border: "none",
                                textTransform: "none",
                              }}>
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* <ToastBRH
            duration={toastObject.duration}
            onSetOpen={setSnackOpen}
            open={snackOpen}
            variant={toastObject.variant}>
            {toastObject.message}
          </ToastBRH> */}
        </>
      ) : (
        <>
          <Container
            style={{
              height: "60vh",
            }}
            className=" d-flex align-items-center justify-content-center ">
            <ScaleLoader size={100} color="orange" />
          </Container>
        </>
      )}
    </>
  );
};

export default EditOrganizationStaff;
