import React, { useEffect, useState } from "react";
import OrganizationForm from "./OrganizationForm";
import URL from "../../components/API/Api";
import Swal from "sweetalert2";

const EditOrganization = ({ handleClose, organizationId }) => {
  const [formData, setFormData] = useState({
    organizationName: "",
  });

  // Fetch organization details based on organizationId when component mounts
  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      try {
        const response = await URL.get(`api/org/${organizationId}`);
        const data = response.data.data;
        setFormData({
          organizationName: data.org_name || "",
        });
      } catch (error) {
        console.error("Error fetching organization details:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to load organization details. Please try again.",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "#dc3545",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    };

    if (organizationId) {
      fetchOrganizationDetails();
    }
  }, [organizationId]);

  // Handle form submission for editing organization details
  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedOrganizationData = {
      org_name: formData.organizationName,
      status: 1,
    };

    try {
      const response = await URL.put(
        `api/org/${organizationId}`,
        updatedOrganizationData
      );

      // Show success toaster
      Swal.fire({
        icon: "success",
        title: "Success",
        text:
          response.data.message || "Organization details updated successfully.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "rgb(255, 165, 0)",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });

      // Clear form data
      setFormData({
        organizationName: "",
      });

      handleClose(); // Close the modal after successful update
    } catch (error) {
      console.error("Error updating organization data:", error);

      // Show error toaster
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error updating organization data. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div>
      <OrganizationForm
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
        buttonName="Update Organization"
        handleClose={handleClose}
      />
    </div>
  );
};

export default EditOrganization;
